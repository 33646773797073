import React, { useState, useEffect } from 'react'

import PhylotreeApplication from './PhylotreeApplication.jsx'

import useWindowDimensions from './hooks/useWindowDimensions.js'

import {
  Dimmer,
  Segment,
  Header,
  Placeholder,
  PlaceholderLine,
  Button,
} from 'semantic-ui-react'

import styles from '../../dashboard/JobDetailsPanel.module.css'
// import data from "./data.js";
import useTreeStore from '../../../store/treeStore.js'

export default function TreeVis({ props, onExit, setTreeParams }) {
  // console.log(props);
  const { user, jobId, processId, outputId, workflowName } = props || {}
  // console.log(jobId, processId, outputId, workflowName);
  const { width } = useWindowDimensions()
  // const newickData = data.h3;

  const fetchDataset = useTreeStore((state) => state.fetchDataset)
  const newickData = useTreeStore((state) => state.dataset) || ''
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props) {
      // console.log(jobId, processId, outputId);
      if (user && jobId && processId && outputId) {
        setIsLoading(true)
        fetchDataset(user, jobId, processId, outputId).then(() => {
          setIsLoading(false)
        })
        // console.log(fetchDataset);
        // console.log(newickData);
      }
    }
  }, [user, jobId, processId, outputId, fetchDataset, props])

  return (
    <Dimmer
      active={!!user && !!jobId && !!processId && !!outputId && !!workflowName}
      page
      onClickOutside={onExit}
    >
      <Segment textAlign="left">
        <Header as="h1">Phylogenetic Tree - {workflowName || ''}</Header>
      </Segment>
      <Segment className={styles.panel} textAlign="left">
        <div>
          <div className="mobile-tree-container">
            The phylogenetic tree currently not supported to display on mobile
            mode.
          </div>
          <div
            style={{
              // maxWidth: "67%",
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            className="tree-container"
          >
            {isLoading ? (
              <Placeholder>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
              </Placeholder>
            ) : (
              <PhylotreeApplication width={width} newickData={newickData} />
            )}
          </div>
        </div>
      </Segment>
      <Segment textAlign="right">
        <Button onClick={() => setTreeParams(null)}>Cancel</Button>
      </Segment>
    </Dimmer>
  )
}
