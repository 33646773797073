export const topologicalSortDFS = (nodes, edges) => {
    const sortedNodeIds = []
    const visitedNodeIds = new Set()
    const nodesChildren = {}

    for (const edge of edges) {
      nodesChildren[edge.source] = [
        ...nodesChildren[edge.source] ?? [],
        edge.target
      ]
    }

    const visit = (nodeId) => {
      if (visitedNodeIds.has(nodeId)) {
        return
      }
      if (nodesChildren[nodeId]) {
        for (const child of nodesChildren[nodeId]) {
          visit(child)
        }
      }
      visitedNodeIds.add(nodeId)
      sortedNodeIds.push(nodeId)
    }

    for (const node of nodes) {
      visit(node.id)
    }
    return sortedNodeIds.map(nodeId => nodes.find(node => node.id === nodeId)).reverse()
  }