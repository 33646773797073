import React, { useEffect, useState, useMemo } from 'react'
// import PieChart from "../visualization/PieChart";
// import Toolbar from "./Toolbar";
import ReactFlow, { useEdges, useNodes, useReactFlow } from 'reactflow'
import {
  Button,
  Form,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableBody,
  TableCell,
  Table,
  Icon,
  Popup,
} from 'semantic-ui-react'
import { useJobStore } from '../../store/jobStore'
import { topologicalSortDFS } from '../../utils/topologicalSortDFS'
import { postRequestDownloadProcessOutputsURL, getMe } from '../../constant/url'
import { myAxios } from '../../utils/axios'
// import { generateName } from "../utilities/randomNameGenerator";
import { colorGenerator } from '../utilities/colorMapping'
// import StackedBarChart from "../visualization/StackedBarChart";
// import categoryColors from "../../styles/colors.js";
import { v4 as uuidv4 } from 'uuid'
import { useCategories } from '../../styles/colorUtils'

const Jobbar = (props) => {
  const user = useJobStore((state) => state.user) // ["public", "persronal"
  const setUser = useJobStore((state) => state.setUser)
  const jobName = useJobStore((state) => state.jobName)
  const setJobName = useJobStore((state) => state.setJobName)
  const [nameEditOpen, setNameEditOpen] = useState(false)
  const jobStatus = useJobStore((state) => state.jobStatus)
  const processStatus = useJobStore((state) => state.processStatus)
  const fetchJob = useJobStore((state) => state.fetchJob)
  const isSubmittingJob = useJobStore((state) => state.isSubmittingJob)
  const jobid = useJobStore((state) => state.jobid)
  const token = localStorage.getItem('token')
  const isJobRunning = useMemo(
    () => ['UPLOADING', 'UPLOADED', 'STARTED'].includes(jobStatus),
    [jobStatus]
  )

  const [myName, setMyName] = useState('')
  useEffect(() => {
    const fetchMyName = async () => {
      try {
        const response = await myAxios.get(getMe)
        console.log(response)
        setMyName(response?.email?.split('@')?.[0] ?? '')
      } catch (error) {
        console.error('Cannot get user name from backend: ' + error)
        setMyName('')
      }
    }
    if (localStorage.getItem('token')) fetchMyName()
  }, [])

  useEffect(() => {
    // if (!jobName) {
    //   setJobName("Workflow-" + generateName());
    // }
    // console.log(props);
    // let nameList = {};
    // props.nodes.forEach((node) => {
    //   if (!nameList.hasOwnProperty(node.type)) {
    //     nameList[node.type] = {};
    //   }

    //   const inputDataValues = Object.values(node.data.inputData);
    //   // console.log(inputDataValues);
    //   const fileIndices = node.data.input
    //     .map((input, i) => (input === "file" ? i : -1))
    //     .filter((index) => index !== -1);
    //   // console.log(fileIndices);
    //   const fileValues = fileIndices
    //     .map((index) => {
    //       const inputType = inputDataValues[index]?.type;
    //       const inputValue = inputDataValues[index]?.value;
    //       // console.log(inputType);
    //       if (inputType === "processOutput") {
    //         return null; // Filter out process outputs
    //       } else if (inputType === "workflowInput") {
    //         if (inputValue) {
    //           return inputValue.name; // Return File name
    //         }
    //       }

    //       return inputValue;
    //     })
    //     .filter(
    //       (value) => value !== undefined && value !== null && value !== ""
    //     );
    //   // Workflow name insertion
    //   if (!nameList[node.type].hasOwnProperty("workflow")) {
    //     nameList[node.type]["workflow"] = node.type;
    //   }
    //   // File names insertion
    //   if (!nameList[node.type].hasOwnProperty("input")) {
    //     nameList[node.type]["input"] = [];
    //   }
    //   nameList[node.type]["input"].push(...fileValues);
    // });
    // console.log(nameList);
    // const concatenatedList = Object.values(nameList)
    //   .map((item) => {
    //     const input = Array.isArray(item.input) ? item.input.join(", ") : "";
    //     return input ? `${item.workflow}<${input}>` : item.workflow;
    //   })
    //   .filter((item) => item !== "")
    //   .join(" - ");
    const fullUUID = uuidv4()
    const shortUUID = fullUUID.substring(0, 8)
    // setJobName(shortUUID + ": " + concatenatedList);

    if (!jobName) {
      setJobName(`${myName ? `${myName}-` : ''}Workflow-${shortUUID}`)
    }
  }, [jobName, setJobName, myName, props.nodes])

  useEffect(() => {
    let periodicFetch = null
    if (
      (jobStatus === 'UPLOADED' || jobStatus === 'STARTED') &&
      !periodicFetch
    ) {
      periodicFetch = setInterval(() => {
        fetchJob()
      }, 10000)
    }
    return () => clearInterval(periodicFetch)
  }, [fetchJob, jobStatus])

  const nameEditHandler = (event) => {
    setNameEditOpen(event)
  }
  const jobNameChange = (e) => {
    const enteredText = e.target.value
    if (enteredText) {
      setJobName(enteredText)
    }
  }
  const nodes = useNodes()
  const edges = useEdges()

  const sortedNodes = useMemo(() => {
    return topologicalSortDFS(nodes, edges)
  }, [nodes, edges])

  const [processDownloading, setProcessDownloading] = useState(new Set())

  const handleDownloadOutput = (pid) => async (e) => {
    try {
      if (!jobid) {
        throw new Error('job id is required')
      }
      setProcessDownloading((prev) => new Set([...prev, pid]))
      const response = await myAxios.post(
        postRequestDownloadProcessOutputsURL(user, jobid, pid)
      )
      console.debug(response)
      const processOutputURLs = response.data
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      for (const url of processOutputURLs) {
        const link = document.createElement('a')
        link.href = url.url
        link.download = url.id
        link.click()
        await sleep(2000)
      }
    } catch (error) {
      console.error('Error occured during handleDownloadOutput: ' + error)
    } finally {
      setProcessDownloading((prev) => {
        const newSet = new Set(prev)
        newSet.delete(pid)
        return newSet
      })
    }
  }

  const loadingButton = () => (
    <>
      <Button disabled textAlign="center" style={{ margin: '0' }}>
        <Popup
          trigger={<Icon loading name="spinner" style={{ margin: '0' }} />}
          content="on process"
          basic
        />
      </Button>
    </>
  )

  //color mapping
  const tools = props.tools
  const groupToolsByCategory = (tools) => {
    const grouped = {}

    tools.forEach((tool) => {
      // console.log(tool);
      const category = tool.category
      if (!grouped[category]) {
        grouped[category] = []
      }
      grouped[category].push(tool)
    })

    return grouped
  }
  const length =
    Object.keys(groupToolsByCategory(tools)).length == 0
      ? 2
      : Object.keys(groupToolsByCategory(tools)).length
  // const { getCategoryColors } = useCategories()

  //Side bar resizing
  const [siderWidth, setSiderWidth] = useState(
    parseInt(localStorage.getItem('siderWidth')) || 450
  )
  const [dragging, setDragging] = useState(false)
  const [startPageX, setStartPageX] = useState(0)
  const pxWidth = `${siderWidth}px`
  const handleMouseDown = (event) => {
    setStartPageX(event.pageX)
    setDragging(true)
  }
  const handleMouseMove = (event) => {
    const currentSiderWidth = siderWidth - event.pageX + startPageX
    setSiderWidth(currentSiderWidth)
    setStartPageX(event.pageX)
  }
  const handleMouseUp = () => {
    setDragging(false)
    localStorage.setItem('siderWidth', siderWidth)
  }

  //visuals openning
  function openCentered({ url, width, height }) {
    const pos = {
      x: window.screen.width / 2 - width / 2,
      y: window.screen.height / 2 - height / 2,
    }

    const features = `width=${width} height=${height} left=${pos.x} top=${pos.y}`

    return window.open(url, '_blank', features)
  }
  const handleVisualClick = () => {
    // openCentered({
    //   url: "https://marbl.github.io/Krona/examples/du.krona.html",
    //   title: "biotailer",
    //   width: 800,
    //   height: 600,
    // }).focus();
    window.open('http://localhost:3000/tree', 'Data', 'height=800,width=1200')
  }

  const personalPublicButtonGroup = () => (
    <Button.Group size="tiny" style={{ width: '100%' }}>
      {personalPublicButton('public')}
      {personalPublicButton('personal')}
    </Button.Group>
  )
  const personalPublicButton = (type) => (
    <Popup
      content={
        type === 'public'
          ? 'Workflow can be seen by everyone'
          : 'Only you can see this workflow'
      }
      trigger={
        <Button
          color={user === type ? 'black' : ''}
          icon
          disabled={isJobRunning || jobid}
          labelPosition="left"
          active={user === type}
          onClick={() => setUser(type)}
        >
          <Icon name={type === 'public' ? 'world' : 'user'} />
          {capitalizeFirstLetter(type)}
        </Button>
      }
    />
  )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <>
      <aside className="result-aside" style={{ width: pxWidth }}>
        <Form size="large" className="job-bar-form">
          {isJobRunning ? (
            <h1>{jobName}</h1>
          ) : nameEditOpen ? (
            <div style={{ display: 'flex' }}>
              <Form.Input
                placeholder={jobName}
                onChange={jobNameChange}
                style={{ marginBottom: '6.87px' }}
              />
              <Button
                secondary
                size="medium"
                style={{
                  height: '42px',
                  marginLeft: '10px',
                  padding: '10px 16px',
                  transition: 'none',
                }}
                onClick={() => nameEditHandler(false)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <h1>{jobName}</h1>
              <Button
                icon="edit"
                secondary
                size="huge"
                style={{
                  backgroundColor: 'transparent',
                  color: '#000000',
                  height: '42px',
                  padding: '8px 16px',
                  transition: 'none',
                }}
                onClick={() => nameEditHandler(true)}
              ></Button>
            </div>
          )}
          {token && personalPublicButtonGroup()}
          {/* <div>Flow Summary</div> */}
          <Table striped size="small" fixed>
            <TableHeader>
              <TableRow>
                <TableHeaderCell width={6}>Node</TableHeaderCell>
                <TableHeaderCell width={6}>Input</TableHeaderCell>
                <TableHeaderCell textAlign="center" width={4}>
                  Result
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedNodes.length !== 0 ? (
                sortedNodes.map((nd) => {
                  const label = nd.data.label
                  const pid = nd.id
                  const tool = nd.type
                  const displayName = nd.data.displayName
                  const inputData = nd.data.inputData
                  const index = nd.data.index
                  // const category = nd.data.category
                  // console.log(category);
                  // const colors = categoryColors[category] || {
                  //   background: "#ffffff",
                  //   border: "#000000",
                  // };
                  // const colors = getCategoryColors(category)
                  return (
                    <TableRow
                      key={pid}
                      verticalAlign="top"
                      style={{
                        backgroundColor: `${
                          colorGenerator(false, length)[index]
                        }`,
                        // backgroundColor: colors.background,
                      }}
                    >
                      <TableCell>
                        <Popup
                          trigger={
                            <p
                              className="table-cell"
                              style={{
                                color: `${
                                  colorGenerator(false, length)[index]
                                }`,
                                filter: 'grayscale(1) contrast(999) invert(1)',
                              }}
                            >
                              {label}
                            </p>
                          }
                          content={'tool: ' + tool}
                          basic
                        />
                      </TableCell>
                      <TableCell verticalAlign="top">
                        {displayName.map((key) => {
                          if (inputData) {
                            if (
                              inputData[key] &&
                              typeof inputData[key].value === 'object' &&
                              inputData[key].type === 'workflowInput'
                            )
                              return (
                                <Popup
                                  key={key}
                                  trigger={
                                    <p className="table-cell">
                                      {inputData[key].value.name + '\n'}
                                    </p>
                                  }
                                  content={inputData[key].value.name}
                                  basic
                                />
                              )
                            else {
                              return <></>
                            }
                          } else {
                            return <></>
                          }
                        })}
                      </TableCell>
                      <TableCell verticalAlign="middle" textAlign="center">
                        {processStatus?.[pid] === 'COMPLETED' ? (
                          processDownloading.has(pid) ? (
                            loadingButton()
                          ) : (
                            <>
                              <Popup
                                trigger={
                                  <Button
                                    icon="cloud download"
                                    size="big"
                                    style={{
                                      margin: '0',
                                      padding: '0',
                                      backgroundColor: 'transparent',
                                      color: 'black',
                                    }}
                                    onClick={handleDownloadOutput(pid)}
                                  />
                                }
                                content="download"
                                basic
                              />
                              {/* <Popup
                          trigger={
                            <Button
                              secondary
                              size="huge"
                              icon="chart bar"
                              onClick={handleVisualClick}
                              style={{
                                margin: "0",
                                marginLeft: "15px",
                                padding: "0",
                                backgroundColor: "transparent",
                                color: "black",
                              }}
                            />
                          }
                          content="visualize"
                          basic
                        /> */}
                            </>
                          )
                        ) : (
                          <>
                            {isSubmittingJob || isJobRunning ? (
                              loadingButton()
                            ) : (
                              <Popup
                                trigger={
                                  <Icon name="minus" style={{ margin: '0' }} />
                                }
                                content="pending submit"
                                basic
                              />
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow verticalAlign="top">
                  <TableCell>
                    <br />
                  </TableCell>
                  <TableCell verticalAlign="top"></TableCell>
                  <TableCell
                    verticalAlign="middle"
                    textAlign="center"
                  ></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Form>
      </aside>
      <div
        className="sider-resizer"
        style={{ right: pxWidth }}
        onMouseDown={handleMouseDown}
      >
        {dragging && (
          <div
            className="resize-mask"
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          />
        )}
      </div>
      <div
        className="sider-resizer"
        style={{ right: pxWidth }}
        onMouseDown={handleMouseDown}
      >
        {dragging && (
          <div
            className="resize-mask"
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          />
        )}
      </div>
    </>
  )
}

export default Jobbar
