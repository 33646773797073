import { useEffect, useMemo, useState } from "react";
import { Button, Table, Icon, Input } from "semantic-ui-react";
import { useDashboardStore } from "../../store/dashboardStore";
import { myAxios } from "../../utils/axios";
import { postCancelJobURL } from "../../constant/url";

import { toJobStatusDisplayName } from "../utilities/jobStatusDisplayName";

import LoadingPlaceholder from "../general/LoadingPlaceholder";

const DashboardTable = ({ setActiveJobDetails, user }) => {
  const jobs = useDashboardStore((state) => state.jobs?.[user] ?? []);
  const isLoading = useDashboardStore((state) => state.isLoading);
  const showLoadingPlaceholder = useMemo(
    () => isLoading && jobs?.length === 0,
    [isLoading, jobs]
  );
  const fetchJobs = useDashboardStore((state) => state.fetchJobs);
  useEffect(() => {
    fetchJobs(user);
    const periodicFetch = setInterval(() => {
      fetchJobs(user);
    }, 10000);
    return () => clearInterval(periodicFetch);
  }, [fetchJobs, user]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
    filter: searchQuery,
  });

  const handleDetailsClicked = (job) => {
    // TODO: navigate to job details page, if job is able to resubmit with existing uploaded file
    setActiveJobDetails(job);
  };

  const [cancellingJobId, setCancellingJobId] = useState(null);
  const handleCancel = async (user, jobid) => {
    try {
      setCancellingJobId(jobid);
      if (!jobid) {
        throw new Error("job id is required");
      }
      const response = await myAxios.post(postCancelJobURL(user, jobid));
      fetchJobs(user);
    } catch (error) {
      console.error("failed to cancel job: " + error);
    } finally {
      setCancellingJobId(null);
    }
  };

  const jobStatusOrder = {
    FAILED: 1,
    CANCELED: 2,
    SUCCEED: 3,
    STARTED: 4,
    UPLOADING: 5,
    UPLOADED: 6,
  };

  const sortedJobs = useMemo(() => {
    const sortableJobs = [...jobs];

    // console.log(sortableJobs);
    if (sortConfig.key) {
      sortableJobs.sort((a, b) => {
        let aKey, bKey;

        if (sortConfig.key === "displayDatetime") {
          aKey = new Date(a.startTimestamp ?? null);
          bKey = new Date(b.startTimestamp ?? null);
        } else if (sortConfig.key === "jobStatus") {
          aKey = jobStatusOrder[a.jobStatus] || 0;
          bKey = jobStatusOrder[b.jobStatus] || 0;
        }

        if (aKey < bKey) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (searchQuery && searchQuery != "")
      return sortableJobs.filter((sortablejob) =>
        sortablejob.workflow.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    return sortableJobs;
  }, [jobs, sortConfig, searchQuery]);

  const handleSort = (key) => {
    // Add a counter for each key
    const counters = sortConfig.counters || {};
    counters[key] = counters[key] ? counters[key] + 1 : 1;

    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // If the user has clicked the sort button three times, reset the table
    if (counters[key] === 3) {
      counters[key] = 0; // reset the counter
      key = null; // reset the key
      direction = "ascending"; // reset the direction
    }

    setSortConfig({ key, direction, counters });
  };

  const jobRow = (job) => {
    // console.log(job.jobid);
    // console.log(job.workflow.processes);
    const displayStatus = toJobStatusDisplayName(job?.jobStatus);
    let statusCellStyle = {};
    switch (job?.jobStatus) {
      case "FAILED":
        statusCellStyle = { negative: true };
        break;
      case "CANCELED":
        statusCellStyle = { disabled: true };
        break;
      case "SUCCEED":
        statusCellStyle = { positive: true };
        break;
      case "STARTED":
        statusCellStyle = { warning: true };
        break;
      default:
        break;
    }
    const displayDatetime = new Date(
      job?.startTimestamp ?? null
    ).toLocaleString();
    return (
      <Table.Row key={job?.jobid}>
        <Table.Cell style={{ backgroundColor: "transparent" }} width={4}>
          {job?.workflow?.name}
        </Table.Cell>
        <Table.Cell style={{ textAlign: "center" }} width={2}>
          {displayDatetime}
        </Table.Cell>
        <Table.Cell
          {...statusCellStyle}
          style={{
            textAlign: "center",
          }}
          width={2}
        >
          {displayStatus}
        </Table.Cell>
        <Table.Cell
          style={{
            textAlign: "right",
          }}
          width={2}
        >
          {displayStatus === "Running" && (
            <Button
              onClick={() => handleCancel(user, job?.jobid)}
              negative
              loading={
                cancellingJobId !== null && cancellingJobId === job?.jobid
              }
            >
              Cancel
            </Button>
          )}
          <Button secondary onClick={() => handleDetailsClicked(job)}>
            Details
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div>
      <Input
        size="small"
        icon="search"
        placeholder="Search by workflow name or tag..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "10px", width: "25rem", width: "100%" }}
      />
      <Table compact definition={!showLoadingPlaceholder} celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Workflow name</Table.HeaderCell>
            <Table.HeaderCell
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleSort("displayDatetime")}
            >
              Start datetime
              {sortConfig.key === "displayDatetime" && (
                <Icon
                  name={
                    sortConfig.direction === "ascending"
                      ? "arrow up"
                      : "arrow down"
                  }
                />
              )}
              {sortConfig.key === null ? <Icon name="sort" /> : null}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleSort("jobStatus")}
            >
              Running status
              {sortConfig.key === "jobStatus" && (
                <Icon
                  name={
                    sortConfig.direction === "ascending"
                      ? "arrow up"
                      : "arrow down"
                  }
                />
              )}
              {sortConfig.key === null ? <Icon name="sort" /> : null}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: "right" }}>
              {/* Operation */}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {showLoadingPlaceholder ? (
            <LoadingPlaceholder />
          ) : (
            sortedJobs.map((job) => jobRow(job))
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DashboardTable;
