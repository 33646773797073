import React, { useRef, useEffect } from "react";

import { line } from "d3-shape";
import * as d3 from "d3"
// import zIndex from "@material-ui/core/styles/zIndex";


function Branch(props) {
  // const svgRef = useRef();
  //   useEffect(()=>{
  //    const svg = d3.select(svgRef.current);
  //    const data1 = 
  //     { cx: target_x, cy: target_y, r:10 }
  //    ;
  //    const rect = svg.selectAll('.node')
  //     .data(data1)
  //     .enter()
  //     .append('circle').join("path").attr('cx',d=>d.cx).attr('cy',d=>d.cy).attr('r',10).attr('fill','red')
  // })
  const { xScale, yScale, colorScale, showLabel, setTooltip } = props,
    { source, target } = props.link,
    source_x = xScale(source.data.abstract_x),
    source_y = yScale(source.data.abstract_y),
    target_x = xScale(target.data.abstract_x),
    target_y = yScale(target.data.abstract_y),
    target_name = target.data.name,
    tracer_x2 = props.alignTips == "right" ?
    // props.width - (target.data.text_width || 0) :
    props.width:
      target_x,
    data = [
      [source_x, source_y],
      [source_x, target_y],
      [target_x, target_y]
    ],
    branch_line = line()
      .x(d=>d[0])
      .y(d=>d[1]),
    computed_branch_styles = props.branchStyler ?
      props.branchStyler(target.data) :
    target.data.annotation && colorScale ? {
      stroke: colorScale(target.data.annotation)
    } : {},
    all_branch_styles = Object.assign(
      {}, props.branchStyle, computed_branch_styles
    ),
    label_style = target.data.name && props.labelStyler ?
      props.labelStyler(target.data) :
      {} ;
      // console.log(target_name)

    // const circle = d3.selectAll(".rp-branch").attch("circle").data(data[3]).cx(data[0]).cy(data[1]).r(5)
    
  return (<g className="node" >
    <path
      className="rp-branch"
      id={target_name}
      fill="none"
      d={branch_line(data)}
      onClick={() => props.onClick(props.link)}
      {...all_branch_styles}
      onMouseMove={props.tooltip ? e => {
        setTooltip({
          x: e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
          data: target.data
        });
      } : undefined}
      onMouseOut={props.tooltip ? e => {
        setTooltip(false);
      } : undefined}
    />
    {showLabel ? <line
      x1={target_x}
      x2={tracer_x2}
      y1={target_y}
      y2={target_y}
      className="rp-branch-tracer"
    /> : null}
        <text
     x={props.width + 5}
     y={target_y}
     textAnchor={props.alignTips=="right"?"end":"start"}
     alignmentBaseline="middle"
     {...Object.assign({}, props.labelStyle, label_style)}
     className="rp-label"
     style={{
      fill:"none",
      stroke:"white",
      strokeWidth:"10",
      strokeLinejoin:"round"
     }}
   >{target.data.name.slice(0, props.maxLabelWidth)}</text>
    {showLabel ?
    <text
      x={tracer_x2 + 5}
      y={target_y}
      textAnchor={props.alignTips=="right"?"end":"start"}
      alignmentBaseline="middle"
      {...Object.assign({}, props.labelStyle, label_style)}
      className="rp-label"
    >{target.data.name.slice(0, props.maxLabelWidth)}</text> : null}

  </g>);
}
Branch.defaultProps = {
  branchStyle: {
    strokeWidth: 2,
    stroke: "grey"
  },
  labelStyle: {
  }
}

export default Branch;
