function createToolsRelations(edges) {
  const relations = {
    incoming: {},
    outgoing: {}
  };

  // Initialize all nodes to have null for both incoming and outgoing by default
  edges.forEach(edge => {
    if (!relations.incoming[edge.source]) {
      relations.incoming[edge.source] = null;
    }
    if (!relations.outgoing[edge.source]) {
      relations.outgoing[edge.source] = null;
    }
    if (!relations.incoming[edge.target]) {
      relations.incoming[edge.target] = null;
    }
    if (!relations.outgoing[edge.target]) {
      relations.outgoing[edge.target] = null;
    }
  });

  // Populate the incoming and outgoing relationships
  edges.forEach(edge => {
    relations.incoming[edge.target] = edge.source; // the source points to the target
    relations.outgoing[edge.source] = edge.target; // the target is pointed to by the source
  });

  return relations;
}

export default createToolsRelations;