import { create } from "zustand";
import { myAxios } from "../utils/axios";
import {
  createBlueprintURL,
  getBlueprintURL,
  getBlueprintsURL,
  editBlueprintURL,
  deleteBlueprintURL,
} from "../constant/url";
// import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useBlueprintStore = create((set, get) => ({
  blueprints: {
    public: [],
    personal: [],
  },

  // API status
  createBlueprintIsLoading: false,
  getBlueprintIsLoading: false,
  getBlueprintsIsLoading: false,
  editBlueprintIsLoading: false,
  deleteBlueprintIsLoading: false,
  createBlueprintIsFailed: false,
  getBlueprintIsFailed: false,
  getBlueprintsIsFailed: false,
  editBlueprintIsFailed: false,
  deleteBlueprintIsFailed: false,

  createBlueprintIsSuccess: false,
  editBlueprintIsSuccess: false,

  handledCreateBlueprintSuccess: () => set({ createBlueprintIsSuccess: false }),
  handledEditBlueprintSuccess: () => set({ editBlueprintIsSuccess: false }),

  // API
  createBlueprint: async (userType, blueprintJSON) => {
    try {
      set({ createBlueprintIsLoading: true });
      await myAxios.post(createBlueprintURL(userType), blueprintJSON);
      set({ createBlueprintIsSuccess: true });
    } catch (error) {
      console.error(error);
      set({ createBlueprintIsFailed: true });
    } finally {
      set({ createBlueprintIsLoading: false });
    }
  },
  getBlueprint: async (userType, id) => {
    try {
      set({ getBlueprintIsLoading: true });
      const response = await myAxios.get(getBlueprintURL(userType, id));
      return response.data;
    } catch (error) {
      console.error(error);
      set({ getBlueprintIsFailed: true });
    } finally {
      set({ getBlueprintIsLoading: false });
    }
  },
  getBlueprints: async (userType) => {
    try {
      set({ getBlueprintsIsLoading: true });
      const response = await myAxios.get(getBlueprintsURL(userType));
      set((state) => ({
        blueprints: {
          ...state.blueprints,
          [userType]: response.data.sort(
            (a, b) => (b?.createdDateTime ?? 0) - (a?.createdDateTime ?? 0)
          ),
        },
      }));
    } catch (error) {
      console.error(error);
      set({ getBlueprintsIsFailed: true });
    } finally {
      set({ getBlueprintsIsLoading: false });
    }
  },
  editBlueprint: async (userType, id, blueprintJSON) => {
    try {
      set({ editBlueprintIsLoading: true });
      await myAxios.patch(editBlueprintURL(userType, id), blueprintJSON);
      set({ editBlueprintIsSuccess: true });
    } catch (error) {
      console.error(error);
      set({ editBlueprintIsFailed: true });
    } finally {
      set({ editBlueprintIsLoading: false });
    }
  },
  deleteBlueprint: async (userType, id) => {
    try {
      set({ deleteBlueprintIsLoading: true });
      await myAxios.delete(deleteBlueprintURL(userType, id));
      set((state) => ({
        blueprints: {
          ...state.blueprints,
          [userType]: state.blueprints[userType].filter(
            (blueprint) => blueprint.blueprintId !== id
          ),
        },
      }));
    } catch (error) {
      console.error(error);
      set({ deleteBlueprintIsFailed: true });
    } finally {
      set({ deleteBlueprintIsLoading: false });
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool('useDashboardStore', useBlueprintStore)
}
