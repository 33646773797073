import { Modal, Button, Form, Popup, Icon, Label, Message } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { useBlueprintStore } from "../../store/blueprintStore";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import _ from "lodash"

const BlueprintCreateDialog = ({ children, reactFlowInstance, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState("public");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [tempTag, setTempTag] = useState("");
  const token = localStorage.getItem("token");

  const personalPublicButtonGroup = () => (
    <Button.Group size="tiny" style={{ width: "100%" }}>
      {personalPublicButton("public")}
      {personalPublicButton("personal")}
    </Button.Group>
  )
  const personalPublicButton = (type) => (
    <Popup
      flowing
      content={type === "public" ? "This blueprint can be used by everyone" : "Only you can use this blueprint"}
      trigger={
        <Button
          type="button"
          color={visibility === type ? "black" : ""}
          icon
          labelPosition="left"
          active={visibility === type}
          onClick={() => setVisibility(type)}
        >
          <Icon name={type === "public" ? "world" : "user"} />
          {capitalizeFirstLetter(type)}
        </Button>
      }
    />
  )

  const addTag = (tag) => {
    if (tag.includes(" ")) {
      alert("Tag cannot contain space");
      return;
    }
    if (tag !== "" && !tags.includes(tag)) {
      setTags([...tags, tag])
      setTempTag("")
    }
  }

  const removeTag = (tag) => {
    if (tag !== "" && tags.includes(tag)) {
      setTags(tags.filter(t => t !== tag))
    }
  }

  const tagsGroup = () => (
    <Label.Group>
      {tags.map((tag, index) => (
        <Label key={index}>
          {tag}
          <Icon name="delete" onClick={() => removeTag(tag)} />
        </Label>
      ))}
    </Label.Group>
  )

  const createBlueprintAPI = useBlueprintStore(state => state.createBlueprint)
  const createBlueprintIsLoading = useBlueprintStore(state => state.createBlueprintIsLoading)
  const createBlueprintIsFailed = useBlueprintStore(state => state.createBlueprintIsFailed)
  const createBlueprintIsSuccess = useBlueprintStore(state => state.createBlueprintIsSuccess)
  const handledCreateBlueprintSuccess = useBlueprintStore(state => state.handledCreateBlueprintSuccess)

  const createBlueprint = async () => {
    if (name === "") {
      alert("Blueprint name is required");
      return;
    }
    if (visibility === "") {
      alert("Visibility scope is required");
      return;
    }
    const graph = _.cloneDeep(reactFlowInstance.toObject());
    if (graph.nodes.length === 0) {
      alert("Please add at least one tool to create a blueprint");
      return;
    }
    graph.nodes.forEach(node => {
      for (const inputFieldName in node.data.inputData) {
        if (node.data.inputData[inputFieldName]?.type === "workflowInput") {
          node.data.inputData[inputFieldName] = null
        }
      }
    })
    // const test = JSON.stringify(graph.nodes.map(node => (
    //   {
    //     ...node,
    //     data: {
    //       ...node.data,
    //       source: undefined
    //     }
    //   }
    // )))
    const test2 = JSON.stringify(graph)
    if (!graph) {
      alert("Failed to get graph JSON");
      return;
    }
    await createBlueprintAPI(visibility, {
      blueprint: {
        blueprintName: name,
        description,
        tags,
        graph: graph
      }
    })
  }

  useEffect(() => {
    if (createBlueprintIsSuccess) {
      onSuccess();
      setOpen(false);
      resetFields();
      handledCreateBlueprintSuccess()
    }
  }, [createBlueprintIsSuccess, handledCreateBlueprintSuccess, onSuccess])

  const resetFields = () => {
    setName("");
    setDescription("");
    setTags([]);
    setTempTag("");
    setVisibility("public")
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={children}
    >
      <Modal.Header>Save as Blueprint</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Blueprints are a way to save your workflow for later use. You can create a blueprint from the current workflow by providing a name and description.</p>
          <p>Blueprints are saved in the database and can be accessed from the 'Blueprints' tab on home page.</p>
        </Modal.Description>
        <br />
        <Form >
          <Form.Input
            width={16}
            label="Blueprint name"
            placeholder="Blueprint name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Form.TextArea
            width={16}
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {
            token &&
            <Form.Field width={12}>
              <label>Visibility scope</label>
              {personalPublicButtonGroup()}
            </Form.Field>
          }
          <Form.Field width={16}>
            <label>Tags</label>
            <Form.Group width={16}>
              <Form.Input width={14}
                placeholder="Add tags"
                value={tempTag}
                onChange={(e) => setTempTag(e.target.value)}
              />
              <Form.Button
                type="button"
                width={2}
                fluid
                content="add"
                onClick={() => addTag(tempTag)}
              />
            </Form.Group>
            {tagsGroup()}
          </Form.Field>
        </Form>
        <Message
          error
          header="Failed to create blueprint"
          hidden={!createBlueprintIsFailed}
          content="Please try again later"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          loading={createBlueprintIsLoading}
          disabled={createBlueprintIsLoading}
          onClick={() => {
            createBlueprint();
          }}
        >
          Create
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BlueprintCreateDialog;