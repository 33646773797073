import { useMemo } from "react"
import { Tab } from "semantic-ui-react"
import BlueprintsTable from "./BlueprintsTable"
import styles from "./BlueprintsPage.module.css"

const BlueprintsPage = () => {
    const token = localStorage.getItem("token")
    const panes = useMemo(() => {
        const dashboardTabPane = (userType) => {
          return (
            <Tab.Pane>
              <BlueprintsTable userType={userType}/>
            </Tab.Pane>
          )
        }
        const output = [
            {
              menuItem: {
                key: "public",
                icon: "world",
                content: "Public blueprints"
              },
              render: () => dashboardTabPane("public")
            },
            { 
              menuItem: {
                key: "personal",
                icon: "user",
                content: "Personal blueprints",
              },
              render: () => dashboardTabPane("personal") }
          ]
          return token ? output : output.slice(0, 1)
        }, [token])
    return (
        <div>
            <div className={styles.dashboardPane}>
                <Tab panes={panes} />
            </div>
        </div>
    )
}

export default BlueprintsPage
