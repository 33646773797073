import { create } from "zustand";
import { myAxios } from "../utils/axios";
import _ from "lodash";

import {
  getJobStatusURL,
  postConfirmUploadedURL,
  postJobURL,
  // postRequestDownloadProcessOutputsURL,
  postCancelJobURL,
} from "../constant/url";
// import { mountStoreDevtool } from "simple-zustand-devtools";
// import Axios from "axios";
import { ConcurrentUploader } from "../utils/ConcurrentUploader";

export const useJobStore = create((set, get) => ({
  user: "public",
  jobStatus: "",
  processStatus: {}, // {"processId1": "WAITING", "processId2": "COMPLETED"}
  jobName: "",
  jobid: "",
  isSubmittingJob: false,
  isCancellingJob: false,
  uploadProgress: 0,
  uploader: undefined,
  setUser: (user) => {
    if (user !== "public" && user !== "personal") {
      throw new Error("user should be either 'public' or 'personal'");
    }
    set({ user });
  },
  resetAll: () => {
    set({
      user: "public",
      jobStatus: "",
      processStatus: {},
      jobName: "",
      jobid: "",
      isSubmittingJob: false,
      isCancellingJob: false,
      uploadProgress: 0,
    });
  },
  setJobName: (jobName) => {
    if (jobName) {
      set({ jobName });
    }
  },
  fetchJob: async () => {
    try {
      const jobid = get().jobid;
      const user = get().user;
      if (!jobid) {
        throw new Error("job id is required");
      }
      const response = await myAxios.get(getJobStatusURL(user, jobid));
      const { jobStatus, processStatus } = response.data;
      set({ jobStatus, processStatus });
    } catch (error) {
      // TODO: showing error in UI?
      console.error("failed to fetch job data: " + error);
    }
  },
  // requestDownloadProcessOutputs: async (processId) => {
  //     try {
  //         // TODO: check if the existing outputURLs are already expired
  //         const jobid = get().jobid
  //         if (!jobid) {
  //             throw new Error("job id is required")
  //         }
  //         const response = await myAxios.post(postRequestDownloadProcessOutputsURL(jobid, processId))
  //         console.debug(response)
  //         const processOutputURLs = response.data
  //         // TODO: extract expiry minute from url get params: X-Amz-Expires
  //         set((state) => ({
  //             outputURLs: {
  //                 ...state.outputURLs,
  //                 [processId]: processOutputURLs
  //             }
  //         }))

  //     } catch (error) {
  //         // TODO: showing error in UI?
  //         console.error("failed to request download process outputs: " + error)
  //     }
  // },
  submitJob: async (workflow, uploads) => {
    try {
      set({
        isSubmittingJob: true,
        jobStatus: "",
        jobid: "",
      });
      const user = get().user;
      if (!workflow || !uploads) {
        throw new Error("workflow and upload files are required");
      }
      const clonedUploads = _.cloneDeep(uploads);
      const clonedWorkflow = _.cloneDeep(workflow);
      const postJobResponse = await myAxios.post(
        postJobURL(user),
        clonedWorkflow
      );
      // check local uploads and server response uploads are the same
      if (clonedUploads) {
        const localUploadIds = new Set(
          clonedUploads.map((upload) => upload.name)
        );
        const serverUploadURLIds = new Set(
          postJobResponse.data.uploadURLs.map((url) => url.id)
        );
        if (!areSetsEqual(localUploadIds, serverUploadURLIds)) {
          throw new Error("upload file id mismatch");
        }
      }
      set({
        jobid: postJobResponse.data.jobid,
        jobStatus: postJobResponse.data.jobStatus,
      });
      const uploadURLs = postJobResponse.data.uploadURLs;
      const singleUploadHeaders = postJobResponse.data.uploadHeaders;
      let multipartResults = {};
      if (uploadURLs) {
        const uploadDetails = uploadURLs.reduce((memo, uploadDetail) => {
          const file = clonedUploads.find(
            (file) => uploadDetail.id === file.name
          );
          if (!file) {
            throw new Error("File not found: " + uploadDetail.id);
          }
          const { chunkSizeInByte, type, urls, url } = uploadDetail;
          return {
            ...memo,
            [file.name]: {
              file,
              chunkSizeInByte,
              type,
              url,
              urls: _.cloneDeep(urls),
            },
          };
        }, {});
        const uploader = new ConcurrentUploader({
          uploadDetails,
          threadsQuantity: 8,
          singleUploadHeaders,
        }).onProgress(({ sent, total, percentage }) => {
          console.log(
            `sent: ${sent}, total: ${total}, percentage: ${percentage}`
          );
          set({ uploadProgress: percentage });
        });
        set({ uploader: uploader });
        const result = await uploader.initialize();
        if (result === false) {
          set({ isSubmittingJob: false });
          return;
        }
        multipartResults = Object.keys(result).map((id) => ({
          id: id,
          parts: result[id],
        }));
      }
      await myAxios.post(
        postConfirmUploadedURL(user, postJobResponse.data.jobid),
        { multipartFiles: multipartResults }
      );
      set({ jobStatus: "UPLOADED" });
    } catch (error) {
      // TODO: showing error in UI?
      set({ jobStatus: "FAILED" });
      console.error("failed to fetch job data: " + error);
    } finally {
      set({ isSubmittingJob: false });
    }
  },
  cancelJob: async () => {
    try {
      set({ isCancellingJob: true });
      const uploader = get().uploader;
      const jobStatus = get().jobStatus;
      const jobid = get().jobid;
      const user = get().user;
      if (jobStatus === "UPLOADING" && uploader) {
        await uploader.abort();
      }
      if (!jobid) {
        throw new Error("job id is required");
      }
      const response = await myAxios.post(postCancelJobURL(user, jobid));
      set({ jobStatus: "CANCELED" });
    } catch (error) {
      // TODO: showing error in UI?
      console.error("failed to cancel job: " + error);
    } finally {
      set({ isCancellingJob: false });
    }
  },
}));

const areSetsEqual = (xs, ys) =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool("Store", useJobStore);
}
