import { create } from "zustand";
import { myAxios } from "../utils/axios";
import { getJobsURL } from "../constant/url";
// import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useDashboardStore = create((set, get) => ({
  jobs: { public: [], personal: [] },
  isLoading: false,
  fetchJobs: async (user) => {
    try {
      set({ isLoading: true });
      const response = await myAxios.get(getJobsURL(user));
      set({
        jobs: {
          ...get().jobs,
          [user]: response.data.sort(
            (a, b) => (b?.startTimestamp ?? 0) - (a?.startTimestamp ?? 0)
          ),
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool('useDashboardStore', useDashboardStore)
}
