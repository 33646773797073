import styles from './BottomBar.module.css';

const BottomBar = () => {

  return (
    <div className={styles.container}>
      The data refresh automatically for every 10 seconds.
    </div>
  )
}

export default BottomBar;