import { useState, useEffect, useMemo } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import DashboardTable from './DashboardTable'
import FixedMenu from '../general/FixedMenu'
import JobDetailsPanel from './JobDetailsPanel'
import BottomBar from './BottomBar'
import { Tab } from 'semantic-ui-react'
import styles from './Dashboard.module.css'
import TreeVis from '../visualization/tree/TreeVis'
import IgvVis from '../visualization/igv/IgvVis'
import LogViewer from '../visualization/log/LogViewer'

const Dashboard = () => {
  const [activeJobDetails, setActiveJobDetails] = useState(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      try {
        localStorage.setItem('token', token)
      } catch (error) {
        console.error(error)
      }
      navigate('/', { replace: true })
    }
  }, [searchParams, navigate])
  const [treeParams, setTreeParams] = useState(null)
  const [igvParams, setIgvParams] = useState(null)
  const [logProps, setLogProps] = useState(null)

  const token = localStorage.getItem('token')
  const panes = useMemo(() => {
    const dashboardTabPane = (user) => {
      return (
        <Tab.Pane>
          <DashboardTable
            user={user}
            setActiveJobDetails={setActiveJobDetails}
          />
          <TreeVis
            props={treeParams}
            onExit={() => {
              setTreeParams(null)
            }}
            setTreeParams={setTreeParams}
          />
          <IgvVis
            props={igvParams}
            onExit={() => {
              setIgvParams(null)
            }}
            setIgvParams={setIgvParams}
          />
          <LogViewer
            logProps={logProps}
            onExit={() => setLogProps(null)}
            setLogProps={setLogProps}
          />
          <JobDetailsPanel
            activeJobDetails={activeJobDetails}
            user={user}
            onExit={() => setActiveJobDetails(null)}
            setTreeParams={setTreeParams}
            setIgvParams={setIgvParams}
            setLogProps={setLogProps}
          />
        </Tab.Pane>
      )
    }
    const output = [
      {
        menuItem: {
          key: 'public',
          icon: 'world',
          content: 'Public workflows',
        },
        render: () => dashboardTabPane('public'),
      },
      {
        menuItem: {
          key: 'personal',
          icon: 'user',
          content: 'Personal workflows',
        },
        render: () => dashboardTabPane('personal'),
      },
    ]
    return token ? output : output.slice(0, 1)
  }, [token, treeParams, igvParams, logProps, activeJobDetails])

  return (
    <div>
      <div className={styles.dashboardPane}>
        <Tab panes={panes} />
      </div>
      <BottomBar />
    </div>
  )
}

export default Dashboard
