import createToolsRelations from "./createToolsRelations";

function transformData(toolsData, nodesData, nodesRelations, workflowName, datasets) {
    // Create a idMap object to hold the displayName to id mapping
    const idMap = {};
  
    // Iterate over each tool to access its parameters
    toolsData.forEach(tool => {
      // Iterate over each parameter of the tool
      idMap[tool.toolid] = {}

      tool.parameters.forEach(param => {
        // Add the displayName and an object with id and type to the idMap
        idMap[tool.toolid][param.displayName] = { "id": param.id, "type": param.type };
      });
    });
  
    // Helper to process each node to match the desired format
    const processNode = (node) => {
      const process = {
        processId: node.id,
        processLabel: node.data.label,
        type: node.data.toolName,
        hardware: {
          cpu: node.data.hardware.cpu,
          ramInGB: node.data.hardware.ramInGB,
          vmtype: "" 
        },
        parameters: [],
        outputs: [] 
      };

      const toolId = node.data.toolName;

      if (node.data.selectedDataType !== "NA") {
        process.isPairEnd = node.data.selectedDataType === "PE";
      }
      // Loop over each key in the node's data
      for (const key in node.data.inputData) {
        if (idMap[toolId][key]) {
          // If the key is in the idMap and does not have file type, it's a parameter
          if (idMap[toolId][key]["type"] !== "file") {
            process.parameters.push({
              id: idMap[toolId][key].id,
              value: node.data.inputData[key]
            });
          }
          else if (node.data.inputData[key]){
            if (node.data.inputData[key]["type"] === "workflowInput") {
              const file = node.data.inputData[key]["value"]
              const item = {
                id: idMap[toolId][key].id,
                source: {
                  type: "workflowInput",
                  fileName: file.name,
                  fileSizeInByte: file.size
                }
              };
              process.parameters.push(item);
            }
  
            else if (node.data.inputData[key]["type"] === "dataset") {
              const item = {
                id: idMap[toolId][key].id,
                source: {
                  type: "dataset",
                  datasetId: findDatasetIdByDisplayName(node.data.inputData[key]["value"], datasets),
                  datasetType: findDatasetTypeByDisplayName(node.data.inputData[key]["value"], datasets)
                }
              };
              process.parameters.push(item);
            }
            else {
              const item = {
                id: idMap[toolId][key].id,
                source: {
                  type: "processOutput",
                  processId: node.data.inputData[key]["value"]["node"],
                  outputId: node.data.inputData[key]["value"]["output"]
                }
              };
              process.parameters.push(item);
            }
          }
        }
      }
      if (nodesRelations["outgoing"][node.id] === null){
        const toolObject = toolsData.find(item => item.toolid === node.data.toolName);
        const item = {
          workflowOutputId: toolObject.outputs.map(output => output.id)
        };
        process.outputs.push(item);
      }
      if (process.outputs.length === 0) {
        delete process.outputs;
      }
      return process;
    };
  
    // Map the nodes to the desired process structure
    const processes = nodesData.map(processNode);
  
    // Construct the final structure
    return {
      workflow: {
        name: workflowName,
        processes: processes,
      }
    };
  }

function generateUniqueWorkflowName(userId) {
    const timestamp = new Date().getTime(); // Get current time in milliseconds
    const randomElement = Math.random().toString(36).substring(2, 15); // Get a random string
    const workflowPrefix = 'workflow'; // A prefix for the workflow name

    return `${workflowPrefix}_${userId}_${timestamp}_${randomElement}`;
}
  
function createWorkflowJson(toolsData, nodesData, edgesData, userId, datasets, jobName){
    const nodesRelations = createToolsRelations(edgesData);
    const workflowName = jobName
    const workflowJson = transformData(toolsData, nodesData, nodesRelations, workflowName, datasets)
    return workflowJson;
}

function findDatasetIdByDisplayName(displayName, datasets) {
  const dataset = datasets.find(dataset => dataset?.displayName === displayName);
  return dataset ? dataset.id : null;
}

function findDatasetTypeByDisplayName(displayName, datasets) {
  const dataset = datasets.find(dataset => dataset?.displayName === displayName);
  return dataset ? dataset.type : null;
}



export default createWorkflowJson;