import { MarkerType } from "reactflow";

const addEndMarker = (edge) => ({
  ...edge,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: "#b1b1b7",
  },
});
export default addEndMarker;
