import { Outlet } from "react-router"
import FixedMenu from "../general/FixedMenu"

const HomePage = () => {

    return (
        <div style={{
        }}>
            <FixedMenu />
            <div>
                <Outlet />
            </div>
        </div>
    )
}

export default HomePage
