import { create } from "zustand";
import { myAxios } from "../utils/axios";
import { getDatasetsURL } from "../constant/url";
// import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useDatasetStore = create((set) => ({
  datasets: [],
  fetchDatasets: async () => {
    try {
      const response = await myAxios.get(getDatasetsURL);
      set({ datasets: response.data });
    } catch (error) {
      console.error("failed to fetch datasetsdata: " + error);
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool('Store', useDatasetStore);
}
