import { Container, Menu, Image, Button } from "semantic-ui-react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./FixedMenu.module.css";
import logo from "../../img/logo.png";

const FixedMenu = () => {
  const token = localStorage.getItem("token");
  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  const navigate = useNavigate();
  const navToDashboard = () => {
    navigate("/");
  };
  return (
    <Menu className={styles.menu} stackable>
      <Container style={{ width: "100%" }}>
        <Menu.Item
          as="a"
          header
          onClick={() => {
            navToDashboard();
          }}
        >
          <Image
            size="mini"
            src={logo}
            style={{
              marginRight: "1.5em",
            }}
          />
          Biotailor
        </Menu.Item>
        <Menu.Item as={NavLink} to="/">
          Workflows
        </Menu.Item>
        <Menu.Item as={NavLink} to="/blueprints">
          Blueprints
        </Menu.Item>
        {token ? (
          <Menu.Item as="a" onClick={logout}>
            Logout
          </Menu.Item>
        ) : (
          <Menu.Item as={NavLink} to="/login">
            Login
          </Menu.Item>
        )}
        <Menu.Item as="div" position="right">
          <NavLink to="/create">
            <Button color="orange">Create workflow</Button>
          </NavLink>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default FixedMenu;
