"use strict";
import React, { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import AbundanceChart from "./AbundanceChart";
import textData from "./AbundanceData.js";

export default function AbundanceVis() {
  const [chartSize, setChartSize] = useState(100); // Initialize chart size at 100%

  const lines = textData.trim().split("\n");
  const headers = lines[0].split("\t");
  // console.log(headers);
  const data = lines.slice(1).map((line) => {
    const values = line.split("\t");
    const label = values.shift();
    return {
      label,
      values: values.map(Number),
    };
  });

  // Handlers for buttons
  const handleIncreaseSize = () => {
    setChartSize((prevSize) => Math.min(prevSize + 10, 200)); // Increase size up to 200%
  };

  const handleDecreaseSize = () => {
    setChartSize((prevSize) => Math.max(prevSize - 10, 100)); // Decrease size down to 50%
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Abundance and Richness of ARGs</h1>
        <div style={{ marginLeft: 10 }}>
          <button onClick={handleDecreaseSize}>Decrease Size</button>
          <button onClick={handleIncreaseSize}>Increase Size</button>
        </div>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <AbundanceChart data={data} headers={headers} chartSize={chartSize} />
      </div>
    </div>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <AbundanceVis />
  </StrictMode>
);
