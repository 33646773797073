import React, { useEffect, useState } from "react";
import { Form, Button, FormSelect, Icon, Popup } from "semantic-ui-react";
import { useNodes, useEdges } from "reactflow";
import "./FileInput.css";

const FileInput = (props) => {
  const [selectedMode, setSelectedMode] = useState("Output");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const nodes = useNodes();
  const edges = useEdges();
  const [connectingNodesOutputs, setConnectingNodesOutputs] = useState([]);

  useEffect(() => {
    const currentNodeId = props.id;
    const connectingEdges = edges.filter(
      (edge) => edge.target === currentNodeId
    ); // Find out those edge(s) target on this node
    const connectingNodes = nodes.filter((node) =>
      connectingEdges.some((edge) => edge.source === node.id)
    ); // Find out those node(s) from the edge(s)
    setConnectingNodesOutputs(connectingNodes);
    // console.log(connectingNodes);
    // console.log(props.data.source);
  }, [nodes, edges]);

  useEffect(() => {
    if (props.inputData && props.inputData[props.displayName]) {
      const inputData = props.inputData[props.displayName];
      // console.log(props);
      if (inputData && inputData.type === "dataset") {
        setSelectedDataset(inputData.value);
        setSelectedMode("Dataset");
      } else if (inputData && inputData.type === "processOutput") {
        setSelectedOutput(inputData.value.output);
        setSelectedMode("Output");
      } else if (inputData && inputData.type === "workflowInput") {
        setSelectedMode("Upload");
        setSelectedFile(inputData.value);
      }
    }
  }, [props.inputData]);

  const handleModeChange = (mode) => {
    setSelectedMode(mode);
  };

  const handleDropdownChange = (e, { value, options }) => {
    if (value === "") {
      if (selectedMode === "Dataset") {
        setSelectedDataset(null);
      } else if (selectedMode === "Output") {
        setSelectedOutput(null);
      }
      setSelectedFile(null);
      return;
    }
    const selectedOption = options.find((option) => option.value === value);
    if (selectedMode === "Dataset") {
      setSelectedDataset(value);
      setSelectedOutput(null);
      setSelectedFile(null);
      props.setInputData((prevState) => ({
        ...prevState,
        [props.displayName]: {
          type: "dataset",
          value: value,
        },
      }));
    } else if (selectedMode === "Output") {
      setSelectedOutput(value);
      setSelectedDataset(null);
      setSelectedFile(null);
      // Ensure that there is at least one source and use the first source's id
      const sourceId =
        connectingNodesOutputs.length > 0 ? connectingNodesOutputs[0].id : null;
      props.setInputData((prevState) => ({
        ...prevState,
        [props.displayName]: {
          type: "processOutput",
          value: { node: selectedOption.key.split("_")[0], output: value },
        },
      }));
    }
  };
  function getNodeOutputs(nodeId) {
    // Find the tool by toolId from the reference data
    const node = nodes.find((node) => node.id === nodeId);
    const tool = props.tools.find((tool) => tool.toolid === node.data.toolName);

    // If the tool isn't found, return an empty array
    if (!tool || !node) {
      return [];
    }

    // Depending on the dataType, we filter the outputs
    const isPairEndOnly = node.data.selectedDataType === "PE";
    const isSingleEndOnly = node.data.selectedDataType === "SE";

    // Filter outputs based on isPairEndOnly or isSingleEndOnly flags
    const filteredOutputs = tool.outputs.filter((output) => {
      // If tool has no distinction between SE and PE, include all outputs
      if (!tool.hasSEPE) {
        return true;
      }

      // If output has isPairEndOnly/isSingleEndOnly property, it needs to match the dataType
      if (
        output.isPairEndOnly !== undefined ||
        output.isSingleEndOnly !== undefined
      ) {
        return (
          (isPairEndOnly && output.isPairEndOnly) ||
          (isSingleEndOnly && output.isSingleEndOnly)
        );
      }

      // If isPairEndOnly or isSingleEndOnly is not specified, include the output
      return true;
    });
    // Map the filtered outputs to the desired format
    return filteredOutputs.map((output) => ({
      key: node.id + "_" + output.id,
      text: node.data.label + " " + output.displayName,
      value: output.id,
    }));
  }

  const getDropdownOptions = () => {
    switch (selectedMode) {
      case "Output":
        if (connectingNodesOutputs && connectingNodesOutputs.length > 0) {
          let allOutputs = [];
          connectingNodesOutputs.forEach((source) => {
            const outputs = getNodeOutputs(source.id);
            outputs.forEach((output) => {
              allOutputs.push({
                key: output.key,
                text: output.text,
                value: output.value,
              });
            });
          });
          return allOutputs;
        } else {
          return [
            {
              key: "no-options",
              text: "No options available",
              value: "No options available",
              disabled: true,
            },
          ];
        }
      case "Dataset":
        const inputAcceptedTypes =
          props.data?.validation?.[props.displayName]?.acceptedInputTypes ?? [];
        // console.log(inputAcceptedTypes)
        return props.datasets && props.datasets.length > 0
          ? props.datasets
              .filter(
                (dataset) =>
                  inputAcceptedTypes.length === 0 ||
                  inputAcceptedTypes.includes(dataset.type)
              )
              .map((dataset) => ({
                key: dataset.id,
                text: dataset.displayName,
                value: dataset.displayName,
              }))
          : [
              {
                key: "no-options",
                text: "No options available",
                value: "No options available",
                disabled: true,
              },
            ];
      default:
        return selectedFile
          ? [
              {
                key: selectedFile.name,
                text: selectedFile.name,
                value: selectedFile,
              },
            ]
          : [];
    }
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 1) {
      alert("Please upload only one file at a time.");
    } else {
      handleFileUpload(files);
    }
  };

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      setSelectedDataset(null);
      setSelectedOutput(null);
      setSelectedFile(files[0]);
      // console.log(files[0]);
      props.setInputData((prevState) => ({
        ...prevState,
        [props.displayName]: { type: "workflowInput", value: files[0] },
      }));
      // console.log(props.inputData);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.currentTarget.classList.add("dragover");
  };

  const handleDragLeave = (event) => {
    setIsDragging(false);
    event.currentTarget.classList.remove("dragover");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    event.currentTarget.classList.remove("dragover");
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      alert("Please upload only one file at a time.");
    } else {
      handleFileUpload(files);
    }
  };
  return (
    <Form.Field>
      <label>{props.displayName}</label>
      <Button.Group size="tiny" style={{ width: "100%" }}>
        <Popup
          content="Select from process outputs"
          trigger={
            <Button
              color={selectedMode === "Output" ? "black" : ""}
              icon
              labelPosition="left"
              active={selectedMode === "Output"}
              onClick={() => handleModeChange("Output")}
            >
              <Icon name="file outline" />
              Output
            </Button>
          }
        />
        <Popup
          content="Select from datasets"
          trigger={
            <Button
              color={selectedMode === "Dataset" ? "black" : ""}
              icon
              labelPosition="left"
              active={selectedMode === "Dataset"}
              onClick={() => handleModeChange("Dataset")}
            >
              <Icon name="database" />
              Dataset
            </Button>
          }
        />
        <Popup
          content="Upload a file"
          trigger={
            <Button
              color={selectedMode === "Upload" ? "black" : ""}
              icon
              labelPosition="left"
              active={selectedMode === "Upload"}
              onClick={() => handleModeChange("Upload")}
            >
              <Icon name="upload" />
              Upload
            </Button>
          }
        />
      </Button.Group>
      {selectedMode === "Upload" ? (
        <Form.Group widths="equal" style={{ marginTop: "10px" }}>
          {/* <Form.Input
            fluid
            readOnly
            hidden
            error={props.error}
            value={selectedFile ? selectedFile.name : null}
            placeholder={"Upload file"}
          /> */}
          <div
            className={`upload-area ${isDragging ? "dragging" : ""}`}
            onClick={() => document.getElementById(props.displayName).click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {selectedFile
              ? selectedFile.name
              : "Drag & Drop or Click to Upload a File"}
          </div>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            id={props.displayName}
          />
        </Form.Group>
      ) : (
        <FormSelect
          fluid
          selection
          clearable
          error={props.error}
          search
          options={getDropdownOptions()}
          placeholder="Select an option"
          className="custom-form-select"
          style={{ width: "100%", marginTop: "10px" }}
          value={selectedMode === "Dataset" ? selectedDataset : selectedOutput}
          onChange={handleDropdownChange}
          tabIndex={10}
          id={props.displayName}
        />
      )}
    </Form.Field>
  );
};

export default FileInput;
