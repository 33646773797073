export const backendURL = process.env.REACT_APP_API_URL;

// tools
export const getToolsURL = "tools";

// datasets
export const getDatasetsURL = "datasets";
export const getTreeDatasetURL = (jobId, processId, outputId) =>
  `/jobs/public/${jobId}/${processId}/outputs/${outputId}/request-download`;

// jobs
export const getJobStatusURL = (user, jobid) => `jobs/${user}/${jobid}/status`;
export const getJobsURL = (user) => `jobs/${user}`;
export const postJobURL = (user) => `jobs/${user}`;
export const postConfirmUploadedURL = (user, jobid) =>
  `jobs/${user}/${jobid}/confirm-uploaded`;
export const postRequestDownloadProcessOutputsURL = (user, jobId, processId) =>
  `jobs/${user}/${jobId}/${processId}/outputs/request-download`;
export const postCancelJobURL = (user, jobid) => `jobs/${user}/${jobid}/cancel`;
export const getProcessLogURL = (user, jobId, processId) =>
  `jobs/${user}/${jobId}/${processId}/log`;

// auth
export const authGoogleURL = "auth/google/authorize";
export const authEmailURL = "auth/link/authorize";

// blueprints
export const createBlueprintURL = (userType) => `blueprints/${userType}`;
export const getBlueprintURL = (userType, id) => `blueprints/${userType}/${id}`;
export const getBlueprintsURL = (userType) => `blueprints/${userType}`;
export const editBlueprintURL = (userType, id) =>
  `blueprints/${userType}/${id}`;
export const deleteBlueprintURL = (userType, id) =>
  `blueprints/${userType}/${id}`;

// user
export const getMe = "user/me"
