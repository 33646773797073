import React, { useEffect, useState } from 'react'
import { colorGenerator } from '../utilities/colorMapping'
// import categoryColors from '../../styles/colors.js'

// Group tools by category
const groupToolsByCategory = (tools) => {
  const grouped = {}

  tools.forEach((tool) => {
    // console.log(tool);
    const category = tool.category
    if (!grouped[category]) {
      grouped[category] = []
    }
    grouped[category].push(tool)
  })

  return grouped
}

// Sort tools alphabetically by displayName within each category
const sortTools = (tools) => {
  return tools.sort((a, b) => a.displayName.localeCompare(b.displayName))
}

export default (props) => {
  const tools = props.tools
  const [groupedTools, setGroupedTools] = useState({})

  const length =
    Object.keys(groupedTools).length == 0 ? 2 : Object.keys(groupedTools).length

  const onDragStart = (event, nodeType, index) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('application/reactflow/index', index)
  }

  useEffect(() => {
    const fetchAndSetTools = async () => {
      const grouped = groupToolsByCategory(tools)
      for (const category in grouped) {
        grouped[category] = sortTools(grouped[category])
      }
      // console.log(Object.keys(grouped).length);
      setGroupedTools(grouped)
    }

    fetchAndSetTools()
  }, [tools])

  return (
    // <aside className="tool-aside">
    //   <div className="description">
    //     You can drag these tools to the pane on the right.
    //   </div>
    //   {/* <div className="group-name">Group1</div> */}
    //   {tools.map((a, index) => {
    //     // console.log(a.toolid);
    //     // let toolName = a.toolid;
    //     return (
    //       <div
    //         className="dndnode"
    //         onDragStart={(event) => onDragStart(event, a.toolid, index)}
    //         draggable
    //         key={a.toolid}
    //         style={{
    //           backgroundColor: `${colorGenerator(false)[index]}`,
    //           color: `${colorGenerator(true)[index]}`,
    //           borderColor: `${colorGenerator(true)[index]}`,
    //         }}
    //       >
    //         {a.toolid}
    //         {/* {`${colorGenerator(false)[index]}`}
    //         {`${colorGenerator(true)[index]}`} */}
    //       </div>
    //     );
    //   })}
    // </aside>

    <aside className="tool-aside">
      <div className="description">
        You can drag these tools to the pane on the right.
      </div>
      {Object.keys(groupedTools).map((category, index) => (
        <div key={category}>
          {/* {console.log(category)} */}
          {/* {console.log(index)} */}
          <h4>{category}</h4>
          {groupedTools[category].map((tool) => {
            const overallIndex = tools.findIndex(
              (t) => t.toolid === tool.toolid
            )
            // const colors = categoryColors[category] || {
            //   background: "#ffffff",
            //   border: "#000000",
            // };
            return (
              <div
                className="dndnode"
                onDragStart={(event) => onDragStart(event, tool.toolid, index)}
                draggable
                key={tool.toolid}
                style={{
                  backgroundColor: `${colorGenerator(false, length)[index]}`,
                  color: `${colorGenerator(false, length)[index]}`,
                  borderColor: `${colorGenerator(true, length)[index]}`,
                  // backgroundColor: colors.background,
                  // color: colors.border,
                  // borderColor: colors.border,
                }}
              >
                {/* {console.log(tool.displayName)} */}
                <p>{tool.displayName}</p>
              </div>
            )
          })}
        </div>
      ))}
    </aside>
  )
}
