import { Table, Placeholder, PlaceholderLine } from 'semantic-ui-react';

const LoadingPlaceholder = () => (
  <>
    <Table.Row>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  </>
)

export default LoadingPlaceholder;