import React, { useState, useRef, useCallback, useEffect } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
  MiniMap,
  Background,
  getOutgoers,
} from 'reactflow'
import 'reactflow/dist/style.css'

import Toolbar from './Toolbar'
import HeadMenu from './HeadMenu'
import Jobbar from './Jobbar'
import Node from '../nodes/Node'
import LeavingBlocker from '../general/LeavingBlocker'
import addEndMarker from '../utilities/addEndMarker'
import { useToolStore } from '../../store/toolStore'
import { useMemo } from 'react'
import {
  Dimmer,
  Loader,
  Button,
  Card,
  Segment,
  Message,
  Progress,
} from 'semantic-ui-react'
import '../../index.css'
import { useDatasetStore } from '../../store/datasetStore'
import { useJobStore } from '../../store/jobStore'
import CustomEdge from '../utilities/CustomEdge'
import useNodesAndEdgesStore from '../store/nodesAndEdgesStore'
import { useBeforeUnload } from 'react-router-dom'
import { useBlocker } from 'react-router'
import { useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import styles from './FlowCanvas.module.css'
import { tr } from '@faker-js/faker'

const minimapStyle = {
  height: 160,
}

const flowKey = 'example-flow'

const FlowCanvas = (props) => {
  const reactFlowWrapper = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [history, setHistory] = useState([{ nodes: nodes, edges: edges }])
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0)
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(true)
  const isDeleted = useNodesAndEdgesStore((state) => state.isDeleted)
  const setIsDeleted = useNodesAndEdgesStore((state) => state.setIsDeleted)
  const storeNodes = useNodesAndEdgesStore((state) => state.nodes)
  const storeEdges = useNodesAndEdgesStore((state) => state.edges)
  const storeSetNodes = useNodesAndEdgesStore((state) => state.setNodes)
  const storeSetEdges = useNodesAndEdgesStore((state) => state.setEdges)

  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [jobbarOpen, setJobbarOpen] = useState(false)
  const [jobbarType, setJobbarType] = useState('')
  const [jsonData, setJsonData] = useState([])
  const { setViewport } = useReactFlow()
  const [drag, setDrag] = useState(true)
  // const [isInputNode, setIsInputNode] = useState(true);
  const tools = useToolStore((state) => state.tools)
  const fetchTools = useToolStore((state) => state.fetchTools)
  const fetchDatasets = useDatasetStore((state) => state.fetchDatasets)
  const defaultViewport = { x: 0, y: 0, zoom: 1.5 }
  const jobStatus = useJobStore((state) => state.jobStatus)
  const isSubmittingJob = useJobStore((state) => state.isSubmittingJob)
  const [isJobFailedDialogShowing, setIsJobFailedDialogShowing] =
    useState(false)
  const resetAll = useJobStore((state) => state.resetAll)
  const uploadProgress = useJobStore((state) => state.uploadProgress)

  const edgeTypes = {
    customedge: (edgeProps) => (
      <CustomEdge {...edgeProps} setNodes={setNodes} />
    ),
  }

  const getId = useCallback(() => {
    const maxTries = 100
    let tries = 0
    do {
      const id = 'node' + uuidv4().toString().replaceAll('-', '')
      if (!nodes.some((node) => node.id === id)) {
        return id
      }
    } while (tries++ < maxTries)
  }, [nodes])

  // Message board
  const [message, setMessage] = useState([]) // {id: "abc", type: "successs"| "error" | "warning" | "info", message: "message"}
  const addMessage = (type, tempMsg) => {
    const id = Date.now()
    setMessage([...message, { id, type, message: tempMsg }])
  }
  const deleteMessage = (id) => {
    setMessage(message.filter((msg) => msg.id !== id))
  }

  useEffect(() => {
    return () => {
      resetAll()
    }
  }, [])

  const updateFlowState = (newNodes, newEdges) => {
    setShouldUpdateHistory(true)
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, currentHistoryIndex + 1)
      newHistory.push({ nodes: newNodes, edges: newEdges })
      return newHistory
    })
    setCurrentHistoryIndex(currentHistoryIndex + 1)
  }

  const compareNodes = (nodes1, nodes2) => {
    if (nodes1.length !== nodes2.length) {
      return true
    }
    for (let i = 0; i < nodes1.length; i++) {
      if (nodes1[i].id !== nodes2[i].id) {
        return true
      }
    }
    return false
  }

  const compareEdges = (edges1, edges2) => {
    if (edges1.length !== edges2.length) {
      return true
    }
    for (let i = 0; i < edges1.length; i++) {
      if (
        edges1[i].id !== edges2[i].id ||
        edges1[i].source !== edges2[i].source ||
        edges1[i].target !== edges2[i].target
      ) {
        return true
      }
    }
    return false
  }

  const compareNodePositions = (nodes1, nodes2) => {
    for (let i = 0; i < nodes1.length; i++) {
      if (
        nodes1[i].position.x !== nodes2[i].position.x ||
        nodes1[i].position.y !== nodes2[i].position.y
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (isDeleted) {
      setNodes(storeNodes)
      setEdges(storeEdges)
      setIsDeleted(false)
      updateFlowState(storeNodes, storeEdges)
    } else {
      if (
        compareNodes(nodes, history[currentHistoryIndex].nodes) ||
        compareEdges(edges, history[currentHistoryIndex].edges)
      ) {
        updateFlowState(nodes, edges)
      }
      if (
        nodes.length !== storeNodes.length ||
        edges.length !== storeEdges.length
      ) {
        storeSetNodes(nodes)
        storeSetEdges(edges)
      }
    }
  }, [nodes, edges, storeNodes, storeEdges])

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        const nodeId = node.id
        // Step 1: Extract source IDs from edges where target matches nodeId
        const sourceIdList = edges
          .filter((edge) => edge.target === nodeId)
          .map((edge) => edge.source)
        // Step 2: Filter inputData and source based on sourceIdList
        const newInputData = {}
        if (node.data.inputData) {
          for (const key in node.data.inputData) {
            const value = node.data.inputData[key]
            if (value) {
              if (
                value?.value?.node &&
                sourceIdList.includes(value?.value?.node)
              ) {
                newInputData[key] = node.data.inputData[key]
              } else if (
                value?.value?.node &&
                !sourceIdList.includes(value?.value?.node)
              ) {
                newInputData[key] = undefined
              } else if (!value?.value?.node) {
                if (
                  typeof value !== 'undefined' ||
                  typeof value !== 'null' ||
                  typeof value !== 'NaN'
                ) {
                  newInputData[key] = value
                } else {
                  newInputData[key] = undefined
                }
              }
            } else {
              newInputData[key] = undefined
            }
          }
        }
        let newSources = node.data.source
        if (Array.isArray(node.data.source)) {
          const filteredSources = node.data.source.filter((source) =>
            sourceIdList.includes(source?.id)
          )
          newSources = filteredSources.length > 0 ? filteredSources : null
        }
        return {
          ...node,
          data: {
            ...node.data,
            inputData: newInputData,
            source: newSources,
          },
        }
      })
    )
  }, [edges])

  const onUndo = useCallback(() => {
    if (currentHistoryIndex === 0) return
    const previousIndex = currentHistoryIndex - 1
    const previousState = history[previousIndex]
    setShouldUpdateHistory(false)
    setNodes(previousState.nodes)
    setEdges(previousState.edges)
    setCurrentHistoryIndex(previousIndex)
  })

  const onRedo = useCallback(() => {
    if (currentHistoryIndex >= history.length) return
    const nextIndex = currentHistoryIndex + 1
    const nextState = history[nextIndex]
    setShouldUpdateHistory(false)
    setNodes(nextState.nodes)
    setEdges(nextState.edges)
    setCurrentHistoryIndex(nextIndex)
  })

  useEffect(() => {
    fetchDatasets()
  }, [fetchDatasets])

  useEffect(() => {
    fetchTools()
  }, [fetchTools])

  const nodeTypes = useMemo(() => {
    let types = {}
    tools
      .map((a) => a.toolid)
      .forEach((e) => {
        types[e] = Node
      })
    return types
  }, [tools])

  const onConnect = useCallback(
    (params) => {
      const customEdge = addEndMarker({
        ...params,
        type: 'customedge',
      })
      setEdges((eds) => addEdge(customEdge, eds))
    },
    [setEdges]
  )

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const isValidConnection = useCallback(
    (connection) => {
      const target = nodes.find((node) => node.id === connection.target)
      const source = nodes.find((node) => node.id === connection.source)

      if (!target.data.source) {
        target.data.source = []
      }
      if (
        (Array.isArray(target.data.source) &&
          !target.data.source.some((src) => src.id === source.id)) ||
        !Array.isArray(target.data.source)
      ) {
        if (source != null || source != undefined) {
          if (Array.isArray(target.data.source)) {
            target.data.source.push(source)
          } else {
            target.data.source = [source]
          }
        }
      }

      target.data.isInputNode = false
      target.data.file = null

      // if (!source.data.targets) {
      //   source.data.targets = [];
      // }
      // if (!source.data.targets.some(tgt => tgt.id === target.id)) {
      //   source.data.targets.push(target);
      // }

      source.selected = false
      target.selected = false

      const hasCycle = (node, visited = new Set(), pathStack = new Set()) => {
        if (pathStack.has(node.id)) {
          return true
        }
        if (visited.has(node.id)) {
          return false
        }

        visited.add(node.id)
        pathStack.add(node.id)

        const outgoers = getOutgoers(node, nodes, edges)
        for (const outgoer of outgoers) {
          if (hasCycle(outgoer, visited, pathStack)) {
            return true
          }
        }

        pathStack.delete(node.id)
        return false
      }
      return !hasCycle(target, new Set(), new Set())
    },
    [nodes, edges]
  )

  const onChange = useCallback(
    (d) => {
      if (d !== null) {
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === d.id) {
              const label = d.label
              const inputData = d.inputData
              const hardware = d.hardware
              const selectedDataType = d.selectedDataType
              node.selected = false
              return {
                ...node,
                data: {
                  ...node.data,
                  isNodeToolBarVisible: false,
                  label: label,
                  inputData: inputData,
                  hardware: hardware,
                  selectedDataType: selectedDataType,
                },
              }
            } else {
              return {
                ...node,
                data: {
                  ...node.data,
                },
              }
            }
          })
        )
      }
    },
    [setNodes]
  )

  const handleNodeDragStop = useCallback(
    (event, node) => {
      if (compareNodePositions(nodes, history[currentHistoryIndex].nodes)) {
        updateFlowState(nodes, edges)
      }
    },
    [currentHistoryIndex, edges, history, nodes, updateFlowState]
  )

  const handlePaneClick = useCallback(() => {
    setNodes((nds) =>
      nds.map((nd) => ({
        ...nd,
        data: {
          ...nd.data,
          isNodeToolBarVisible: false,
          isNodeMenuVisible: Math.random(),
        },
      }))
    )
  }, [setNodes])

  const handleNodeClick = useCallback(
    (event, node) => {
      setNodes((nds) =>
        nds.map((nd) =>
          nd.id === node.id
            ? {
                ...nd,
                data: {
                  ...nd.data,
                  isNodeToolBarVisible: !node.data.isNodeToolBarVisible,
                },
              }
            : {
                ...nd,
                data: {
                  ...nd.data,
                  isNodeToolBarVisible: false,
                },
              }
        )
      )
    },
    [setNodes]
  )

  const handleNodeDrag = useCallback(
    (event, node) => {
      setNodes((nds) =>
        nds.map((nd) =>
          nd.id === node.id
            ? {
                ...nd,
                data: {
                  ...nd.data,
                  isNodeToolBarVisible: false,
                  isNodeMenuVisible: Math.random(),
                },
              }
            : nd
        )
      )
    },
    [setNodes]
  )

  const onDrop = useCallback(
    (event) => {
      event.preventDefault()
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')
      const toolName = type
      const index = event.dataTransfer.getData('application/reactflow/index')
      console.log(index)
      let category = 'Uncategorised'
      const parameters = () => {
        let params
        let input = []
        let displayName = []
        let defaultValue = []
        let description = []
        let output = []
        let hasSEPE = []
        let defaultHardware = []
        let paramsDataType = {}
        const validation = {}

        for (const e in tools) {
          if (tools[e].toolid == type) {
            output = tools[e].outputs
            params = tools[e].parameters
            output = tools[e].outputs
            hasSEPE = tools[e].hasSEPE
            defaultHardware = tools[e].defaultHardware
            category = tools[e].category
            break
          }
        }
        params.forEach((e) => {
          input.push(e.type)
          displayName.push(e.displayName)
          description.push(e.description)
          defaultValue.push(
            e.defaultValue || e.defaultValue == false || true
              ? e.defaultValue
              : null
          )
          if (e.isPairEndOnly) {
            paramsDataType[e.displayName] = 'PE'
          } else if (e.isSingleEndOnly) {
            paramsDataType[e.displayName] = 'SE'
          } else {
            paramsDataType[e.displayName] = 'NA'
          }
          validation[e.displayName] = e?.validation
        })
        return {
          input,
          displayName,
          description,
          defaultValue,
          output,
          hasSEPE,
          defaultHardware,
          paramsDataType,
          validation,
        }
      }

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      })
      const parametersResult = parameters()
      const defaultFilledInputData = {}
      parametersResult.displayName.forEach((name, index) => {
        const defaultValue = parametersResult.defaultValue[index]
        defaultFilledInputData[name] = defaultValue
      })
      const newNode = {
        id: getId(),
        type,
        position,
        data: {
          toolName: `${toolName}`,
          label: `${toolName}`,
          onChange: onChange,
          input: parametersResult.input,
          output: parametersResult.output,
          displayName: parametersResult.displayName,
          description: parametersResult.description,
          defaultValue: parametersResult.defaultValue,
          hasSEPE: parametersResult.hasSEPE,
          hardware: parametersResult.defaultHardware,
          paramsDataType: parametersResult.paramsDataType,
          selectedDataType: parametersResult.hasSEPE ? 'SE' : 'NA',
          validation: parametersResult.validation,
          isNodeToolBarVisible: false,
          isNodeMenuVisible: 0,
          source: null,
          target: null,
          inputData: defaultFilledInputData,
          index: index,
          category: category,
        },
      }
      setNodes((nds) => nds.concat(newNode))
    },
    [getId, onChange, reactFlowInstance, setNodes, tools]
  )

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject()
      flow.nodes.forEach((node) => {
        for (const inputFieldName in node.data.inputData) {
          if (node.data.inputData[inputFieldName]?.type === 'workflowInput') {
            node.data.inputData[inputFieldName] = null
          }
        }
      })
      const flowString = JSON.stringify(flow)
      localStorage.setItem(flowKey, flowString)
    }
  }, [reactFlowInstance])

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey))

      if (flow) {
        const { x = 0, y = 0, zoom = 1.5 } = flow.viewport
        setNodes(
          flow.nodes.map((node) => {
            return {
              ...node,
              data: {
                ...node.data,
                onChange,
              },
            }
          })
        )
        setEdges(flow.edges || [])
        setViewport({ x, y, zoom })
      }
    }

    restoreFlow()
  }, [onChange, setEdges, setNodes, setViewport])

  const onClear = useCallback(() => {
    setNodes([])
    setEdges([])
    setViewport({ x: 0, y: 0, zoom: 1.5 })
  }, [setEdges, setNodes, setViewport])

  const onNodeSelected = () => {
    const selectedNode = nodes.map((nd) => nd.selected)
    if (selectedNode.includes(true)) {
      setDrag(false)
    } else {
      setDrag(true)
    }
  }

  const cancelJob = useJobStore((state) => state.cancelJob)
  const isCancellingJob = useJobStore((state) => state.isCancellingJob)
  const onCancel = (e) => {
    cancelJob()
  }

  useEffect(() => onNodeSelected())

  const isSubmittingOrRunning = useMemo(() => {
    return (
      ['UPLOADING', 'UPLOADED', 'STARTED'].includes(jobStatus) ||
      isSubmittingJob
    )
  }, [jobStatus, isSubmittingJob])

  const loadingDescription = useMemo(() => {
    if (isCancellingJob) return 'Cancelling...'
    switch (jobStatus) {
      case 'UPLOADING':
        return 'Uploading data...'
      case 'UPLOADED':
        return 'Preparing cloud infrastructure...'
      case 'STARTED':
        return 'Pipeline is running...'
      default:
        return 'Uploading data...'
    }
  }, [jobStatus, isCancellingJob])
  useEffect(() => {
    if (jobStatus === 'FAILED') {
      setIsJobFailedDialogShowing(true)
    }
  }, [jobStatus])

  const shouldBlockLeaving = useMemo(() => isSubmittingJob, [isSubmittingJob])

  const blockingMessage = useMemo(() => {
    if (isSubmittingJob) {
      return 'Your current job submission might be interupted.'
    } else {
      return 'Your unsaved progress will be lost.'
    }
  }, [isSubmittingJob])

  const { state } = useLocation()
  const { blueprintGraph } = state || {}
  useEffect(() => {
    if (blueprintGraph) {
      const { x = 0, y = 0, zoom = 1.5 } = blueprintGraph?.viewport
      setNodes(
        blueprintGraph.nodes.map((node) => {
          return {
            ...node,
            data: {
              ...node.data,
              onChange,
            },
          }
        })
      )
      setEdges(blueprintGraph.edges || [])
      setViewport({ x, y, zoom })
    }
  }, [blueprintGraph, onChange, setEdges, setNodes, setViewport])

  return (
    <>
      <LeavingBlocker active={shouldBlockLeaving} message={blockingMessage} />
      <HeadMenu
        setJobbarOpen={setJobbarOpen}
        jobbarOpen={jobbarOpen}
        setJobbarType={setJobbarType}
        onSave={onSave}
        onRestore={onRestore}
        onClear={onClear}
        nodes={nodes}
        edges={edges}
        tools={tools}
        onRedo={onRedo}
        onUndo={onUndo}
        reactFlowInstance={reactFlowInstance}
        currentHistoryIndex={currentHistoryIndex}
        history={history}
        addMessage={addMessage}
      />

      <div className="dndflow">
        <Toolbar tools={tools} />
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            style={{ background: '#fff' }}
            onNodeDrag={handleNodeDrag}
            onNodeDragStop={handleNodeDragStop}
            onNodeClick={handleNodeClick}
            onClick={() => setJobbarOpen(false)}
            isValidConnection={isValidConnection}
            defaultViewport={defaultViewport}
            panOnDrag={drag}
            onPaneClick={handlePaneClick}
            {...props}
          >
            {/* {console.log(nodes)} */}
            <Background />
            <MiniMap style={minimapStyle} zoomable pannable />
          </ReactFlow>
          <Dimmer active={isSubmittingOrRunning}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <Loader inline />
              <div>{loadingDescription}</div>
              {jobStatus === 'UPLOADING' && (
                <Progress
                  style={{ width: '30vw' }}
                  percent={uploadProgress}
                  progress
                  color="orange"
                />
              )}
              {['STARTED', 'UPLOADING'].includes(jobStatus) && (
                <Button negative disabled={isCancellingJob} onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </div>
          </Dimmer>
          <Dimmer active={isJobFailedDialogShowing}>
            <Card
              header="Failed!"
              description="Sorry, job failed."
              extra={
                <div>
                  <Button onClick={() => setIsJobFailedDialogShowing(false)}>
                    OK
                  </Button>
                </div>
              }
            />
          </Dimmer>
        </div>
        <Jobbar
          jobbarType={jobbarType}
          data={jsonData}
          nodes={nodes}
          tools={tools}
        />
      </div>
      <div className={styles.messageBoard}>
        {message.map((msg, _) => (
          <Message
            className={styles.message}
            onDismiss={() => deleteMessage(msg.id)}
            color={
              msg.type === 'success'
                ? 'green'
                : msg.type === 'error'
                ? 'red'
                : msg.type === 'warning'
                ? 'yellow'
                : msg.type === 'info'
                ? 'grey'
                : 'grey'
            }
            key={msg.id}
          >
            {msg.message}
          </Message>
        ))}
      </div>
    </>
  )
}

export default () => (
  <ReactFlowProvider>
    <FlowCanvas />
  </ReactFlowProvider>
)
