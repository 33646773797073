import _ from "lodash";

export class ConcurrentUploader {
  constructor(options) {
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.uploadDetails = options.uploadDetails;
    this.aborted = false;
    this.totalFileSize = Object.keys(options.uploadDetails).reduce(
      (size, fileName) => size + options.uploadDetails[fileName].file.size,
      0
    );
    this.timeout = 1000 * 60 * 8;
    this.singleUploadHeaders = options.singleUploadHeaders || {};
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.uploadedParts = {};
    this.onProgressFn = () => { };
    this.aborted = false;
  }

  async initialize() {
    try {
      await Promise.all(
        [...Array(this.threadsQuantity)].map((i) => this.sendNext())
      );
      if (this.aborted) return false;
      return this.uploadedParts;
    } catch (error) {
      if (this.aborted) return false;
      throw error;
    }
  }

  async sendNext(retry = 0) {
    let mypart;
    if (this.aborted) {
      return;
    }
    // find idle part
    for (let [fileName, detail] of Object.entries(this.uploadDetails)) {
      if (detail.type === "single") {
        if (!detail.started) {
          detail.started = true;
          mypart = detail;
          break;
        }
      } else if (detail.type === "multipart") {
        if (!detail.urls) {
          throw new Error(`urls is missing for file[${fileName}]`);
        }
        for (const urlPart of detail.urls) {
          if (!urlPart.started) {
            urlPart.started = true;
            mypart = {
              ...detail,
              part: urlPart,
            };
            break;
          }
        }
        if (mypart) {
          break;
        }
      } else {
        throw new Error(
          `unrecognized type of upload in file[${fileName}]: ${JSON.stringify(
            detail
          )}`
        );
      }
    }
    // return when all part are being handled
    if (!mypart) {
      return;
    }
    // upload part
    try {
      if (mypart.type === "single") {
        const status = await this.singleUpload(mypart);
        if (status !== 200) {
          throw new Error("Failed upload");
        }
      } else if (mypart.type === "multipart") {
        const status = await this.multipartUpload(mypart);
        if (status !== 200) {
          throw new Error("Failed upload");
        }
      } else {
        throw new TypeError(`unrecognized part type: ${mypart}`);
      }
    } catch (error) {
      if (this.aborted) {
        return;
      }
      if (error instanceof TypeError) {
        throw error;
      }
      if (retry <= 6) {
        retry++;
        const wait = (ms) => new Promise((res) => setTimeout(res, ms));
        //exponential backoff retry before giving up
        console.warn(
          `File ${mypart.file.name} #${mypart.part?.partNumber
          } failed to upload, backing off ${2 ** retry * 100
          } before retrying...`
        );
        await wait(2 ** retry * 100);
        mypart.part.started = undefined;
        await this.sendNext(retry);
        return;
      } else {
        console.error(
          `File ${mypart.file.name} #${mypart.part?.partNumber} failed to upload, giving up`
        );
        throw error;
      }
    }
    // send next part
    await this.sendNext();
  }

  singleUpload(detail) {
    const { file, url } = detail;
    const fileName = file.name;
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, fileName, abortFx) => {
        delete this.activeConnections[fileName];
        window.removeEventListener("offline", abortFx);
        reject(error);
      };
      if (!window.navigator.onLine) reject(new Error("System is offline"));

      const xhr = (this.activeConnections[fileName] = new XMLHttpRequest());
      xhr.timeout = this.timeout;

      const progressListener = this.handleProgress.bind(this, detail);

      xhr.upload.addEventListener("progress", progressListener);

      xhr.addEventListener("error", progressListener);
      xhr.addEventListener("abort", progressListener);
      xhr.addEventListener("loadend", progressListener);

      xhr.open("PUT", url);
      xhr.setRequestHeader("Content-Type", file.type);
      for (const [headerName, headerValue] of Object.entries(
        this.singleUploadHeaders
      )) {
        xhr.setRequestHeader(headerName, headerValue);
      }
      const abortXHR = () => xhr.abort();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(xhr.status);
          delete this.activeConnections[fileName];
          window.removeEventListener("offline", abortXHR);
        }
      };

      xhr.onerror = (error) => {
        throwXHRError(error, fileName, abortXHR);
      };
      xhr.ontimeout = (error) => {
        throwXHRError(error, fileName, abortXHR);
      };
      xhr.onabort = () => {
        throwXHRError(new Error("Upload canceled by user or system"), fileName);
      };
      window.addEventListener("offline", abortXHR);
      xhr.send(file);
    });
  }

  multipartUpload(partDetail) {
    const { file, part, chunkSizeInByte } = partDetail;
    const sentStartPosition = (part.partNumber - 1) * chunkSizeInByte;
    const chunk = file.slice(
      sentStartPosition,
      sentStartPosition + chunkSizeInByte
    );
    const fileName = file.name;
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, part, abortFx) => {
        delete this.activeConnections[fileName][part.partNumber - 1];
        window.removeEventListener("offline", abortFx);
        reject(error);
      };
      if (!window.navigator.onLine) reject(new Error("System is offline"));
      if (this.activeConnections[fileName] === undefined) {
        this.activeConnections[fileName] = {};
      }
      const xhr = (this.activeConnections[fileName][part.partNumber - 1] =
        new XMLHttpRequest());
      xhr.timeout = this.timeout;

      const progressListener = this.handleProgress.bind(this, partDetail);

      xhr.upload.addEventListener("progress", progressListener);

      xhr.addEventListener("error", progressListener);
      xhr.addEventListener("abort", progressListener);
      xhr.addEventListener("loadend", progressListener);

      xhr.open("PUT", part.url);
      const abortXHR = () => xhr.abort();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const ETag = xhr.getResponseHeader("ETag");
          if (ETag) {
            if (this.uploadedParts[fileName] === undefined) {
              this.uploadedParts[fileName] = [];
            }
            const myPart = {
              PartNumber: part.partNumber,
              ETag: ETag.replaceAll('"', ""),
            };
            // console.log(myPart);
            this.uploadedParts[fileName].push(myPart);
            resolve(xhr.status);
            delete this.activeConnections[fileName][part.partNumber - 1];
            window.removeEventListener("offline", abortXHR);
          }
        }
      };

      xhr.onerror = (error) => {
        throwXHRError(error, part, abortXHR);
      };
      xhr.ontimeout = (error) => {
        throwXHRError(error, part, abortXHR);
      };
      xhr.onabort = () => {
        throwXHRError(
          new Error("Upload canceled by user or system"),
          part,
          abortXHR
        );
      };
      window.addEventListener("offline", abortXHR);
      xhr.send(chunk);
    });
  }

  handleProgress(detail, event) {
    if (!["single", "multipart"].includes(detail.type)) {
      console.warn(
        "unrecognized detail type in handleProgress:" + JSON.stringify(detail)
      );
      return;
    }

    if (
      event.type === "progress" ||
      event.type === "error" ||
      event.type === "abort"
    ) {
      if (detail.type === "multipart") {
        if (this.progressCache[detail.file.name] === undefined) {
          this.progressCache[detail.file.name] = {};
        }
        this.progressCache[detail.file.name][detail.part.partNumber - 1] =
          event.loaded;
      } else if (detail.type === "single") {
        this.progressCache[detail.file.name] = event.loaded;
      }
    }

    if (event.type === "uploaded") {
      if (detail.type === "multipart") {
        this.uploadedSize +=
          this.progressCache[detail.file.name][detail.part.partNumber];
        delete this.progressCache[detail.file.name][detail.part.partNumber];
      } else if (detail.type === "single") {
        this.uploadedSize += this.progressCache[detail.file.name];
        delete this.progressCache[detail.file.name];
      }
    }

    const inProgress = Object.keys(this.progressCache).reduce((memo, id) => {
      const cachedSize = this.progressCache[id];
      if (typeof cachedSize === "object") {
        return (
          memo + _.sum(Object.keys(cachedSize).map((key) => cachedSize[key]))
        );
      } else if (typeof cachedSize === "number") {
        return memo + cachedSize;
      } else {
        throw new Error(`unrecognized progressCache at [${id}]: ${cachedSize}`);
      }
    }, 0);

    const sent = Math.min(this.uploadedSize + inProgress, this.totalFileSize);

    const percentage = Math.round((sent / this.totalFileSize) * 100);

    this.onProgressFn({
      sent: sent,
      total: this.totalFileSize,
      percentage: percentage,
    });
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  abort() {
    this.aborted = true;
    Object.keys(this.activeConnections).forEach((key) => {
      const connections = this.activeConnections[key];
      if (connections instanceof XMLHttpRequest) {
        // single connection
        connections.abort();
      } else if (typeof connections === "object") {
        // multipart connections
        Object.keys(connections).forEach((key) => {
          const connection = connections[key];
          if (connection instanceof XMLHttpRequest) {
            connection.abort();
          }
        });
      }
    });
  }
}
