import React, { useMemo } from "react";
import {
  Button,
  Icon,
  DropdownMenu,
  DropdownItem,
  DropdownHeader,
  DropdownDivider,
  Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useJobStore } from "../../store/jobStore";
import createWorkflowJson from "../../transformers/convertNodesToWorkflow";
import { useNodes, useEdges } from "reactflow";
import { useDatasetStore } from "../../store/datasetStore";
import { topologicalSortDFS } from "../../utils/topologicalSortDFS";
import BlueprintCreateDialog from "./BlueprintCreateDialog";

export default function HeadMenu(props) {
  const submitJob = useJobStore((state) => state.submitJob);
  const isSubmittingJob = useJobStore((state) => state.isSubmittingJob);
  const nodes = useNodes();
  const edges = useEdges();
  const datasets = useDatasetStore((state) => state.datasets);
  const jobStatus = useJobStore((state) => state.jobStatus);
  const isSubmittingOrRunning = useMemo(() => {
    return (
      ["UPLOADING", "UPLOADED", "STARTED"].includes(jobStatus) ||
      isSubmittingJob
    );
  }, [jobStatus, isSubmittingJob]);
  const isNodeValid = () => {
    const handleInvalidInput = (nodeLabel, paramDisplayName) => {
      console.error(`Incomplete input in prcoess '${nodeLabel}': ${paramDisplayName}`);
      alert(`Failed! Please compelete your inputs in process '${nodeLabel}': ${paramDisplayName}`);
    }
    if (nodes.length === 0) {
      alert("Failed! Please add at least one process.");
      return false
    }
    for (const nd of nodes) {
      const inputData = nd.data.inputData;
      const label = nd.data.label;
      if (!inputData) {
        handleInvalidInput(label);
        return false
      }
      for (let i = 0; i < nd.data.displayName.length; i++) {
        const paramDisplayName = nd.data.displayName[i]
        const paramDataType = nd.data.paramsDataType[paramDisplayName]
        const processDataType = nd.data.selectedDataType
        const isRequired = nd.data.validation?.[paramDisplayName]?.required
        if ((inputData[paramDisplayName] === undefined && isRequired) &&
          (paramDataType === processDataType || paramDataType === "NA")
        ) {
          handleInvalidInput(label, paramDisplayName);
          return false
        }
      }
    }
    return true
  };
  const jobName = useJobStore((state) => state.jobName);
  const handleSubmit = () => {
    const getUploads = (nodes) => {
      const uploads = []
      nodes.forEach((nd) => {
        if (nd.data?.inputData) {
          for (const paramKey in nd.data.inputData) {
            if (nd.data?.inputData[paramKey]?.type === "workflowInput") {
              uploads.push(nd.data.inputData[paramKey].value)
            }
          }
        }
      })
      return uploads;
    }
    const validationResult = isNodeValid()
    const sortedNodes = topologicalSortDFS(nodes, edges)
    if (validationResult) {
      const workflowJSON = createWorkflowJson(
        props.tools,
        sortedNodes,
        edges,
        "user01",
        datasets,
        jobName
      );
      const uploads = getUploads(nodes)
      props.setJobbarOpen(true);
      props.setJobbarType("Submit");
      try {
        submitJob(workflowJSON, uploads);
      } catch (error) {
        alert("Unexpected Error.")
      }
    }
  };

  return (
    <div className="head-menu">
      <div className="head-left">
        <Link to="/">
          <Icon name="angle left" style={{ marginTop: "10px" }} />
          {/* <img alt="logo" src={logo} /> */}
          <p
            style={{ marginTop: "10px", marginLeft: "10px", fontWeight: "600" }}
          >
            Back
          </p>
        </Link>
      </div>
      <div className="head-right">
        <Button
          onClick={props.onUndo}
          disabled={props.currentHistoryIndex === 0}
          color="grey"
        >
          <Icon name="undo" />
          Undo
        </Button>
        <Button
          onClick={props.onRedo}
          disabled={props.currentHistoryIndex === props.history.length - 1}
          color="grey"
        >
          <Icon name="redo" />
          Redo
        </Button>
        <Button
          disabled={isSubmittingOrRunning}
          color="black"
          icon
          labelPosition="left"
          onClick={handleSubmit}
        >
          <Icon name="play" />
          Run
        </Button>
        <Button color="grey" icon labelPosition="left" onClick={props.onClear}>
          <Icon name="trash alternate" />
          Clear
        </Button>
        <Button
          color="grey"
          icon
          labelPosition="left"
          onClick={props.onRestore}
        >
          <Icon name="redo" />
          Restore
        </Button>
        <Button color="grey" icon labelPosition="left" onClick={props.onSave}>
          Save
          <Icon name="save" />
        </Button>
        <BlueprintCreateDialog
          reactFlowInstance={props.reactFlowInstance}
          onSuccess={() => {
            props.addMessage("success", "Blueprint created successfully")
          }}
        >
          <Button color="grey" icon labelPosition="left">
            Save as Blueprint
            <Icon name="save" />
          </Button>
        </BlueprintCreateDialog>
        {/* <Dropdown
          text='Blueprint'
          color="grey"
          icon='zip'
          floating
          labeled
          button
          className='icon'
        >
          <DropdownMenu>
            <DropdownItem onClick={() => handleUseBlueprint()}>Use blueprint</DropdownItem>
            <DropdownItem onClick={() => handleCreateBlueprint()}>Create blueprint</DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
      </div>
    </div>
  );
}
