import React, { useState, useEffect } from 'react'

import igv from 'igv'

import {
  Dimmer,
  Segment,
  Header,
  Placeholder,
  PlaceholderLine,
  Button,
} from 'semantic-ui-react'

import styles from '../../dashboard/JobDetailsPanel.module.css'
import useIgvStore from '../../../store/igvStore.js'
import './styles/igv.css'

export default function IgvVis({ props, onExit, setIgvParams }) {
  const { user, jobId, processId, workflowName } = props || {}

  const fetchDataset = useIgvStore((state) => state.fetchDataset)
  const urlsJson = useIgvStore((state) => state.urlsJson)
  const [isLoading, setIsLoading] = useState(true)
  const [canvasChanges, setCanvasChanges] = useState(0)

  useEffect(() => {
    if (user && jobId && processId) {
      setIsLoading(true)
      fetchDataset(user, jobId, processId)
    }
  }, [user, jobId, processId, fetchDataset])

  useEffect(() => {
    if (props) {
      if (
        urlsJson &&
        urlsJson.bam &&
        urlsJson.bai &&
        urlsJson.fasta &&
        urlsJson.fai
      ) {
        const igvOptions = {
          reference: {
            fastaURL: urlsJson.fasta,
            indexURL: urlsJson.fai,
          },
          tracks: [
            {
              height: 450,
              url: urlsJson.bam,
              indexURL: urlsJson.bai,
              format: 'bam',
            },
          ],
        }
        const div = document.getElementById('igv_div')
        div.style.cursor = 'grab'
        if (div) {
          igv
            .createBrowser(div, igvOptions)
            .then(() => {
              setIsLoading(false)
              setCanvasChanges((prev) => prev + 1)
            })
            .catch((error) => {
              console.error('IGV creation failed:', error)
            })
        }
      }
    }
  }, [urlsJson])

  useEffect(() => {
    if (props) {
      const intervalId = setInterval(() => {
        //   let scrollableCanvasCount = 0
        const nonScrollableCanvasNum = 2
        // Make the second to last IGV views become scrollable
        const igvCols = document.getElementsByClassName('igv-column')

        function simulateMouseDrag(element, xChanges) {
          let x = 1000
          // Simulate mousedown
          element.dispatchEvent(
            new MouseEvent('mousedown', {
              view: window,
              bubbles: true,
              cancelable: true,
              buttons: 1,
              clientX: 1000, // Starting X position
            })
          )

          // Simulate mousemove (dragging)
          element.dispatchEvent(
            new MouseEvent('mousemove', {
              view: window,
              bubbles: true,
              cancelable: true,
              buttons: 1,
              clientX: x + xChanges, // Drag to the left/right
            })
          )

          // Simulate mouseup
          element.dispatchEvent(
            new MouseEvent('mouseup', {
              view: window,
              bubbles: true,
              cancelable: true,
              buttons: 1,
            })
          )
        }

        if (igvCols) {
          Array.from(igvCols).forEach((col) => {
            const scrollableDivs = col.getElementsByClassName('igv-viewport')
            if (scrollableDivs.length > 0) {
              const handleWheel = (event, canvas) => {
                event.preventDefault()
                const oriLeftValue = canvas.style.left
                const numericValue = parseInt(oriLeftValue, 10) // Converts '-100px' to -100
                if (event.deltaY > 0) {
                  canvas.style.left = `${numericValue + 100}px`
                  simulateMouseDrag(canvas, 40)
                } else if (event.deltaY < 0) {
                  canvas.style.left = `${numericValue - 100}px`
                  simulateMouseDrag(canvas, -40)
                }
              }
              // Check if there have all the scrollable views are loaded
              // Array.from(scrollableDivs).forEach((div, index) => {
              //   if (index > nonScrollableCanvasNum - 1) {
              //     // Skip the first two containers
              //     const canvas = div.getElementsByTagName('canvas')[0]
              //     if (canvas) {
              //       scrollableCanvasCount++
              //     }
              //   }
              // })
              // if (
              //   scrollableCanvasCount >=
              //   scrollableDivs.length - nonScrollableCanvasNum
              // ) {
              // Assumes that all scrollable canvases are loaded
              Array.from(scrollableDivs).forEach((div, index) => {
                if (index > nonScrollableCanvasNum - 1) {
                  // Skip the first two containers
                  const canvas = div.getElementsByTagName('canvas')[0]
                  if (canvas) {
                    div.removeEventListener('wheel', (event) =>
                      handleWheel(event, canvas)
                    )
                    div.addEventListener('wheel', (event) =>
                      handleWheel(event, canvas)
                    )
                    canvas.title = 'Scrollable'
                  }
                }
              })
              //   clearInterval(intervalId) // Stop checking once listerners are added
              // }
            }
          })
        }
      }, 1000)
    }
  }, [canvasChanges])

  return (
    <Dimmer
      active={!!user && !!jobId && !!processId && !!workflowName}
      page
      onClickOutside={onExit}
    >
      <Segment textAlign="left" style={{ height: '10vh' }}>
        <Header as="h1">IGV - {workflowName || ''}</Header>
        <Header as="h6">
          The IGV is active for only 7 days. Feel free to open a new one at any
          time. Certain tracks should be scrollable using the mouse.
        </Header>
      </Segment>
      <Segment
        className={styles.panel}
        textAlign="left"
        style={{ height: '70vh' }}
      >
        <div>
          <div className="mobile-igv-container">
            The IGV currently not supported to display on mobile mode.
          </div>
          <div
            id="igv_div"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            className="igv-container"
          ></div>
          {isLoading && (
            <Placeholder>
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
              <PlaceholderLine />
            </Placeholder>
          )}
        </div>
      </Segment>
      <Segment textAlign="right" style={{ height: '10vh' }}>
        <Button onClick={() => setIgvParams(null)}>Cancel</Button>
      </Segment>
    </Dimmer>
  )
}
