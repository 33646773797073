export const toJobStatusDisplayName = (jobStatus) => {
  switch (jobStatus) {
    case "STARTED":
      return "Running"
    case "UPLOADING":
    case "UPLOADED":
      return "Starting"
    case "FAILED":
      return "Failed"
    case "CANCELED":
      return "Canceled"
    case "SUCCEED":
      return "Completed"
    default:
      return ""
  }
}