import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Papa from 'papaparse'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export default function StickyHeadTable() {
  const columns = [
    { id: 'qseqid', label: 'qseqid', minWidth: 170 },
    {
      id: 'sseqid',
      label: 'sseqid',
      minWidth: 100,
      render: (data, index) => {
        //   console.log(sseqidIndex[index])
        return (
          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                sseqid
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sseqidIndex[index]}
                onChange={(e) => handleChange(e, index)}
                label="sseqid"
              >
                {data.map((d, index) => (
                  <MenuItem value={index}>{d}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      id: 'pident',
      label: 'pident',
      minWidth: 170,
    },
    {
      id: 'length',
      label: 'length',
      minWidth: 170,
    },
    {
      id: 'mismatch',
      label: 'mismatch',
      minWidth: 170,
    },
    {
      id: 'gapopen',
      label: 'gapopen',
      minWidth: 170,
    },
    {
      id: 'qstart',
      label: 'qstart',
      minWidth: 170,
    },
    {
      id: 'qend',
      label: 'qend',
      minWidth: 170,
    },
    {
      id: 'sstart',
      label: 'sstart',
      minWidth: 170,
    },
    {
      id: 'send',
      label: 'send',
      minWidth: 170,
    },
    {
      id: 'evalue',
      label: 'evalue',
      minWidth: 170,
    },
    {
      id: 'bitscore',
      label: 'bitscore',
      minWidth: 170,
    },
  ]

  //     const rows = [
  //       {
  //         qseqid: 'k141_5852',
  //         sseqid: ['YP_009505617.1', 'NP_740733.1'],
  //         pident: [93.8, 87.5],
  //         length: [48, 48],
  //         mismatch: [3, 6],
  //         gapopen: [0, 0],
  //         qstart: [340, 340],
  //         qend: [197, 197],
  //         sstart: [1206, 161],
  //         send: [1253, 208],
  //         evalue: ['2.53e-26', '2.90e-25'],
  //         bitscore: [101, 96.7],
  //       },
  //     ]
  const keys = [
    'qseqid',
    'sseqid',
    'pident',
    'length',
    'mismatch',
    'gapopen',
    'qstart',
    'qend',
    'sstart',
    'send',
    'evalue',
    'bitscore',
  ]
  const [sseqidIndex, setSseqidIndex] = React.useState([])

  const handleChange = (e, index) => {
    const newSseqidIndex = sseqidIndex.map((c, i) => {
      if (i === index) {
        return e.target.value
      } else {
        return c
      }
    })
    setSseqidIndex(newSseqidIndex)
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rows, setRows] = React.useState([])
  const [open, setOpen] = React.useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const [dataFile, setDataFile] = React.useState('')
  const handleFileChange = (event) => {
    setDataFile(event.target.files[0])
  }
  function createDefaultSseqidIndex(rows) {
    let defaultSseqidIndex = []
    console.log(rows.length)
    for (let i = 0; i < rows.length; i++) {
      defaultSseqidIndex.push(0)
    }
    return defaultSseqidIndex
  }

  React.useEffect(() => {
    if (dataFile) {
      Papa.parse(dataFile, {
        complete: function (results) {
          console.log('Finished:', results.data)
          setRows(parseData(results.data))
          setSseqidIndex(createDefaultSseqidIndex(parseData(results.data)))
        },
      })
    }
  }, [dataFile])

  //   console.log(sseqidIndex)
  console.log(rows)
  function parseData(sourceData) {
    const map = new Map()

    sourceData.forEach((subArray) => {
      const key = subArray[0]

      if (!map.has(key)) {
        map.set(
          key,
          Array.from({ length: subArray.length }, () => [])
        )
      }

      subArray.forEach((value, index) => {
        map.get(key)[index].push(value)
      })
    })
    const keys = [
      'qseqid',
      'sseqid',
      'pident',
      'length',
      'mismatch',
      'gapopen',
      'qstart',
      'qend',
      'sstart',
      'send',
      'evalue',
      'bitscore',
    ]

    const result = Array.from(map.entries()).map(([key, arrays]) => {
      const obj = {}
      arrays.forEach((array, index) => {
        obj[keys[index]] = array
      })
      return obj
    })

    return result
  }

  React.useEffect(() => {
    setRows(rows)
  }, [sseqidIndex])
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {/* Dev using */}
      <input type="file" onChange={handleFileChange} />
      {/* End */}
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id]
                      const sIndex = sseqidIndex[index]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.render
                            ? column.render(row.sseqid, index)
                            : value[sIndex]}
                        </TableCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
            {/* {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {row[keys[0]].map((k, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code + index}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          {row[column.id][index]}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </React.Fragment>
              ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
