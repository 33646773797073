import { create } from "zustand";
import { myAxios } from "../utils/axios";
// import { mountStoreDevtool } from "simple-zustand-devtools";
import { postRequestDownloadProcessOutputsURL } from "../constant/url";

const useTreeStore = create((set) => ({
  dataset: null, // Initialize dataset with a default value of null
  fetchDataset: async (user, jobId, processId, outputId) => {
    console.log(jobId, processId, outputId);
    try {
      // Make a POST request to fetch the URL
      const response = await myAxios.post(
        postRequestDownloadProcessOutputsURL(user, jobId, processId, outputId)
      );

      // Extract the URL from the response
      const fileUrl = response.data.url;

      if (fileUrl) {
        // Fetch the file object from the URL
        const fileResponse = await myAxios.get(fileUrl, {
          responseType: "blob",
        }); // or 'arraybuffer' depending on your needs
        // Convert the blob data to a string
        const textData = await fileResponse.data.text();
        // Save the fetched String to the dataset
        set({ dataset: textData });
        // console.log(useTreeStore.getState().dataset); // Access dataset through useTreeStore.getState()
      } else {
        console.error("No URL found in the response.");
      }
    } catch (error) {
      console.error("Failed to fetch dataset data: " + error);
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool("Store", useTreeStore);
}

export default useTreeStore;
