import React, { Component } from "react";
import RBButton from "react-bootstrap/Button";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
import { phylotree } from "phylotree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faSortAmountUp,
  faAlignRight,
  faAlignLeft,
} from "@fortawesome/free-solid-svg-icons";
import { text } from "d3-fetch";

import Phylotree from "./phylotree.jsx";

import "./styles/phylotree.css";
import compress from "./icons/compress.svg";
import expand from "./icons/expand.svg"

function Button(props) {
  return (
    <RBButton
      variant="secondary"
      {...props}
      style={{
        // display: "flex"
        backgroundColor: "#000",
        color: "white",
        width: "48px",
        height: "24px",
        borderRadius: "100px",
        marginRight: "6px",
      }}
    >
      {props.children}
    </RBButton>
  );
}

function HorizontalExpansionButton(props) {
  return (
    <Button style={{ fontSize: 10 }} title="Expand horizontally" {...props}>
      <FontAwesomeIcon key={1} icon={faArrowLeft} />
      <FontAwesomeIcon key={2} icon={faArrowRight} />
    </Button>
  );
}

function HorizontalCompressionButton(props) {
  return (
    <Button style={{ fontSize: 10 }} title="Compress horizontally" {...props}>
      <FontAwesomeIcon key={1} icon={faArrowRight} />
      <FontAwesomeIcon key={2} icon={faArrowLeft} />
    </Button>
  );
}

function VerticalExpansionButton(props) {
  return (
    <Button
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        fontSize: 10,
        display: "grid",
        backgroundColor: "#000",
        borderRadius: "100px 0 0 100px",
        borderRight: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
      title="Expand vertically"
      {...props}
    >
      <img src={expand} style={{
        width: "30%",
        marginTop:"2px"}}/>
    </Button>
  );
}

function VerticalCompressionButton(props) {
  return (
    <Button
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        fontSize: 10,
        display: "block",
        backgroundColor: "#000",
        borderLeft: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
      title="Compress vertically"
      {...props}
    >
      <img src={compress} style={{
        width: "30%",
        marginTop:"2px"}}/>
    </Button>
  );
}

function AscendingSortButton(props) {
  return (
    <Button
      title="Sort in ascending order"
      {...props}
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        backgroundColor: "#000",
        borderLeft: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
    >
      <FontAwesomeIcon key={1} icon={faSortAmountUp} flip="vertical" />
    </Button>
  );
}

function DescendingSortButton(props) {
  return (
    <Button
      title="Sort in descending order"
      {...props}
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        backgroundColor: "#000",
        borderLeft: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
    >
      <FontAwesomeIcon key={1} icon={faSortAmountUp} />
    </Button>
  );
}

function AlignTipsRightButton(props) {
  return (
    <Button
      title="Align tips to right"
      {...props}
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        backgroundColor: "#000",
        borderRadius: "0 100px 100px 0",
        borderLeft: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
    >
      <FontAwesomeIcon key={1} icon={faAlignRight} />
    </Button>
  );
}

function AlignTipsLeftButton(props) {
  return (
    <Button
      title="Align tips to left"
      {...props}
      style={{
        color: "white",
        width: "40px",
        height: "24px",
        backgroundColor: "#000",
        borderLeft: "1px white solid",
        boxShadow: "0 2px 12px rgba(0,0,0,0.2)",
      }}
    >
      <FontAwesomeIcon key={1} icon={faAlignLeft} />
    </Button>
  );
}

function randomSort(a, b) {
  // Generate a random number between -1 and 1
  let rand = Math.random() * 2 - 1;
  // Return 1 if the random number is greater than 0, -1 otherwise
  return rand > 0 ? 1 : -1;
}

function ColourMapping() {
  let mappedColour={}
  let colour = ['#7adcdc', '#8cd0d0', '#9ec4c4', '#b0b8b8', '#c3abab', '#d59f9f', '#e79393', '#e4958e', '#d69f8d', '#c9a88b', '#bbb18a', '#aeba88', '#a0c487', '#98c88a', '#9ac297', '#9dbca4', '#9fb7b1', '#a1b1bd', '#a4abca', '#a6a5d7', '#a9a1d6', '#ab9cd4', '#ae98d3', '#b093d1', '#b38fd0', '#b58ace', '#b18fd0', '#ab98d3', '#a4a1d6', '#9daad8', '#96b3db', '#90bcde', '#90c1df', '#9dbfdc', '#abbcda', '#b9bad7', '#c7b8d4', '#d4b5d2', '#e2b3cf', '#e3b2c1', '#e5b1b3', '#e6b0a4', '#e7af96', '#e8ae88', '#eaad7a', '#ebb077', '#ecb579', '#edba7b', '#edc07e', '#eec580', '#efca82', '#edc986', '#e5bc8a', '#dcb08f', '#d4a394', '#cb9799', '#c38a9d', '#ba7ea2']
  // let colour=['#7270BF', '#B689CE', '#9b68b6', '#7f489d', '#642785', '#f0cd84', '#ebc576', '#e5bd68', '#e0b45a', '#daac4c', '#d5a43e', '#cf9c30', '#ca9322', '#c48b14', '#bf8306', '#97ca86', '#93c682', '#8fc37e', '#8bbf7a', '#87bc75', '#83b871', '#7fb56d', '#7bb169', '#77ad65', '#73aa61', '#6fa65c', '#6ba358', '#679f54', '#639b50', '#5f984c', '#5b9448', '#579143', '#538d3f', '#4f8a3b', '#4b8637', '#478233', '#437f2f', '#3f7b2a', '#3b7826', '#377422', '#33701e', '#2f6d1a', '#2b6916', '#276611', '#23620d', '#1f5f09', '#1b5b05', '#8BC2E0', '#EAAD75', '#7adcdc', '#51baba', '#299797', '#007575', '#ED8F8F', '#c04848', '#920000', '#E2B3CF', '#8B2A64']
  let tag = ['2.1.3.2a', '2.2.2', '2.3', '2.2.1.1a', '2.1.3.1', '7.2', '2.3.4.4e', '2.3.2.1b', '2.3.2.1c', '2.3.4.4h', '2.3.2.1a', '8', '0', '2.1.3.2', '2.4', '7.1', '2.3.2.1f', '5', '2.2.2.1', '2.3.4.3', '2.3.4.4d', '1', '2.1.3.2b', '2.3.4.4c', '3', '2.1.3.3', '2.3.4.2', '2.3.4.4a', '9', '2.1.1', '2.3.2.1', '7', '1.1.1', '2.3.3', '1.1.2', '1.1', '2.3.2.1e', '6', '2.3.4.4b', '4', '2.3.4.1', '2.1.3', '2.3.2', '2.3.4.4f', '2.5', '2.2', '2.3.1', '2.1', '2.3.2.1d', '2.3.2.1g', '2.2.1', '2.3.4.4', '2.2.1.1', '2.2.1.2', '2', '2.3.4.4g', '2.3.4', '2.1.2']
  let tag_sars2=["B.1.36.1","C.3","BQ.1.3.2","BB.1","BA.2.12.1","B.10","BQ.1.1.15","B.55","B.20","A.23.1","BA.3.1","XBB.1.5.13","AY.9.2","AY.10","BA.4.1","BA.2.9.5","BA.5.1.1","B.1.351","P.1","B.1.177.44","BL.1","CH.1.1.11","B.1.177.16","B.1.1.519","BQ.1.1","XBB.1.9.1","B.1.351.3","BA.4.6.5","BA.5.2","EG.2","B.1.525","B.1.2","B.31","B.1.1.1","C.36.3.1","P.1.15","B.1.621","B.1.623","B.1.1.291","AY.47","B.1.1.7","B.1.617.2","AY.54","B.1.258","B.1.1.222","AY.4","XW","AY.26","BA.2","AY.100","BF.8","AZ.2","B.1.1.317","B.1.526","B.1.617.1","A.27","BA.1.17.2","AY.25.1","C.16","BA.4.7","XB","B.1.111","B.1.427","AY.44","B.1.177","B.1.177.57","XG","BA.1.1","AY.43","BQ.1.1.22","AY.9","BA.1","XBB.1","B.1.1.432","A.1","C.30","B.1.575","BA.5.1.22","XBB.1.16","XY","FL.2","BR.1.2","BA.1.1.2","BA.4.6","B.1.637","B.1.429","B.1.617","XBB.1.9.2","P.3","XN","B","B.1.375","BQ.1.1.28","BA.5.1","A.3","BN.6","D.2","B.1","AY.75","XBB.2.1","XBB.1.5","CH.1.1","C.37"]
  // tag = tag_sars2.sort(randomSort);
  // console.log(tag)
  for(let i=0; i<161; i++){
      if(i<59){
      mappedColour[tag[i]]=colour[i]
      mappedColour[tag_sars2[i]]=colour[i]}
      else{
      mappedColour[tag[i]]=colour[i-59]
}  }
  mappedColour.Test = "black"
  mappedColour.innode = "black"
  return mappedColour
}
// console.log(mappedColour)
class PhylotreeApplication extends Component {
  constructor(props) {super(props);
    this.state = {
      tree: null,
      alignTips: "right",
      sort: null,
      internal: false,
      clickedBranch: null,
    };
  }

  componentDidMount() {
    text("data/CD2.new").then(() => {
      this.setState({ newick: this.props.newickData });
      this.setState({ newickLength: this.props.newickData.split(":").length - 1 });
    });
    this.setState({ dimension: 0 });
  }
  toggleDimension(dimension, direction) {
    const new_dimension =
      this.state.dimension + (direction == "expand" ? 20 : -20);
    console.log(new_dimension);
    this.setState({ dimension: new_dimension });
  }
  handleSort(direction) {
    this.setState({ sort: direction });
  }
  alignTips(direction) {
    this.setState({ alignTips: direction });
  }

  render() {
    const { padding, width } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{
            display:"flex"
          }}>
            {/* <HorizontalExpansionButton
              onClick={() => this.toggleDimension("width", "expand")}
            />
            <HorizontalCompressionButton
              onClick={() => this.toggleDimension("width", "compress")}
            /> */}
            <VerticalExpansionButton
              onClick={() => this.toggleDimension("height", "expand")}
            />
            <VerticalCompressionButton
              onClick={() => this.toggleDimension("height", "compress")}
            />
            <AscendingSortButton onClick={() => this.handleSort("ascending")} />
            <DescendingSortButton
              onClick={() => this.handleSort("descending")}
            />
            <AlignTipsLeftButton onClick={() => this.alignTips("left")} />
            <AlignTipsRightButton onClick={() => this.alignTips("right")} />
            </div>
          
          <div
            style={{
              display: "flex",
              minWidth: "160px",
              marginTop: "10px",
            }}
          >
            <input
              type="checkbox"
              onChange={() => this.setState({ internal: !this.state.internal })}
            />
            <p
              style={{
                marginBottom: "0",
                lineHeight: "12px",
                marginLeft: "10px",
                color: "#000",
              }}
            >
              {this.state.internal ? "Hide" : "Show"} internal labels
            </p>
          </div>
        </div>
        <svg width={width * 0.67} height={this.state.newickLength * 16+ this.state.dimension+2 * padding}>
          <Phylotree
            width={width * 0.67 - 2 * padding}
            height={
              this.state.newickLength * 16 - 2 * padding + this.state.dimension
            }
            // height={this.state.dimension}
            transform={`translate(${padding}, ${padding})`}
            newick={this.state.newick}
            queries={this.props.queries}
            alignTips={this.state.alignTips}
            sort={this.state.sort}
            internalNodeLabels={this.state.internal}
            // onBranchClick={(branch) => {
            maxLabelWidth={200}
            //   this.setState({ clickedBranch: branch.target.data.name });
            // }}
            includeBLAxis
            highlightBranches={ColourMapping()}
          />
        </svg>
      </div>
    );
  }
}
PhylotreeApplication.defaultProps = {
  padding: 10,
};

export default PhylotreeApplication;
