import { create } from "zustand";
import { myAxios } from "../utils/axios";
import { getToolsURL } from "../constant/url";
// import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useToolStore = create((set, get) => ({
  tools: [],
  fetchTools: async () => {
    if (get().tools?.length === 0)
      try {
        const response = await myAxios.get(getToolsURL);
        set({ tools: response.data });
      } catch (error) {
        console.error("failed to fetch tools data: " + error);
      }
  },
}));

if (process.env.NODE_ENV === "development") {
  // mountStoreDevtool('Store', useToolStore);
}
