import React, { useEffect, useState, useCallback } from 'react'
import { Handle, Position, NodeToolbar } from 'reactflow'
import {
  Menu,
  Form,
  Divider,
  Icon,
  Popup,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Checkbox,
  Dropdown,
  FormSelect,
} from 'semantic-ui-react'
import FileInput from './FileInput'
import { useDatasetStore } from '../../store/datasetStore'
import { useToolStore } from '../../store/toolStore'
import useNodesAndEdgesStore from '../store/nodesAndEdgesStore'
import '../../styles/node.css'
import { colorGenerator } from '../utilities/colorMapping'
// import categoryColors from "../../styles/colors.js";
// import { useCategories } from '../../styles/colorUtils'

const CPU_CHOICE = [8, 16, 32, 48, 64, 96, 128, 192]
const RAM_MULTIPLIER_CHOICES = [2, 4, 8]

export default function Node({ data, isConnectable, id, selected }) {
  // const defaultInputData = () => {
  //   let d = {};
  //   for (let i = 0; i < data.displayName.length; i++) {
  //     d[data.displayName[i]] = data.defaultValue[i];
  //   }
  //   return d;
  // };
  const [newData, setNewData] = useState(data)
  const datasets = useDatasetStore((state) => state.datasets)
  const tools = useToolStore((state) => state.tools)
  const [isPairEnd, setPairEnd] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [nodeMenuIsVisible, setNodeMenuIsVisible] = useState(false)
  const [inputData, setInputData] = useState({})
  const [label, setLabel] = useState(data.label)
  const [hardware, setHardware] = useState({})
  const [ramMultiplier, setRamMultiplier] = useState(() => {
    const defaultHardware = tools?.find(tool => tool.toolid === data.toolName)?.defaultHardware
    if (!defaultHardware || !defaultHardware.ramInGB || !defaultHardware.cpu) return 2
    const ratio = Math.floor(defaultHardware.ramInGB / defaultHardware.cpu)
    if (!RAM_MULTIPLIER_CHOICES.includes(ratio)) {
      console.warn(`Default hardware ram to cpu ratio(${defaultHardware.ramInGB} / ${defaultHardware.cpu}) is not in the list of choices(${RAM_MULTIPLIER_CHOICES.toString()}), defaulting to 2`)
      return 2
    }
    return ratio
  })
  useEffect(() => {
    setHardware((hardware) => ({
      ...hardware,
      ramInGB: hardware.cpu * ramMultiplier,
    }))
  }, [ramMultiplier])
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    setPairEnd(data.hasSEPE && data.selectedDataType === 'PE')
    setIsVisible(data.isNodeToolBarVisible)
    setNodeMenuIsVisible(data.isNodeMenuVisible)
    setInputData(data.inputData)
    setHardware({
      cpu: data.hardware.cpu,
      ramInGB: data.hardware.ramInGB,
    })
    console.log(data?.validation)
  }, [data])

  useEffect(() => {
    setIsVisible(data.isNodeToolBarVisible)
  }, [data.isNodeToolBarVisible])
  useEffect(() => {
    setNodeMenuIsVisible(data.isNodeMenuVisible == true ? true : false)
  }, [data.isNodeMenuVisible])
  // useEffect(() => {
  //   console.log(inputData);
  // }, [inputData]);
  // useEffect(() => {
  //   console.log(isVisible);
  // }, [isVisible]);
  // Update state when data.inputData changes
  const currentDataType = isPairEnd ? 'PE' : 'SE'

  const handleToggle = () => {
    setPairEnd(!isPairEnd)
  }

  const deleteNode = useNodesAndEdgesStore((state) => state.deleteNode)

  const labelChange = (event) => {
    setLabel(event.target.value)
  }

  const onHardwareChange = (event, { id, value }) => {
    setHardware((prevState) => ({
      ...prevState,
      [id]: id === 'ramInGB' ? value * prevState.cpu : value,
    }))
  }

  const saveValidation = () => {
    // console.log(data)
    // console.log(newData)
    // console.log(data.inputData);
    // console.log(newData.inputData);
    // console.log(data.displayName);
    // console.log(data.validation)
    for (let i = 0; i < data.displayName.length; i++) {
      const paramDisplayName = data.displayName[i]
      // console.log(paramDisplayName);
      const paramDataType = data.paramsDataType[data.displayName[i]]
      // console.log(paramDataType);
      const isParamRequired =
        data.validation?.[paramDisplayName]?.required ?? false
      // console.log(newData.inputData[paramDisplayName]);
      if (
        isParamRequired &&
        (newData.inputData[paramDisplayName] === undefined ||
          newData.inputData[paramDisplayName] === '' ||
          newData.inputData[paramDisplayName] === null) &&
        (paramDataType === currentDataType || paramDataType === 'NA')
      ) {
        // console.log(paramDataType);
        // console.log(currentDataType);
        if (data.input[i] === 'file') {
          setActiveIndex(1)
        } else if (
          data.input[i] === 'string' ||
          data.input[i] === 'number' ||
          data.input[i] === 'boolean' ||
          data.input[i] === 'string[]'
        ) {
          setActiveIndex(2)
        }
        return false
      }
      if (paramDataType === currentDataType || paramDataType === 'NA') {
        if (data.input[i] === 'number') {
          if (
            data.validation?.[paramDisplayName]?.minimum !== undefined &&
            newData.inputData[paramDisplayName] <
            data.validation?.[paramDisplayName]?.minimum
          ) {
            setActiveIndex(2)
            return false
          } else if (
            data.validation?.[paramDisplayName]?.maximum !== undefined &&
            newData.inputData[paramDisplayName] >
            data.validation?.[paramDisplayName]?.maximum
          ) {
            setActiveIndex(2)
            return false
          }
        }
      }
    }
    return true
  }

  const filterNewData = (preUpdateData, selectedDataType) => {
    if (!preUpdateData || !preUpdateData.inputData) {
      console.error('Invalid data object provided')
      return
    }
    Object.keys(data.paramsDataType).forEach((key) => {
      const paramType = data.paramsDataType[key]
      if (paramType !== selectedDataType && paramType !== 'NA') {
        preUpdateData.inputData[key] = null
      }
    })
    return preUpdateData
  }
  const onParamsChange = (event, { id, value }) => {
    setInputData((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }
  const onCheckboxChange = (e, data) => {
    setInputData((prevState) => ({
      ...prevState,
      [data.id]: data.checked,
    }))
  }

  const onSave = (e) => {
    let selectedDataType = 'NA'
    if (data.hasSEPE) {
      selectedDataType = isPairEnd ? 'PE' : 'SE'
    }
    if (saveValidation()) {
      const filteredNewData = filterNewData(newData, selectedDataType)
      data.onChange(filteredNewData)
      e.stopPropagation()
      setIsVisible(false)
    }
  }

  const handleClick = (e) => {
    e.stopPropagation()
    setIsVisible(true)
    setNodeMenuIsVisible(false)
  }
  const handleClickMenu = (e) => {
    e.stopPropagation()
    setNodeMenuIsVisible(true)
  }
  const handleClickEdit = (e) => {
    setNodeMenuIsVisible(false)
  }

  useEffect(() => {
    let selectedDataType = 'NA'
    if (data.hasSEPE) {
      selectedDataType = isPairEnd ? 'PE' : 'SE'
    }
    setNewData((prevNewData) => ({
      ...prevNewData,
      label: label,
      inputData: inputData,
      hardware: hardware,
      id: id,
      selectedDataType: selectedDataType,
    }))
  }, [label, data, inputData, hardware, isPairEnd, id])

  const handleInputClick = (e, titlePorps) => {
    const { index } = titlePorps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  // const { getCategoryColors } = useCategories()
  // const colors = getCategoryColors(data.category)

  const groupToolsByCategory = (tools) => {
    const grouped = {}

    tools.forEach((tool) => {
      // console.log(tool);
      const category = tool.category
      if (!grouped[category]) {
        grouped[category] = []
      }
      grouped[category].push(tool)
    })

    return grouped
  }
  const length =
    Object.keys(groupToolsByCategory(tools)).length == 0
      ? 2
      : Object.keys(groupToolsByCategory(tools)).length

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        // onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        className="react-flow__node-default"
        style={{
          backgroundColor: `${colorGenerator(false, length)[data.index]}`,
          color: `${colorGenerator(false, length)[data.index]}`,
          // borderColor: `${colorGenerator(true, length)[data.index]}`,
          // backgroundColor: colors.background,
          // color: colors.border,
          // borderColor: colors.border,
        }}
      >
        <div className="node-content">
          <div className="node-label">{data.label}</div>
          <Popup
            content="Menu"
            size="mini"
            trigger={
              <button
                onClick={handleClickMenu}
                className="node-delete-btn"
                style={{
                  backgroundColor: `${colorGenerator(false)[data.index]}`,
                  color: `${colorGenerator(true)[data.index]}`,
                  borderColor: `${colorGenerator(true)[data.index]}`,
                  // backgroundColor: colors.background,
                  // color: colors.border,
                  // borderColor: colors.border,
                }}
              >
                <Icon name="ellipsis vertical" />
              </button>
            }
          ></Popup>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <NodeToolbar
        isVisible={nodeMenuIsVisible}
        position="bottom"
        align="end"
        className="node-delete-menu"
      >
        <button
          onClick={() => {
            deleteNode(id)
          }}
        >
          <Icon name="trash alternate" />
          delete
        </button>
        <Divider style={{ margin: '0' }} />
        <button onClick={handleClickEdit}>
          <Icon name="edit" />
          edit
        </button>
      </NodeToolbar>
      <div onClick={handleClick}>
        <NodeToolbar
          style={{
            backgroundColor: '#fff',
            width: '360px',
            boxShadow: '0 0 20px rgba(0,0,0,0.2)',
            margin: '0',
            display: 'fixed',
            top: '0',
            padding: '0',
            borderRadius: '2px',
            border: '1px solid gray',
          }}
          isVisible={isVisible}
          position="right"
        >
          <div
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            vertical
            width="wide"
            direction="right"
            style={{ backgroundColor: 'white', textAlign: 'left' }}
          >
            <div
              style={{
                background: 'black',
                color: 'white',
                padding: '10px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
              className="edit-bar-title"
            >
              <div>{data.label}</div>
              {data.hasSEPE && (
                <label className="toggle-container">
                  <input
                    type="checkbox"
                    checked={isPairEnd}
                    onChange={handleToggle}
                    className="toggle-checkbox"
                  />
                  <div className="toggle-switch">
                    <span className="toggle-text">
                      {isPairEnd ? 'PE' : 'SE'}
                    </span>
                  </div>
                </label>
              )}
            </div>

            <Form className="edit-bar-form nowheel">
              <Accordion>
                <AccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleInputClick}
                >
                  <Icon name="dropdown" />
                  Info
                </AccordionTitle>
                <AccordionContent active={activeIndex === 0}>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      label="Tool Name"
                      value={data.toolName}
                      readOnly
                    />
                    <Form.Input
                      fluid
                      label="Label"
                      value={label}
                      id="label"
                      onChange={labelChange}
                    />
                  </Form.Group>
                </AccordionContent>
              </Accordion>
              <Divider className="edit-bar-divider" />
              {data.input && data.input.length > 0 && (
                <>
                  <Accordion>
                    <AccordionTitle
                      active={activeIndex === 1}
                      index={1}
                      onClick={handleInputClick}
                    >
                      <Icon name="dropdown" />
                      Input
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === 1}>
                      <div className="accordion-content">
                        {data.displayName.map((name, i) => {
                          const currentDataType = isPairEnd ? 'PE' : 'SE'
                          if (
                            data.input[i] === 'file' &&
                            (currentDataType ===
                              data.paramsDataType[data.displayName[i]] ||
                              data.paramsDataType[data.displayName[i]] === 'NA')
                          ) {
                            // console.log(data.validation[name].required);
                            // console.log(newData.inputData[name]);
                            return (
                              <FileInput
                                key={`file-input-${i}`}
                                displayName={data.displayName[i]}
                                // data={data}
                                datasets={datasets}
                                tools={tools}
                                id={id}
                                value={inputData[data.displayName[i]]}
                                setInputData={setInputData}
                                inputData={inputData}
                                error={
                                  (data.validation[name]?.required
                                    ? newData.inputData[name] === undefined ||
                                      newData.inputData[name] === null ||
                                      newData.inputData[name] === ''
                                      ? true
                                      : false
                                    : false) ?? false
                                }
                              />
                            )
                          }
                          return null
                        })}
                      </div>
                    </AccordionContent>
                  </Accordion>
                  <Divider />
                </>
              )}

              <Accordion>
                <AccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleInputClick}
                >
                  <Icon name="dropdown" />
                  Parameter
                </AccordionTitle>
                <AccordionContent active={activeIndex === 2}>
                  <div className="accordion-content">
                    {data.displayName.map((name, i) => {
                      const currentDataType = isPairEnd ? 'PE' : 'SE'
                      if (
                        data.input[i] === 'file' &&
                        (currentDataType ===
                          data.paramsDataType[data.displayName[i]] ||
                          data.paramsDataType[data.displayName[i]] === 'NA')
                      ) {
                        return null
                      } else if (
                        data.input[i] === 'string' &&
                        data.validation[data.displayName[i]] &&
                        data.validation[data.displayName[i]].options
                      ) {
                        return (
                          <Form.Field key={i}>
                            <label>
                              {data.displayName[i]}
                              <Popup
                                content={data.description[i]}
                                trigger={<Icon name="info circle" />}
                                size="small"
                                position="top center"
                                inverted
                              />
                            </label>
                            <FormSelect
                              id={data.displayName[i]}
                              placeholder="Select an option"
                              fluid
                              selection
                              clearable
                              search
                              options={data.validation[
                                data.displayName[i]
                              ].options.map((option) => ({
                                key: option.description,
                                text: option.displayName,
                                value: option.value,
                              }))}
                              value={inputData[data.displayName[i]] || ''}
                              onChange={onParamsChange}
                              error={
                                (data.validation[name]?.required
                                  ? newData.inputData[name] === undefined ||
                                    newData.inputData[name] === null ||
                                    newData.inputData[name] === ''
                                    ? true
                                    : false
                                  : false) ?? false
                              }
                            />
                          </Form.Field>
                        )
                      } else if (data.input[i] === 'string[]') {
                        return (
                          <Form.Field key={i}>
                            <label>
                              {data.displayName[i]}
                              <Popup
                                content={data.description[i]}
                                trigger={<Icon name="info circle" />}
                                size="small"
                                position="top center"
                                inverted
                              />
                            </label>
                            <FormSelect
                              id={data.displayName[i]}
                              placeholder="Select an option"
                              fluid
                              selection
                              clearable
                              search
                              multiple
                              options={data.validation[
                                data.displayName[i]
                              ].options.map((option) => ({
                                key: option.value,
                                text: option.displayName,
                                value: option.value,
                              }))}
                              value={inputData[data.displayName[i]] || []}
                              onChange={onParamsChange}
                              tabIndex={10}
                              error={
                                (data.validation[name]?.required
                                  ? newData.inputData[name] === undefined ||
                                    newData.inputData[name] === null ||
                                    newData.inputData[name] === ''
                                    ? true
                                    : false
                                  : false) ?? false
                              }
                            />
                          </Form.Field>
                        )
                      } else if (
                        data.input[i] !== 'file' &&
                        data.input[i] !== 'boolean'
                      ) {
                        return (
                          <Form.Field key={i}>
                            <label>
                              {data.displayName[i]}{' '}
                              <Popup
                                content={data.description[i]}
                                trigger={<Icon name="info circle" />}
                                size="small"
                                position="top center"
                                inverted
                              />
                            </label>
                            <Form.Input
                              id={data.displayName[i]}
                              type={data.input[i]}
                              min={data?.validation?.[name]?.minimum}
                              max={data?.validation?.[name]?.maximum}
                              value={inputData[data.displayName[i]] || ''}
                              placeholder={data.defaultValue[i]}
                              onChange={onParamsChange}
                              error={
                                (() => {
                                  const value = newData.inputData[name]
                                  const validation = data.validation[name]

                                  // Check for required field
                                  if (
                                    validation?.required &&
                                    (value === undefined ||
                                      value === null ||
                                      value === '')
                                  ) {
                                    return true
                                  }

                                  // Check for minimum value
                                  if (validation?.minimum !== undefined) {
                                    const numValue = Number(value)
                                    if (
                                      isNaN(numValue) ||
                                      numValue < validation.minimum
                                    ) {
                                      return true
                                    }
                                  }

                                  // Check for maximum value
                                  if (validation?.maximum !== undefined) {
                                    const numValue = Number(value)
                                    if (
                                      isNaN(numValue) ||
                                      numValue > validation.maximum
                                    ) {
                                      return true
                                    }
                                  }

                                  return false
                                })() ?? false
                              }
                            />
                          </Form.Field>
                        )
                      } else if (data.input[i] === 'boolean') {
                        return (
                          <Form.Group inline key={i}>
                            <label>
                              {data.displayName[i]}
                              <Popup
                                content={data.description[i]}
                                trigger={<Icon name="info circle" />}
                                size="small"
                                position="top center"
                                inverted
                              />
                            </label>
                            <Checkbox
                              value={String(inputData[data.displayName[i]])}
                              checked={inputData[data.displayName[i]] === true}
                              id={data.displayName[i]}
                              onChange={onCheckboxChange}
                            />
                          </Form.Group>
                        )
                      }
                      return null
                    })}
                  </div>
                </AccordionContent>
              </Accordion>
              <Divider className="edit-bar-divider" />
              <Accordion>
                <AccordionTitle
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleInputClick}
                >
                  <Icon name="dropdown" />
                  Hardware
                </AccordionTitle>
                <AccordionContent active={activeIndex === 3}>
                  <Form.Group widths="equal">
                    <FormSelect
                      fluid
                      selection
                      upward
                      options={CPU_CHOICE.map((cpu) => ({
                        key: cpu,
                        text: cpu,
                        value: cpu,
                      }))}
                      label="CPU Cores"
                      value={hardware.cpu}
                      onChange={onHardwareChange}
                      id="cpu"
                    ></FormSelect>
                    <FormSelect
                      fluid
                      selection
                      upward
                      options={RAM_MULTIPLIER_CHOICES.map((ramMultiplier) => {
                        const acutalRam = ramMultiplier * hardware.cpu
                        return {
                          key: ramMultiplier,
                          text: `${acutalRam} (cpu x${ramMultiplier})`,
                          value: ramMultiplier,
                        }
                      })}
                      label="Memory (GB)"
                      value={ramMultiplier}
                      id="ramInGB"
                      onChange={(event, { id, value }) => {
                        setRamMultiplier(value)
                      }}
                    ></FormSelect>
                  </Form.Group>
                </AccordionContent>
              </Accordion>
              <Divider className="edit-bar-divider" />
              <Form.Button
                className="save-btn"
                style={{
                  backgroundColor: 'black',
                  marginTop: '10px',
                  color: 'white',
                }}
                type="submit"
                onClick={onSave}
              >
                Save
              </Form.Button>
            </Form>
          </div>
        </NodeToolbar>
      </div>
    </>
  )
}
