import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import { Icon, Popup } from "semantic-ui-react";

import "./customedge.css";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  // target,
  // source,
  // setNodes,
}) {
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    // Remove the edge
    setEdges((edges) => edges.filter((edge) => edge.id !== id));

    // Update inputData of the source node
    // setNodes((nodes) =>
    //   nodes.map((node) => {
    //     // console.log(node);
    //     if (node.id === target) {
    //       // Remove disconnected input data from node
    //       const updatedInputData = Object.entries(node.data.inputData).reduce(
    //         (acc, [key, value]) => {
    //           if (value && value.type === "processOutput") {
    //             return {
    //               ...acc,
    //               [key]: null, // Set the inputData[key] to null
    //             };
    //           }
    //           return {
    //             ...acc,
    //             [key]: value,
    //           };
    //         },
    //         {}
    //       );

    //       // Remove disconnected output data from node
    //       const updatedSource = node.data.source.filter(
    //         (item) => !(item && item.id === source)
    //       );

    //       return {
    //         ...node,
    //         data: {
    //           ...node.data,
    //           inputData: updatedInputData,
    //           source: updatedSource,
    //         },
    //       };
    //     }
    //     return node;
    //   })
    // );
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <Popup
            content="Remove"
            size="mini"
            trigger={
              <button className="edgebutton" onClick={onEdgeClick}>
                <Icon name="trash alternate" />
              </button>
            }
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
