// import { useState, useCallback } from 'react'

// let usedBackgroundColors = []
// let usedBorderColors = []

// const useCategories = () => {
//   const [categoryColors, setCategoryColors] = useState({})

//   const getRandomLightColor = useCallback(() => {
//     let colorHex
//     do {
//       // Generate light colors (high brightness)
//       const r = Math.floor(Math.random() * 55 + 200) // 200-255
//       const g = Math.floor(Math.random() * 55 + 200) // 200-255
//       const b = Math.floor(Math.random() * 55 + 200) // 200-255
//       colorHex = `#${r.toString(16).padStart(2, '0')}${g
//         .toString(16)
//         .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
//     } while (usedBackgroundColors.includes(colorHex))
//     usedBackgroundColors.push(colorHex)
//     return colorHex
//   }, [])

//   const getRandomDarkColor = useCallback(() => {
//     let colorHex
//     do {
//       // Generate dark colors (low brightness)
//       const r = Math.floor(Math.random() * 100) // 0-99
//       const g = Math.floor(Math.random() * 100) // 0-99
//       const b = Math.floor(Math.random() * 100) // 0-99
//       colorHex = `#${r.toString(16).padStart(2, '0')}${g
//         .toString(16)
//         .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
//     } while (usedBorderColors.includes(colorHex))
//     usedBorderColors.push(colorHex)
//     return colorHex
//   }, [])

//   const getCategoryColors = useCallback(
//     (category) => {
//       if (categoryColors.hasOwnProperty(category)) {
//         return categoryColors[category]
//       } else {
//         const newColors = {
//           background: getRandomLightColor(),
//           border: getRandomDarkColor(),
//         }
//         setCategoryColors((prevColors) => ({
//           ...prevColors,
//           [category]: newColors,
//         }))
//         return newColors
//       }
//     },
//     [categoryColors, getRandomLightColor, getRandomDarkColor]
//   )

//   return { getCategoryColors }
// }

// export { useCategories }

import { useState, useCallback, useEffect } from 'react'

// Singleton state
let categoryColors = {}
let usedBackgroundColors = []
let usedBorderColors = []
let listeners = []

const getRandomLightColor = () => {
  let colorHex
  do {
    const r = Math.floor(Math.random() * 55 + 200)
    const g = Math.floor(Math.random() * 55 + 200)
    const b = Math.floor(Math.random() * 55 + 200)
    colorHex = `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
  } while (usedBackgroundColors.includes(colorHex))
  usedBackgroundColors.push(colorHex)
  return colorHex
}

const getRandomDarkColor = () => {
  let colorHex
  do {
    const r = Math.floor(Math.random() * 100)
    const g = Math.floor(Math.random() * 100)
    const b = Math.floor(Math.random() * 100)
    colorHex = `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
  } while (usedBorderColors.includes(colorHex))
  usedBorderColors.push(colorHex)
  return colorHex
}

const getCategoryColors = (category) => {
  if (categoryColors.hasOwnProperty(category)) {
    return categoryColors[category]
  } else {
    const newColors = {
      background: getRandomLightColor(),
      border: getRandomDarkColor(),
    }
    categoryColors = {
      ...categoryColors,
      [category]: newColors,
    }
    listeners.forEach((listener) => listener(categoryColors))
    return newColors
  }
}

const useCategories = () => {
  const [, setLocalCategoryColors] = useState(categoryColors)

  useEffect(() => {
    const listener = (newColors) => {
      setLocalCategoryColors({ ...newColors })
    }
    listeners.push(listener)
    return () => {
      listeners = listeners.filter((l) => l !== listener)
    }
  }, [])

  return {
    getCategoryColors: useCallback((category) => {
      return getCategoryColors(category)
    }, []),
  }
}

export { useCategories }
