import { useEffect, useMemo, useState } from "react";
import { Button, Table, Label , Input} from "semantic-ui-react";
import { useBlueprintStore } from "../../store/blueprintStore";
import { useShallow } from "zustand/react/shallow";

import LoadingPlaceholder from "../general/LoadingPlaceholder";
import { useNavigate } from "react-router";

export async function loader() {
    
}

const BlueprintsTable = ({ userType }) => {
    const blueprints = useBlueprintStore((state) => state.blueprints[userType] ?? []);
    const [searchQuery, setSearchQuery] = useState("");

    // Filter blueprints by tags based on the search query
    const filteredBlueprints = useMemo(() => {
      if (!searchQuery && searchQuery == "") return blueprints;
      return blueprints.filter((blueprint) =>
        blueprint.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }, [blueprints, searchQuery]);

    const { 
        getBlueprintsIsLoading, 
        getBlueprintsIsFailed 
    } = useBlueprintStore(useShallow((state) => ({
        getBlueprintsIsLoading: state.getBlueprintsIsLoading,
        getBlueprintsIsFailed: state.getBlueprintsIsFailed
    })));
    const showLoadingPlaceholder = useMemo(
      () => getBlueprintsIsLoading && !blueprints?.length,
      [getBlueprintsIsLoading, blueprints]
    );
    const getBlueprints = useBlueprintStore((state) => state.getBlueprints);
    useEffect(() => {
      getBlueprints(userType);
    }, [getBlueprints, userType]);

    const navigate = useNavigate()
    const createFromBlueprint = (blueprintGraph) => {
        navigate("/create", { state: { blueprintGraph } })
    }

    const deleteBlueprint = useBlueprintStore((state) => state.deleteBlueprint)
    const { 
        deleteBlueprintIsLoading, 
        deleteBlueprintIsFailed
    } = useBlueprintStore(useShallow((state) => ({
        deleteBlueprintIsLoading: state.deleteBlueprintIsLoading,
        deleteBlueprintIsFailed: state.deleteBlueprintIsFailed
    })));
    const [deletingBlueprintIds, setDeletingBlueprintIds] = useState([])
    const handleDelete = async (blueprintId) => {
        setDeletingBlueprintIds(state => [ ...state, blueprintId])
        await deleteBlueprint(userType, blueprintId)
        setDeletingBlueprintIds(state => state.filter(id => id !== blueprintId))
    }
    useEffect(() => {
        if (deleteBlueprintIsFailed) {
            console.error("Failed to delete blueprint")
        }
    }, [deleteBlueprintIsFailed])
  
    const blueprintRow = (blueprint) => {
      return (
        <Table.Row key={blueprint?.blueprintId}>
          <Table.Cell style={{ backgroundColor: "transparent" }} width={4}>
            {blueprint?.blueprintName}
          </Table.Cell>
          <Table.Cell width={2}>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "5px" }}>
                {blueprint?.tags?.map((tag) => (
                    <Label key={tag} color="blue">
                        {tag}
                    </Label>
                ))}
            </div>
          </Table.Cell>
          <Table.Cell
            style={{
              textAlign: "right",
            }}
            width={1}
          >
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "true", gap: "5px" }}>
                <Button
                    onClick={() => handleDelete(blueprint?.blueprintId)}
                    negative
                    loading={deleteBlueprintIsLoading && deletingBlueprintIds.includes(blueprint?.blueprintId)}
                    >
                    Delete
                </Button>
                <Button secondary onClick={() => createFromBlueprint(blueprint?.graph)}>
                    Create from blueprint
                </Button>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    };
  
    return (
      <div>
      <Input
        icon="search"
        placeholder="Search by tag..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "10px",width:"100%" }}
      />
      <Table compact definition={!showLoadingPlaceholder} celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>
                Blueprint name
            </Table.HeaderCell>
            {/* <Table.HeaderCell style={{ textAlign: "center" }}>
              Invovled processes
            </Table.HeaderCell> */}
            <Table.HeaderCell style={{ textAlign: "center" }}>
              Tags
            </Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: "right" }}>
              {/* Operation */}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        {/* <Table.Body>
          {showLoadingPlaceholder
            ? <LoadingPlaceholder />
            : getBlueprintsIsFailed ? <Table.Row>Network failure, please try again later.</Table.Row> : blueprints.map((job) => blueprintRow(job))}
        </Table.Body>
      </Table> */}
              <Table.Body>
          {showLoadingPlaceholder ? (
            <LoadingPlaceholder />
          ) : getBlueprintsIsFailed ? (
            <Table.Row>
              <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                Network failure, please try again later.
              </Table.Cell>
            </Table.Row>
          ) : (
            filteredBlueprints.map((blueprint) => blueprintRow(blueprint))
          )}
        </Table.Body>
      </Table>
      </div>
    );
  };

export default BlueprintsTable
