import { useState, useEffect, Fragment } from 'react'
import {
  Dimmer,
  Segment,
  Header,
  Table,
  TableHeader,
  TableBody,
  Placeholder,
  PlaceholderLine,
  TableCell,
  TableHeaderCell,
  TableRow,
  Button,
} from 'semantic-ui-react'
import { toJobStatusDisplayName } from '../utilities/jobStatusDisplayName'
import { myAxios } from '../../utils/axios'
import { getJobStatusURL } from '../../constant/url'
import { postRequestDownloadProcessOutputsURL } from '../../constant/url'

import styles from './JobDetailsPanel.module.css'
import { useToolStore } from '../../store/toolStore'
import { capitalizeFirstLetter } from '../../utils/CapitalizeFirstLetter'


const JobDetailsPanel = ({
  activeJobDetails,
  onExit,
  user,
  setTreeParams,
  setIgvParams,
  setLogProps,
}) => {
  const workflowName = activeJobDetails?.workflow?.name ?? ''
  const startDateTimeLocalString = new Date(
    activeJobDetails?.startTimestamp ?? null
  ).toLocaleString()
  const latestStatusTimestamp = new Date(
    activeJobDetails?.latestStatusTimestamp ?? null
  ).toLocaleString()
  const [isLoadingProcessStatus, setIsLoadingProcessStatus] = useState(false)
  const [processesStatus, setProcessesStatus] = useState(null)
  const [jobStatus, setJobStatus] = useState(activeJobDetails?.jobStatus)
  const jobStatusDisplayName = toJobStatusDisplayName(jobStatus)

  useEffect(() => {
    const fetchJobDetail = async () => {
      try {
        setIsLoadingProcessStatus(true)
        const jobid = activeJobDetails?.jobid
        if (jobid) {
          const response = await myAxios.get(getJobStatusURL(user, jobid))
          const { jobStatus, processesStatus } = response.data
          setProcessesStatus(processesStatus)
          setJobStatus(jobStatus)
        }
      } catch (error) {
        console.error('failed to fetch job data: ' + error)
      } finally {
        setIsLoadingProcessStatus(false)
      }
    }
    let periodicFetch = null
    if (activeJobDetails) {
      fetchJobDetail()
      periodicFetch = setInterval(() => {
        fetchJobDetail()
      }, 10000)
    }
    return () => clearInterval(periodicFetch)
  }, [activeJobDetails, user])

  useEffect(() => {
    if (!activeJobDetails) {
      setProcessesStatus(null)
    }
  }, [activeJobDetails])

  const tools = useToolStore((state) => state.tools)
  const fetchTools = useToolStore((state) => state.fetchTools)

  useEffect(() => {
    if (!tools?.length) {
      fetchTools()
    }
  }, [tools, fetchTools])

  const processPanelHeader = () => (
    <TableHeader>
      <TableRow>
        <TableHeaderCell>Process Name</TableHeaderCell>
        <TableHeaderCell>Process Type</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell colSpan={4}>Outputs</TableHeaderCell>
      </TableRow>
    </TableHeader>
  )

  const loadingProcessBody = () => (
    <TableBody>
      <TableRow>
        <TableCell colSpan={5}>
          <Placeholder>
            <PlaceholderLine />
            <PlaceholderLine />
            <PlaceholderLine />
            <PlaceholderLine />
            <PlaceholderLine />
          </Placeholder>
        </TableCell>
      </TableRow>
    </TableBody>
  )

  const viewBtn = ({
    firstCell,
    currentProcessStatus,
    processOutputs,
    processId,
    processType,
    numberOfOutputs,
    index = -1,
  }) => {
    if (currentProcessStatus === 'SUCCEEDED') {
      let fileType, id
      if (firstCell) {
        fileType = processOutputs?.[0]?.fileType
        id = processOutputs?.[0]?.id
      } else {
        fileType = processOutputs.fileType
        id = processOutputs.id
      }
      if (fileType === 'nwk') {
        return (
          <TableCell
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              primary
              disabled={processDownloading.has(processId)}
              loading={processDownloading.has(processId)}
              onClick={() =>
                setTreeParams({
                  user: user,
                  jobId: activeJobDetails?.jobid,
                  processId: processId,
                  outputId: id,
                  workflowName: activeJobDetails?.workflow?.name ?? '',
                })
              }
            >
              Visualize
            </Button>
          </TableCell>
        )
      } else if (fileType === 'html') {
        return (
          <TableCell
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              primary
              disabled={processDownloading.has(processId)}
              loading={processDownloading.has(processId)}
              onClick={handleViewOutput(processId)}
            >
              Visualize
            </Button>
          </TableCell>
        )
      } else if (processType === 'igv') {
        if (firstCell) {
          return (
            <TableCell
              rowSpan={numberOfOutputs}
              style={{
                textAlign: 'center',
              }}
            >
              <Button
                primary
                disabled={processDownloading.has(processId)}
                loading={processDownloading.has(processId)}
                onClick={() =>
                  setIgvParams({
                    user: user,
                    jobId: activeJobDetails?.jobid,
                    processId: processId,
                    workflowName: activeJobDetails?.workflow?.name ?? '',
                  })
                }
              >
                Visualize
              </Button>
            </TableCell>
          )
        } else {
          return <></>
        }
      }
    }
    return (
      <TableCell></TableCell>
    )
  }

  const downloadAndlogBtn = ({ currentProcessStatus, numberOfOutputs, processId }) => {
    return (
      <TableCell
        rowSpan={numberOfOutputs}
        style={{
          textAlign: 'center',
        }}

      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '5px'
        }}>
          {currentProcessStatus === 'SUCCEEDED' &&
            <Button
              onClick={handleDownloadOutput(processId)}
              secondary
              disabled={processDownloading.has(processId)}
              loading={processDownloading.has(processId)}
            >
              Download
            </Button>
          }
          {["RUNNING", "SUCCEEDED", "FAILED"].includes(currentProcessStatus) &&
            <Button
              onClick={() => handleViewLog(processId, currentProcessStatus)}
            >
              View log
            </Button>
          }
        </div>
      </TableCell>
    )
  }

  const processBody = () => {
    const someProcessHasLogs = processesStatus && processesStatus.some((status) => status.status !== 'WAITING')
    const someProcessVisualizable = processesStatus &&
      processesStatus.some((status) => {
        const tool = tools.find((tool) =>
          activeJobDetails?.workflow?.processes?.find(
            (p) => p.processId === status.processId
          )?.type === tool.toolid
        )
        return (
          status.status === 'SUCCEEDED' &&
          (
            tool?.outputs.some((output) =>
              output.fileType === 'nwk' ||
              output.fileType === 'html'
            ) ||
            tool?.toolid === 'igv'
          )
        )
      })
    return (
      <TableBody>
        {processesStatus &&
          processesStatus.map(({ processId, status }) => {
            const process = activeJobDetails?.workflow?.processes?.find(
              (p) => p.processId === processId
            )
            if (!process) {
              console.error(`workflowProcess not found: ${processId}`)
              return null
            }
            const processLabel = process.processLabel
            const processType = process.type
            const tool = tools.find((tool) => tool.toolid === processType)
            if (!tool) {
              console.error(`tool not found: ${processType}`)
              return null
            }
            const isPairEnd = process?.isPairEnd
            const currentProcessStatus = status
            const processStatusStyle = {
              positive: currentProcessStatus === 'SUCCEEDED',
              negative: currentProcessStatus === 'FAILED',
              warning: currentProcessStatus === 'STARTING' || currentProcessStatus === 'RUNNING',
            }
            const processStatusStyleDisplayName = capitalizeFirstLetter(status)
            const processOutputs = tool.outputs.filter((output) => {
              return !(
                (!isPairEnd && output?.isPairEndOnly) ||
                (isPairEnd && output?.isSingleEndOnly)
              )
            })
            const numberOfOutputs = processOutputs?.length ?? 1
            return (
              <Fragment key={`${processId}`}>
                <TableRow>
                  <TableCell
                    rowSpan={numberOfOutputs}
                  >
                    {processLabel}
                  </TableCell>
                  <TableCell
                    rowSpan={numberOfOutputs}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {processType}
                  </TableCell>
                  <TableCell
                    {...processStatusStyle}
                    rowSpan={numberOfOutputs}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {processStatusStyleDisplayName}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {processOutputs?.[0]?.displayName}
                  </TableCell>
                  {someProcessVisualizable && viewBtn({
                    firstCell: true,
                    numberOfOutputs,
                    currentProcessStatus: currentProcessStatus,
                    processOutputs: processOutputs,
                    processId: processId,
                    processType: processType
                  })}
                  {someProcessHasLogs && downloadAndlogBtn({
                    currentProcessStatus,
                    numberOfOutputs,
                    processId
                  })}
                </TableRow>
                {processOutputs?.slice(1).map((output, index) => (
                  <TableRow key={`${processId}-${output.outputId}`}>
                    <TableCell
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {output.displayName}
                    </TableCell>
                    {someProcessVisualizable && viewBtn({
                      firstCell: false,
                      currentProcessStatus: currentProcessStatus,
                      processOutputs: output,
                      processId: processId,
                      processType: processType,
                      index: index,
                    })}
                  </TableRow>
                ))}
              </Fragment>
            )
          })}
      </TableBody>
    )
  }

  const [processDownloading, setProcessDownloading] = useState(new Set())

  const handleDownloadOutput = (pid) => async (e) => {
    try {
      const jobid = activeJobDetails?.jobid
      if (!jobid) {
        console.error('jobid not found')
      }
      setProcessDownloading((prev) => new Set([...prev, pid]))
      const response = await myAxios.post(
        postRequestDownloadProcessOutputsURL(user, jobid, pid)
      )
      console.debug(response)
      const processOutputURLs = response.data
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      for (const url of processOutputURLs) {
        const link = document.createElement('a')
        link.href = url.url
        link.download = url.id
        link.click()
        await sleep(5000)
      }
    } catch (error) {
      console.error('Error occured furint handleDownloadOutput: ' + error)
    } finally {
      setProcessDownloading((prev) => {
        const newSet = new Set(prev)
        newSet.delete(pid)
        return newSet
      })
    }
  }

  const handleViewOutput = (pid) => async (e) => {
    try {
      const jobid = activeJobDetails?.jobid
      if (!jobid) {
        console.error('jobid not found')
      }
      setProcessDownloading((prev) => new Set([...prev, pid]))
      const response = await myAxios.post(
        postRequestDownloadProcessOutputsURL(user, jobid, pid)
      )
      console.debug(response)
      const processOutputURLs = response.data
      let viewUrlFound = false
      let viewUrl = ''

      for (const url of processOutputURLs) {
        if (url.viewUrl && !viewUrlFound) {
          viewUrl = url.viewUrl
          viewUrlFound = true
        }
      }

      // if (viewUrl) {
      //   window.open(viewUrl, "PopupWindow", "width=1024,height=720");
      // }
      if (viewUrl) {
        const anchorElement = document.createElement('a')
        anchorElement.href = viewUrl
        anchorElement.target = '_blank'
        anchorElement.click()
      }
    } catch (error) {
      console.error('Error occured furint handleViewOutput: ' + error)
    } finally {
      setProcessDownloading((prev) => {
        const newSet = new Set(prev)
        newSet.delete(pid)
        return newSet
      })
    }
  }

  const handleViewLog = async (processId, processStatus) => {
    const logProps = {
      user: user,
      jobId: activeJobDetails?.jobid,
      processId: processId,
      workflowName: activeJobDetails?.workflow?.name ?? '',
      processLabel: activeJobDetails?.workflow?.processes?.find(
        (p) => p.processId === processId
      )?.processLabel,
      processStatus: processStatus
    }
    setLogProps(logProps)
  }

  const processPanel = () => {
    return (
      <div className={styles.table}>
        <Table celled structured unstackable>
          {processPanelHeader()}
          {(isLoadingProcessStatus && !processesStatus) || !tools?.length
            ? loadingProcessBody()
            : processBody()}
        </Table>
      </div>
    )
  }

  return (
    <Dimmer
      active={!!activeJobDetails}
      page
      onClickOutside={onExit}
      style={{ opacity: 1 }}
    >
      <Segment className={styles.panel} textAlign="left">
        <Header as="h1">Workflow Details - {workflowName}</Header>
        <p>
          Running status: {jobStatusDisplayName}
          <br />
          Submitted datetime: {startDateTimeLocalString}
          <br />
          {activeJobDetails?.latestStatusTimestamp && jobStatus && (
            <>
              {jobStatus?.toLowerCase()} datetime: {latestStatusTimestamp}
              <br />
            </>
          )}
        </p>

        {activeJobDetails && processPanel()}
        <div style={{ textAlign: 'center', color: 'orange' }}>
          The data refresh automatically for every 10 seconds.
        </div>
      </Segment>
    </Dimmer>
  )
}

export default JobDetailsPanel
