import axios from 'axios'
import { backendURL } from '../constant/url'
import axiosRetry from 'axios-retry';

const myAxios = axios.create({
  baseURL: backendURL,
  headers: {
    "Content-Type": "application/json",
  }
})

myAxios.interceptors.request.use(
  config => {
    if (config.baseURL === backendURL && !config.headers.Authorization) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      // TODO: make it prettier, like handle it in a zustand store
      alert("Your login session has expired. Please login again.")
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
)

axiosRetry(myAxios, { retryDelay: axiosRetry.exponentialDelay, retries: 3 });

export { myAxios }
