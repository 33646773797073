import { create } from 'zustand'
import { myAxios } from '../utils/axios'
import { postRequestDownloadProcessOutputsURL } from '../constant/url'

const useIgvStore = create((set) => ({
  urlsJson: null, // Initialize dataset with a default value of null
  fetchDataset: async (user, jobId, processId) => {
    // console.log(user, jobId, processId)
    try {
      // Make a POST request to fetch the URL
      const response = await myAxios.post(
        postRequestDownloadProcessOutputsURL(user, jobId, processId)
      )

      // Extract the URL from the response
      const processOutputURLs = response.data
      //   console.log(processOutputURLs)
      if (processOutputURLs) {
        let urls = {
          bam: null,
          bai: null,
          fasta: null,
          fai: null,
        }
        for (const json of processOutputURLs) {
          if (json.id === 'igv_sorted_bam.bam') {
            urls.bam = json.url
          } else if (json.id === 'igv_sorted_bam.bai') {
            urls.bai = json.url
          } else if (json.id === 'igv_fasta.fa') {
            urls.fasta = json.url
          } else if (json.id === 'igv_fasta.fai') {
            urls.fai = json.url
          }
        }

        // console.log(urls)
        set({ urlsJson: urls })
      } else {
        console.error('No URL found in the response.')
      }
    } catch (error) {
      console.error('Failed to fetch dataset data: ' + error)
    }
  },
}))

export default useIgvStore
