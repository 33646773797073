import Rainbow from 'rainbowvis.js'

export const colorGenerator = (border, length) => {
  let colors = []
  for (let i = 0; i <= length; i++) {
    // let hex = rainbow(length, i);
    let myRainbow = new Rainbow()
    if (border) {
      myRainbow.setSpectrum(
        // '#675163',
        // '#b85000',
        // '#ac0c17',
        // '#a51248',
        // '#496f64',
        // '#337984',
        // '#69644f',
        // '#0b36ad',
        // '#0734b0'
        '#000000',
        '#000000'
      )
    } else {
      myRainbow.setSpectrum(
        '#8CB369',
        '#F4E285',
        '#F4A259',
        '#5B8E7D',
        '#BC4B51'
        // '#eae4e9',
        // '#fff1e6',
        // '#fde2e4',
        // '#fad2e1',
        // '#e2ece9',
        // '#bee1e6',
        // '#f0efeb',
        // '#dfe7fd',
        // '#cddafd'
      )
    }
    myRainbow.setNumberRange(0, length - 1)
    let color = `#${myRainbow.colourAt(i)}`
    colors.push(color)
  }
  return colors
}
