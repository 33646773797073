'use strict'
import React, { StrictMode, createRef, Component } from 'react'
import { createRoot } from 'react-dom/client'
import igv from 'igv'
// import index from 'igv'
// import igv from "https://cdn.jsdelivr.net/npm/igv@3.0.2/dist/igv.esm.min.js"
import './styles/igv.css'

const igvStyle = {
  margin: '5px',
}

class IGViewerSectionTest extends Component {
  constructor(props) {
    super(props)
    this.container = createRef(null)
  }

  componentDidMount() {
    const igvOptions = {
      // For FASTA, .fai, .bam, .bai (not ok)
      // reference: {
      //   // fastaURL:
      //   //   'https://igv-genepattern-org.s3.amazonaws.com/genomes/GCA_000182895.1/GCA_000182895.1.fa',
      //   // fastaURL:
      //   // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data2/sample_fasta.fasta',
      //   // indexURL:
      //   // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data2/sample_fasta.fasta.fai',
      //   fastaURL:
      //     'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31.fa.fasta',
      //   indexURL:
      //     'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31.fa.fasta.fai',
      //   tracks: [
      //     {
      //       type: 'alignment',
      //       format: 'bam',
      //       // url: 'gs://genomics-public-data/platinum-genomes/bam/NA12878_S1.bam',
      //       // url: 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data2/sorted_output.bam',
      //       // indexURL:
      //       //   'gs://genomics-public-data/platinum-genomes/bam/NA12878_S1.bam.bai',
      //       // indexURL:
      //       // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data2/sorted_output.bam.bai',
      //       url: 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31_sorted.bam',
      //       indexURL:
      //         'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31_sorted.bam.bai',
      //     },
      //     // {
      //     //   name: 'HG00103',
      //     //   url: 'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram',
      //     //   indexURL:
      //     //     'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai',
      //     //   format: 'cram',
      //     // },
      //   ],
      // },
      // IGV ref genome example (not ok)
      // reference: {
      //   id: 'hg38',
      //   name: 'Human (GRCh38/hg38)',
      //   fastaURL:
      //     'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa',
      //   indexURL:
      //     'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai',
      //   cytobandURL:
      //     'https://s3.amazonaws.com/igv.broadinstitute.org/annotations/hg38/cytoBandIdeo.txt',
      //   tracks: [
      //     {
      //       name: 'Refseq Genes',
      //       url: 'https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.txt.gz',
      //       order: 1000000,
      //       indexed: false,
      //     },
      //   ],
      // },
      // IGV Alignment Example (not ok)
      // reference: {
      //   id: 'hg38', // Change here
      //   tracks: [
      //     {
      //       type: 'alignment',
      //       format: 'bam',
      //       name: 'NA12878',
      //       url: 'gs://genomics-public-data/platinum-genomes/bam/NA12878_S1.bam',
      //       indexURL:
      //         'gs://genomics-public-data/platinum-genomes/bam/NA12878_S1.bam.bai',
      //     },
      //   ],
      // },
      // IGV Main Example (ok)
      // genome: 'hg38',
      // locus: 'chr8:127,736,588-127,739,371',
      // tracks: [
      //   {
      //     name: 'HG00103',
      //     url: 'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram',
      //     indexURL:
      //       'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai',
      //     format: 'cram',
      //   },
      // ],
      // IGV Main Example Custom (ok)
      reference: {
        fastaURL:
          // 'https://igv-genepattern-org.s3.amazonaws.com/genomes/seq/hg38/hg38.fa',
          // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/test1/fasta.fa',
          // 'https://devcyrus-bioplatform-d24h-st-outputsbucket30eb72c2-st30pffg6lps.s3.us-east-1.amazonaws.com/public/job717ea54661d94de9b33b96a2b9e25e1f/node9d78dd12667049f696eb98e1c408b167/results/igv_fasta.fa?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA6GBMDLURSJC7MQ4K%2F20240924%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240924T024329Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQDYPRRQDXgY95IP3%2F18b5hxOWuwiiy%2BY7HHe%2BoxPOu52AIgKTK7DscUJXTLPQV7qjfueGCpGjbsR1LgHtJYvfTDCtAq3AMIzP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw5NzUwNTAwNzEzMzEiDKlSWhwrcXiH8tQyMiqwA7x3BpvxHq0l70%2FjPp%2By7In1EYmWpFgOXeCF64Uq2t3CE62YdU3cC5hk4dBVyguKoJoZ32zAtdtSXyoCvzCSd9j40bQjMl219HKope1K1dszX4zMfFpgjEdXsDr1G9YpaKKVm6GR6abJS02WocnDJXkY%2BRIJB2%2BQUCbTFEE0PFXuwMW984WVj%2FPJcbC%2FcEx%2FlWaZKa0gB3%2F7flWLRXcmMVpJXEEFr%2Bp0sziTykpVWHwhlxuKvc4B9ty7gxYyVHd65Q0rKE5RMWEqyNrzJigYr9Q1bHHmeMaLlhK3FpVk0lhaDPU0KBUFc9nssDvqOYgKVmuxby9%2FntJohID0R48tuEwhvqz2D%2FSAyXpCzTn%2Bnof4WoIZXbrWcfbBrW2o7g2%2F984SaWSR21Z9Y5EJOPgyv5Gg9TKnrNvvvoAx8GICr6SBYbjlk4lE17RVWDVXw4aelNSWua%2B%2BBJ3l991LJ2Z4XD%2FPCAOxrWXPeRtlerwCugMDeIjaw46Iv398pgp0WONxchVzuYrOl%2FNxe2uzffFhB37s0DKjPXcmpmhzUWycgum45tC9%2B9dPXBM%2Fq5js9ctlHjDKzsi3BjqeAQG5RW%2BPZCZWK4sKdc%2FF3IDysEReh2toNxBQ7nZ6f76XCzBCg90k8ghsXkEpWP0EvpFS4BpScIv%2B%2FGaBv4XB1tQWo%2FpBqYJWKkjEfl%2BYQsMFkKc0aaUjUasAgkV%2BWKuIqwQlv0ARCx5d5qP0L1yM3FtEbZWolL01XdlyGiWYEgv6S9TjcD%2BELvCScCyL%2B0YAbc5k1tX2OdZC7lVSoIwo&X-Amz-Signature=a244cb2c9d909a4c4411b7b7f5a562d1cb4927a410f48d3ef8a601782b7a2376&X-Amz-SignedHeaders=host&response-content-disposition=attachment&x-id=GetObject',
          'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data6/results/split_fa/split_005.fa',
        indexURL:
          // 'https://igv-genepattern-org.s3.amazonaws.com/genomes/seq/hg38/hg38.fa.fai',
          // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/test1/fasta.fai',
          // 'https://devcyrus-bioplatform-d24h-st-outputsbucket30eb72c2-st30pffg6lps.s3.us-east-1.amazonaws.com/public/job717ea54661d94de9b33b96a2b9e25e1f/node9d78dd12667049f696eb98e1c408b167/results/igv_fasta.fai?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA6GBMDLURSJC7MQ4K%2F20240924%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240924T024329Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQDYPRRQDXgY95IP3%2F18b5hxOWuwiiy%2BY7HHe%2BoxPOu52AIgKTK7DscUJXTLPQV7qjfueGCpGjbsR1LgHtJYvfTDCtAq3AMIzP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw5NzUwNTAwNzEzMzEiDKlSWhwrcXiH8tQyMiqwA7x3BpvxHq0l70%2FjPp%2By7In1EYmWpFgOXeCF64Uq2t3CE62YdU3cC5hk4dBVyguKoJoZ32zAtdtSXyoCvzCSd9j40bQjMl219HKope1K1dszX4zMfFpgjEdXsDr1G9YpaKKVm6GR6abJS02WocnDJXkY%2BRIJB2%2BQUCbTFEE0PFXuwMW984WVj%2FPJcbC%2FcEx%2FlWaZKa0gB3%2F7flWLRXcmMVpJXEEFr%2Bp0sziTykpVWHwhlxuKvc4B9ty7gxYyVHd65Q0rKE5RMWEqyNrzJigYr9Q1bHHmeMaLlhK3FpVk0lhaDPU0KBUFc9nssDvqOYgKVmuxby9%2FntJohID0R48tuEwhvqz2D%2FSAyXpCzTn%2Bnof4WoIZXbrWcfbBrW2o7g2%2F984SaWSR21Z9Y5EJOPgyv5Gg9TKnrNvvvoAx8GICr6SBYbjlk4lE17RVWDVXw4aelNSWua%2B%2BBJ3l991LJ2Z4XD%2FPCAOxrWXPeRtlerwCugMDeIjaw46Iv398pgp0WONxchVzuYrOl%2FNxe2uzffFhB37s0DKjPXcmpmhzUWycgum45tC9%2B9dPXBM%2Fq5js9ctlHjDKzsi3BjqeAQG5RW%2BPZCZWK4sKdc%2FF3IDysEReh2toNxBQ7nZ6f76XCzBCg90k8ghsXkEpWP0EvpFS4BpScIv%2B%2FGaBv4XB1tQWo%2FpBqYJWKkjEfl%2BYQsMFkKc0aaUjUasAgkV%2BWKuIqwQlv0ARCx5d5qP0L1yM3FtEbZWolL01XdlyGiWYEgv6S9TjcD%2BELvCScCyL%2B0YAbc5k1tX2OdZC7lVSoIwo&X-Amz-Signature=f9f7e9c4819fd92a40eb6ec23531666322ae8bda4e2540d7653c0bd4580261e9&X-Amz-SignedHeaders=host&response-content-disposition=attachment&x-id=GetObject',
          'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data6/results/split_fa/split_005.fa.fai',
      },
      // locus: 'chr8:127,736,588-127,739,371',
      tracks: [
        {
          // name: 'HG00103',
          url:
            // 'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram',
            // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/test1/sorted_bam.bam',
            // 'https://devcyrus-bioplatform-d24h-st-outputsbucket30eb72c2-st30pffg6lps.s3.us-east-1.amazonaws.com/public/job717ea54661d94de9b33b96a2b9e25e1f/node9d78dd12667049f696eb98e1c408b167/results/igv_sorted_bam.bam?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA6GBMDLURSJC7MQ4K%2F20240924%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240924T024329Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQDYPRRQDXgY95IP3%2F18b5hxOWuwiiy%2BY7HHe%2BoxPOu52AIgKTK7DscUJXTLPQV7qjfueGCpGjbsR1LgHtJYvfTDCtAq3AMIzP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw5NzUwNTAwNzEzMzEiDKlSWhwrcXiH8tQyMiqwA7x3BpvxHq0l70%2FjPp%2By7In1EYmWpFgOXeCF64Uq2t3CE62YdU3cC5hk4dBVyguKoJoZ32zAtdtSXyoCvzCSd9j40bQjMl219HKope1K1dszX4zMfFpgjEdXsDr1G9YpaKKVm6GR6abJS02WocnDJXkY%2BRIJB2%2BQUCbTFEE0PFXuwMW984WVj%2FPJcbC%2FcEx%2FlWaZKa0gB3%2F7flWLRXcmMVpJXEEFr%2Bp0sziTykpVWHwhlxuKvc4B9ty7gxYyVHd65Q0rKE5RMWEqyNrzJigYr9Q1bHHmeMaLlhK3FpVk0lhaDPU0KBUFc9nssDvqOYgKVmuxby9%2FntJohID0R48tuEwhvqz2D%2FSAyXpCzTn%2Bnof4WoIZXbrWcfbBrW2o7g2%2F984SaWSR21Z9Y5EJOPgyv5Gg9TKnrNvvvoAx8GICr6SBYbjlk4lE17RVWDVXw4aelNSWua%2B%2BBJ3l991LJ2Z4XD%2FPCAOxrWXPeRtlerwCugMDeIjaw46Iv398pgp0WONxchVzuYrOl%2FNxe2uzffFhB37s0DKjPXcmpmhzUWycgum45tC9%2B9dPXBM%2Fq5js9ctlHjDKzsi3BjqeAQG5RW%2BPZCZWK4sKdc%2FF3IDysEReh2toNxBQ7nZ6f76XCzBCg90k8ghsXkEpWP0EvpFS4BpScIv%2B%2FGaBv4XB1tQWo%2FpBqYJWKkjEfl%2BYQsMFkKc0aaUjUasAgkV%2BWKuIqwQlv0ARCx5d5qP0L1yM3FtEbZWolL01XdlyGiWYEgv6S9TjcD%2BELvCScCyL%2B0YAbc5k1tX2OdZC7lVSoIwo&X-Amz-Signature=25fec6bcc557f2bc0c4680ec50b2669072193ce163f1a65178d2d81f4949d2c9&X-Amz-SignedHeaders=host&response-content-disposition=attachment&x-id=GetObject',
            'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data6/results/split_bam/split_005.bam',
          indexURL:
            // 'https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai',
            // 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/test1/sorted_bam.bai',
            // 'https://devcyrus-bioplatform-d24h-st-outputsbucket30eb72c2-st30pffg6lps.s3.us-east-1.amazonaws.com/public/job717ea54661d94de9b33b96a2b9e25e1f/node9d78dd12667049f696eb98e1c408b167/results/igv_sorted_bam.bai?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA6GBMDLURSJC7MQ4K%2F20240924%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240924T024329Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQDYPRRQDXgY95IP3%2F18b5hxOWuwiiy%2BY7HHe%2BoxPOu52AIgKTK7DscUJXTLPQV7qjfueGCpGjbsR1LgHtJYvfTDCtAq3AMIzP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw5NzUwNTAwNzEzMzEiDKlSWhwrcXiH8tQyMiqwA7x3BpvxHq0l70%2FjPp%2By7In1EYmWpFgOXeCF64Uq2t3CE62YdU3cC5hk4dBVyguKoJoZ32zAtdtSXyoCvzCSd9j40bQjMl219HKope1K1dszX4zMfFpgjEdXsDr1G9YpaKKVm6GR6abJS02WocnDJXkY%2BRIJB2%2BQUCbTFEE0PFXuwMW984WVj%2FPJcbC%2FcEx%2FlWaZKa0gB3%2F7flWLRXcmMVpJXEEFr%2Bp0sziTykpVWHwhlxuKvc4B9ty7gxYyVHd65Q0rKE5RMWEqyNrzJigYr9Q1bHHmeMaLlhK3FpVk0lhaDPU0KBUFc9nssDvqOYgKVmuxby9%2FntJohID0R48tuEwhvqz2D%2FSAyXpCzTn%2Bnof4WoIZXbrWcfbBrW2o7g2%2F984SaWSR21Z9Y5EJOPgyv5Gg9TKnrNvvvoAx8GICr6SBYbjlk4lE17RVWDVXw4aelNSWua%2B%2BBJ3l991LJ2Z4XD%2FPCAOxrWXPeRtlerwCugMDeIjaw46Iv398pgp0WONxchVzuYrOl%2FNxe2uzffFhB37s0DKjPXcmpmhzUWycgum45tC9%2B9dPXBM%2Fq5js9ctlHjDKzsi3BjqeAQG5RW%2BPZCZWK4sKdc%2FF3IDysEReh2toNxBQ7nZ6f76XCzBCg90k8ghsXkEpWP0EvpFS4BpScIv%2B%2FGaBv4XB1tQWo%2FpBqYJWKkjEfl%2BYQsMFkKc0aaUjUasAgkV%2BWKuIqwQlv0ARCx5d5qP0L1yM3FtEbZWolL01XdlyGiWYEgv6S9TjcD%2BELvCScCyL%2B0YAbc5k1tX2OdZC7lVSoIwo&X-Amz-Signature=7e87077629a8b168d21bd3c48234afe8eeba97040653afdd217d95c50426b4e9&X-Amz-SignedHeaders=host&response-content-disposition=attachment&x-id=GetObject',
            'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data6/results/split_bam/split_005.bam.bai',
          // format: 'cram',
          format: 'bam',
        },
      ],
      // Custom (not ok)
      // showCenterGuide: true,
      // showCursorTrackingGuide: false,
      // reference: {
      //   fastaURL:
      //     'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31.fa.fasta',
      //   indexURL:
      //     'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31.fa.fasta.fai',
      // },
      // genome: 'hg38',
      // trackDefaults: {
      //   bam: {
      //     coverageThreshold: 0.2,
      //     coverageQualityWeight: true,
      //   },
      // },
      // tracks: [
      //   {
      //     type: 'alignment',
      //     url: 'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31_sorted.bam',
      //     indexURL:
      //       'https://raw.githubusercontent.com/cyruslauwork/bio-sample-data/main/sample_data/nCoV_Jan31_sorted.bam.bai',
      //     format: 'bam',
      //     height: 600,
      //   },
      // ],
    }
    // IGV Example
    // const igvOptions = {
    //   // Example of fully specifying a reference .  We could alternatively use  "genome: 'hg19'"
    //   reference: {
    //     id: 'hg19',
    //     name: 'Human (CRCh37/hg19)',
    //     fastaURL:
    //       'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/hg19.fasta',
    //     indexURL:
    //       'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/hg19.fasta.fai',
    //     cytobandURL:
    //       'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg19/cytoBand.txt',
    //   },
    //   locus: '8:128,750,948-128,751,025',
    //   tracks: [
    //     {
    //       name: 'Phase 3 WGS variants',
    //       type: 'variant',
    //       format: 'vcf',
    //       url: 'https://s3.amazonaws.com/1000genomes/release/20130502/ALL.wgs.phase3_shapeit2_mvncall_integrated_v5b.20130502.sites.vcf.gz',
    //       indexURL:
    //         'https://s3.amazonaws.com/1000genomes/release/20130502/ALL.wgs.phase3_shapeit2_mvncall_integrated_v5b.20130502.sites.vcf.gz.tbi',
    //     },
    //     {
    //       type: 'alignment',
    //       format: 'cram',
    //       url: 'https://s3.amazonaws.com/1000genomes/phase3/data/HG00096/exome_alignment/HG00096.mapped.ILLUMINA.bwa.GBR.exome.20120522.bam.cram',
    //       indexURL:
    //         'https://s3.amazonaws.com/1000genomes/phase3/data/HG00096/exome_alignment/HG00096.mapped.ILLUMINA.bwa.GBR.exome.20120522.bam.cram.crai',
    //       name: 'HG00096',
    //       sort: {
    //         chr: 'chr8',
    //         position: 128750986,
    //         option: 'BASE',
    //         direction: 'ASC',
    //       },
    //       height: 600,
    //     },

    //     {
    //       name: 'Genes',
    //       type: 'annotation',
    //       format: 'bed',
    //       url: 'https://s3.amazonaws.com/igv.broadinstitute.org/annotations/hg19/genes/refGene.hg19.bed.gz',
    //       indexURL:
    //         'https://s3.amazonaws.com/igv.broadinstitute.org/annotations/hg19/genes/refGene.hg19.bed.gz.tbi',
    //       order: Number.MAX_VALUE,
    //       visibilityWindow: 300000000,
    //       displayMode: 'EXPANDED',
    //     },
    //   ],
    // }
    const igvView = igv.createBrowser(this.container.current, igvOptions)
    return igvView
  }

  render() {
    return <div id="igv-div" ref={this.container} style={igvStyle}></div>
  }
}

export default IGViewerSectionTest

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <IGViewerSectionTest />
  </StrictMode>
)
