import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import FlowCanvas from './components/layout/FlowCanvas'
import LoginForm from './components/auth/LoginForm'
import Dashboard from './components/dashboard/Dashboard'
import HomePage from './components/common/HomePage'
import ErrorPage from './components/common/ErrorPage'
import BlueprintsPage from './components/blueprints/BlueprintsPage'
// import Tree from './components/visualization/tree/Tree'
// import FastaVis from "./components/visualization/fasta/FastaVis";
import AbundanceVis from './components/visualization/abundance/AbundanceVis'
// import KronaVis from './components/visualization/krona/KronaVis'
import IGViewerSectionTest from './components/visualization/igv/IGViewerSectionTest'
import DiamondVis from './components/visualization/diamond/DiamondVis'

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      title: 'Biotailer - Build, Run, Visualize Genomic Pipeline',
      element: <HomePage />,

      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: '/blueprints',
              title: 'Blueprints | Biotailor',
              element: <BlueprintsPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/login',
              title: 'Login | Biotailor',
              element: <LoginForm />,
              errorElement: <ErrorPage />,
            },
            // {
            //   path: '/tree',
            //   element: <Tree />,
            //   errorElement: <ErrorPage />,
            // },
            // {
            //   path: '/krona',
            //   element: <KronaVis />,
            //   errorElement: <ErrorPage />,
            // },
            {
              path: '/abundance',
              element: <AbundanceVis />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/igv',
              element: <IGViewerSectionTest />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/diamond',
              element: <DiamondVis />,
              errorElement: <ErrorPage />,
            },
          ],
        },
      ],
    },
    {
      path: '/create',
      title: 'Create Workflow | Biotailor',
      element: <FlowCanvas />,
      errorElement: <ErrorPage />,
    },
    // {
    //   path: "/fasta",
    //   element: <FastaVis />,
    //   errorElement: <ErrorPage />,
    // },
  ])

  return <RouterProvider router={router} />
}
