import { useEffect } from "react";
import { useBlocker } from "react-router";
import { Segment, Dimmer, Button } from "semantic-ui-react";
import styles from './LeavingBlocker.module.css';

const LeavingBlocker = ({ active, message }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      active &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (active) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [active])

  const handleStay = () => {
    blocker.reset()
  }

  return (
    <Dimmer active={blocker.state === "blocked"} page>
      <Segment textAlign="left">
        <p className={styles.message}>
          Are you sure you want to leave?<br />
          {message}
        </p>
        <div className={styles.buttonContainer}>
          <Button onClick={() => blocker.proceed()} negative>
            Leave anyway
          </Button>
          <Button onClick={handleStay}>
            Stay
          </Button>
        </div>
      </Segment>
    </Dimmer>
  )
}

export default LeavingBlocker;