import React, { useState, useEffect, useMemo } from 'react'
import {
  Dimmer,
  Segment,
  Header,
  Placeholder,
  PlaceholderLine,
  Button,
  Table,
} from 'semantic-ui-react'
import styles from '../../dashboard/JobDetailsPanel.module.css'
import { myAxios } from '../../../utils/axios'
import { getProcessLogURL } from '../../../constant/url'
import { utcTimestampToLocalizedString } from '../../../utils/timeUtil'
import { capitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter'

export default function LogViewer({ logProps, onExit, setLogProps }) {
  const { user, jobId, processId, workflowName, processLabel, processStatus } =
    logProps || {}
  const isPropsValid = useMemo(
    () =>
      !!user &&
      !!jobId &&
      !!processId &&
      !!workflowName &&
      !!processLabel &&
      !!processStatus,
    [user, jobId, processId, workflowName, processLabel, processStatus]
  )

  const [isLoading, setIsLoading] = useState(true)
  const [logData, setLogData] = useState('')
  const [_refetch, _setRefetch] = useState({})
  const reFetchLog = () => {
    _setRefetch({})
  }

  useEffect(() => {
    const fetchLog = async (user, jobId, processId) => {
      try {
        const response = await myAxios.get(
          getProcessLogURL(user, jobId, processId)
        )
        if (!response.data?.length) {
          console.error('No log data found in the response.')
          return
        }
        setLogData(response.data)
      } catch (error) {
        console.error('Failed to fetch log data: ' + error)
      } finally {
        setIsLoading(false)
      }
    }
    if (isPropsValid) {
      setIsLoading(true)
      fetchLog(user, jobId, processId)
      if (processStatus === 'RUNNING') {
        const interval = setInterval(() => {
          fetchLog(user, jobId, processId)
        }, 10000)
        return () => clearInterval(interval)
      }
    }
  }, [user, jobId, processId, isPropsValid, processStatus, _refetch])

  return (
    <Dimmer active={isPropsValid} page onClickOutside={onExit}>
      <Segment textAlign="left" style={{ maxHeight: '15dvh' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <Header as="h3">Log - {processLabel}</Header>
            <p style={{ color: 'black' }}>Workflow: {workflowName}</p>
          </div>
          <Button onClick={reFetchLog}>Refresh Log</Button>
        </div>
      </Segment>
      <Segment
        className={styles.panel}
        textAlign="left"
        style={{ maxHeight: '75dvh' }}
      >
        <div>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {isLoading ? (
              <Placeholder>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
              </Placeholder>
            ) : (
              !!logData?.length && (
                <Table striped celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Time</Table.HeaderCell>
                      <Table.HeaderCell>Log message</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {logData.map((log, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {utcTimestampToLocalizedString(log.timestamp)}
                        </Table.Cell>
                        <Table.Cell>{log.message}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )
            )}
          </div>
        </div>
      </Segment>
      <Segment textAlign="right" style={{ maxHeight: '10dvh' }}>
        <Button onClick={() => setLogProps(null)}>Cancel</Button>
      </Segment>
    </Dimmer>
  )
}
