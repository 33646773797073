const textData = `	FEBIFF	FEBEFF	MARIFF	MAREFF	APRIFF	APREFF	MAYIFF	MAYEFF	JUNIFF	JUNEFF	JULIFF	JULEFF	AUGIFF	AUGEFF	SEPIFF	SEPEFF	OCTIFF	OCTEFF	NOVIFF	NOVEFF	DECIFF	DECEFF	JANIFF	JANEFF
aminocoumarin	0.0621119856508464	0.0315578089935677	0.0491623595861303	0.0960449228200894	0.0441173900468803	0.0655127840151843	0.0490552130163472	0.0502193792335522	0.108665085862546	0.195866542193427	0.195052982712015	0.11197948186524	0.144203529176473	0.165582024693376	0.130396501970437	0.101298459739878	0.157393825332911	0.112019324754706	0.192553994829456	0.168956626656852	0.10547114031056	0.129925377715695	0.0739073049398225	0.119469621872611
aminoglycoside	0.646916575147986	0.550761865551774	0.488712508890483	1.10686087345983	0.478993753035267	0.44155390761109	0.707601004164337	0.775083542915579	1.21795845105532	1.57627980921602	1.59999161752391	1.23011553598032	1.08579319976028	1.0598108399307	1.38810855842402	1.1492952685481	1.50821327688373	1.24663743044063	1.94349720178157	1.42538216099344	1.73436072041932	1.24366751667618	1.15823300849125	1.46514354641231
antibacterial_free_fatty_acid	0.000967451369267138	0.000587177377327713	0.00173706339982635	0.00100767243519597	0.0010757946449829	0.000384870107724175	0.00111735951588254	0.00100345218994844	0.000323780408241765	0.000292723625979888	0.000439536536500969	0.000710533246106377	0	0.000255381214178316	0.000684493134601065	0.00105940186196951	0.000232821098151061	0.00107818920279769	0.000656605168610725	0.000674661191896042	0.000317874907069271	0.000262970458620971	0.000443788536821383	0.000813457597810125
betalactam	0.371666667300956	0.30369887205369	0.289246009111033	0.827794991743928	0.244320510676712	0.274518043875233	0.579879512726853	0.609771820123599	0.933083121359263	1.19322557911114	1.10871815998883	1.00940586618991	0.904276215364282	0.935784896709651	1.07153027939852	0.882432485246971	1.10205951860813	0.976393964380564	1.58886622948948	1.26923119154095	1.40237315369236	1.03961199548522	0.913623799871883	1.22562374281078
bicyclomycin	0	0	0	0	0	0	0	0	0	0.000986425220549143	0.000277717115102602	0.000199530618585981	0	0.000215147026206607	0.000157269340740543	0.000617883060712411	0.000392282314629317	0	0	0	0	0	0	0
colistin	0.0053948726015348	0.00486997726828535	0.00347896092785258	0.0114644439314133	0.00233981880826879	0.00274642003190186	0.00460729410769747	0.00386177054919552	0.0177754843418963	0.0177487910856971	0.0380821921274863	0.0195691782751591	0.0121734857852614	0.014588149889234	0.0240651269318324	0.017358043238047	0.0336986956467533	0.0197443447771102	0.06372620757413	0.0417547269265236	0.0306852805993497	0.0249498304760635	0.0189438972540678	0.0396369665414585
diaminopyrimidine	0.0398171699682649	0.0346605834219835	0.0320588792962748	0.0381970646311514	0.0414417307302327	0.0315915393744407	0.0314220487035675	0.0382603476303678	0.0260370008050792	0.0436108461818547	0.0428944694189536	0.0489690347320441	0.0442877119394741	0.0489291482315355	0.0436819593177555	0.0389173237317154	0.0406886784938772	0.040761013384167	0.0638434153619932	0.0630450068502255	0.0452859465198264	0.0464315695777743	0.0739902265811507	0.0678705599864472
elfamycin	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0
fluoroquinolone	0.101165337423862	0.0596402830971025	0.0780147696796887	0.16026271486762	0.0801677726667488	0.0913841570914163	0.0975206887540656	0.0921145403528592	0.179983978241423	0.277859090971182	0.333164347764873	0.201662341611406	0.25117611025954	0.282738524591274	0.234070515764549	0.152290366238637	0.242676658256608	0.196288022280726	0.347780347656044	0.291804401899134	0.210385634848514	0.218475627168096	0.173475066431923	0.247512492136311
fosfomycin	0.0439729202901783	0.0225229426250904	0.021940468321193	0.0631773864022843	0.0195084489685173	0.0298408952761244	0.0431367695077421	0.04060231351073	0.0616054907437635	0.108595973902718	0.0923179721169923	0.0629419238144827	0.0824108110635511	0.0953231394464555	0.0685466716411903	0.0512083694305063	0.0705478407908439	0.0536844603211909	0.104665905702777	0.0783945101258215	0.0735279569027942	0.0674988357804645	0.0301153318474797	0.066764325759882
fusidic_acid	0.000384558134262681	0	0	0.000343325014808552	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0
glycopeptide	0.0846227561458007	0.109627789455331	0.0763692695382022	0.0504875625194861	0.0914354046035126	0.0964989477421859	0.0235889781299448	0.0192340470965735	0.0175854565681443	0.0164915423495089	0.0651122707929661	0.0329418458285994	0.0414951112474896	0.0335331157754957	0.0569805400545304	0.0370619357685435	0.0728984432027261	0.0569024523869924	0.0688268787071476	0.0535082452748275	0.0588348375516131	0.0601539158241975	0.046020892798013	0.059067591987053
lincosamide	0.049225404422373	0.0434674291992379	0.052907371885428	0.0223695038957472	0.03582502055708	0.0326917181969439	0.0360299428205466	0.0341550130795174	0.0218551691047429	0.0153832384112336	0.0208363738487147	0.050660319841407	0.0265468700492878	0.0369776049398568	0.0261173107144583	0.027952909827036	0.0237557882773903	0.0257507202692419	0.0547103972683816	0.0420847358088428	0.0612514717551244	0.0516104071989443	0.067343614971599	0.0443229302270445
macrolide	0.108332889380868	0.0959179193269169	0.111425838381598	0.187808822041201	0.127336151182585	0.0761979176707871	0.230208454984962	0.227397291838903	0.26868184938007	0.263327766009888	0.203600446392242	0.27463597631966	0.295750558291548	0.25680680326203	0.249610364939731	0.213444629167123	0.31325740018962	0.273856102965908	0.181357661615682	0.174729798723703	0.183292246725076	0.173791106386896	0.220607063426488	0.269726256603651
multi|23S_rRNA_methyltransferase	0.191239952177717	0.230619449598981	0.178736572287189	0.0968864350194166	0.152501565297109	0.126934512025121	0.16463176589024	0.172979199819671	0.0861028661993623	0.0779519724532125	0.108519248203055	0.168094420172771	0.17139171349032	0.138456099575757	0.212346971752779	0.16563167854317	0.158955098816801	0.180794051750122	0.168388723322685	0.180262399983556	0.1544323095043	0.130605198129128	0.285942087145369	0.175107647825202
multi|AAC_gene	0.0228948254301051	0.0207639490894798	0.0174648337149988	0.0395066468401643	0.0154729124104233	0.0129469528227646	0.0227799555551705	0.0228988750282591	0.0447676463474168	0.0656663236991886	0.0594620727474906	0.0468786953268781	0.0394735991692138	0.0510294790995142	0.0663706059109904	0.0492555272251471	0.0624083300036045	0.0611017843256217	0.0806153198806149	0.0754833907892937	0.0887122340883907	0.0602614683937612	0.0440649965938644	0.0597359037590687
multi|ABC-F_cassette	0.0965162182946809	0.096539763886935	0.081561372206016	0.12214114237601	0.134219289698698	0.0646902667174993	0.147468949797319	0.167688696778164	0.169229744423067	0.168688791526528	0.168588783931	0.153596278169691	0.183684170399351	0.130188893371188	0.183393232993574	0.144489316285873	0.245752519465648	0.191892044509616	0.169317293101222	0.135913532792705	0.140601217375352	0.148904677344174	0.160888924450494	0.26278211919994
multi|ABC/MFS/RND_efflux_pump	0.0145619346840802	0.00711065872859239	0.0109189348859791	0.0255549917295299	0.0186255265586581	0.017929735445501	0.0169138885909248	0.0168498620971308	0.0348958624381134	0.0352482612142894	0.0618731290661509	0.0354874274728742	0.0408284656707245	0.04680778689073	0.0304590871448109	0.0215772254292418	0.0460296143090791	0.0322938177244625	0.0599658531898418	0.0532157806176363	0.033492785765623	0.0365119258432417	0.0266517997733954	0.0411260716344927
multi|ABC/MFS_efflux_pump	0	0.00040583143902378	0.000750364121693693	0	0	0	0	0.000577952054981642	0.000279728652018396	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0
multi|ABC_efflux_pump	0.013688171792334	0.0127913197320723	0.0160845874340817	0.0293995623238217	0.0133854295599743	0.0170201362437264	0.0186454211543516	0.0154368046592262	0.0278740953867607	0.0443021934050774	0.042955184092505	0.0244942176944062	0.0364748041912562	0.0472007465637437	0.0381232197098706	0.0250143732258718	0.0309648028146561	0.0276575691624296	0.0407148517737471	0.0295201019225023	0.0404900296211413	0.0370242452254731	0.0172101422835294	0.0352672939274323
multi|EAT_gene	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0	0
multi|LAP_gene	0	0	0	0.000513180548450678	0	9.80020123929229e-05	0	0.000298101586253689	0.000432843282596887	0.002782757464286	0.000652878831995591	0.00197010231824895	0.00287785475362101	0.000303470542228267	0.000110916271890699	0.000581026878143601	0.000138331132000864	0.000360342180935016	0.0026333323077967	0.00173702242155223	0.000283299039984543	0.000624977744933114	0.000494396001546629	0.000869971494079039
multi|MATE_transporter	0.00149741936548681	0.00109326168488449	0.00240303274643389	0.00677415506339329	0.00321846281623666	0.00203360714509625	0.011007113579171	0.00874461079412112	0.00828642748273962	0.00985797649592223	0.00842113454591272	0.0057827795180838	0.00819317934771646	0.00581869912769073	0.0054204633524406	0.00508706211673578	0.0041483547298862	0.00450524246395806	0.00550403205869459	0.00391008996334988	0.00816051168160219	0.00619040839725913	0.00320549525859652	0.0093229998273591
multi|MFS/RND_efflux_pump	0.0292912088364834	0.0140082915037011	0.0208307291342152	0.0317346794301556	0.0211121713572737	0.0284702610703107	0.0257903000433098	0.0253023465754317	0.0524245257930571	0.0709183558498589	0.0953376335624617	0.0628285348554303	0.0787296203899477	0.0885981417109359	0.0374818250644855	0.0298948572122588	0.0706553758500163	0.0508523465966127	0.0869380029103071	0.0867032974462652	0.044643919512447	0.0507212820480639	0.0893736762969126	0.0803695907156685
multi|MFS_efflux_pump	0.170647045342154	0.108882724294628	0.113075501704354	0.296954082205257	0.125593632563354	0.164772755507011	0.180578981692081	0.196842862085142	0.313793281051784	0.52869598528639	0.528532665564463	0.314010214107514	0.404168343866013	0.430864364273463	0.345770276798104	0.282158479111117	0.377796404449572	0.322981916122321	0.493554972993327	0.403223031142274	0.384858930937438	0.361413866318381	0.238967872929708	0.349495908000339
multi|Porin_gene/RND_efflux_pump	0.0201734415897989	0.0154370826620028	0.0245150076588395	0.0442702541978837	0.0223781302950166	0.0309981634952064	0.0257422511628045	0.0286925824416838	0.0592465924435938	0.0676165689490492	0.107312436944977	0.073463942350546	0.0590365701495202	0.0902209655035326	0.082624878563083	0.0466951743775791	0.0922797688058739	0.0562636514838962	0.122512569481172	0.108107612663802	0.0668716518210404	0.0736515218892516	0.0578895781426872	0.0893701253748944
multi|RND_efflux_pump	0.346168371349436	0.209196861523011	0.296541397419187	0.567012392034691	0.293176029539386	0.352072896114893	0.428701753644484	0.411081262532758	0.667298280092502	0.968822327603855	1.14744453537358	0.66065846842444	0.857894905968127	0.942364672451022	0.695258672427106	0.536481582993663	0.799898188771835	0.628898140507281	1.11203014549181	0.944660171488602	0.729014614631998	0.746923358019552	0.543017634263251	0.851271640357063
multi|SMR_efflux_pump	0.000375737993568583	0.000568164014633292	0.00168659825518307	0.00134180235145361	0	0	0.00390563280322244	0.000389719963680282	0.00188624366269285	0.000454751076445821	0.00136565480454124	0.000735883565794168	0.000442627417583372	0.00119021703488609	0.000290010435677515	0	0.00180845745964433	0.00062811939796012	0.0011475530699114	0	0.00148147204395587	0.000817058061036411	0	0
multi|TLA_gene	0	9.81204301587099e-05	0.000362840545687411	0.000240553382086255	0	0.00018375377323673	0.000116697909964706	0.00111788094845133	0.000530249861856797	0	0	0.000263852989051199	0.000312274359330664	0.00112895390809043	0.00010398400489753	0	0.00154376807645575	0.00090085545233754	0.000411458173093234	0.000375798120047357	0.000398389274978264	0.000585916635874794	0.00111239100347992	0.00013593304594985
multi|rpoB_gene	0.0768355831946245	0.113590336074816	0.0765228363505337	0.0648523386829643	0.0710114426380815	0.0761541697545114	0.0536576335307473	0.0603325349687448	0.0542019633444088	0.0304224475623592	0.0314644247366732	0.0355861555718832	0.0360768161828861	0.0438829828039067	0.0685663717053755	0.0724277747722481	0.0500674683280299	0.0582044903679748	0.0447021375178901	0.0412301109323409	0.0481372840129835	0.063301475691806	0.0301700643290457	0.0392399998552441
multi|tetracycline_inactivation_enzyme	0.00550696876376505	0.00616939856939204	0.010260192075427	0.0247380064412785	0.00640853053802884	0.00360715373262161	0.0191456977439788	0.0132841494429396	0.022630650924764	0.0227866850823175	0.0173001439531317	0.0283824424160342	0.0307725185430289	0.0219831202421882	0.0239993062188945	0.0172105476430174	0.0241463065698149	0.0253771547802668	0.00872518292772777	0.0121874609151926	0.014703823261921	0.0127767167137331	0.0142337633513919	0.0160154716810488
mupirocin	0.0292962743875397	0.0448560870333348	0.0310822781082257	0.0260482704645452	0.0264127157287387	0.0288537401983573	0.0170170220579428	0.0196840759079211	0.0180562773011201	0.0115930002753908	0.00988005605893388	0.0109023133457566	0.0107214196703528	0.0118472624342194	0.0271808189604507	0.0293190256667587	0.019406858136744	0.0214589682221315	0.0139064288911541	0.0138976277963674	0.0162345960788685	0.0244458605279957	0.0104436979533938	0.0113108082461903
nitroimidazole	0.0202666101273282	0.0106091450670572	0.0164254964095836	0.0357029935897965	0.0175498516211932	0.020861226994037	0.0204201291250956	0.0206305249162969	0.0450743310786983	0.0644722947951438	0.0845019950466938	0.0501444717303466	0.054690293328769	0.0604284413644417	0.0497504265209679	0.0341418681391357	0.06440352866542	0.0449892785199141	0.0828759146507411	0.0714110212607447	0.0472371943087633	0.0481236622055734	0.0391467850890618	0.0551701441575053
nucleoside	0.0054999548564333	0.00314857558109282	0.00326825261893253	0.0020453489100606	0.00609642330527623	0.00263788750024284	0.00178060443158408	0.00283196506941005	0.000913780263260094	0.000835626882235309	0.00270194279839095	0.00133685514452607	0.00616481631045286	0.0033634651763633	0.0026342614574041	0.00344984708897763	0.00153317004634291	0.00247234774141525	0.00648579994327705	0.00277946624881003	0.00249026368768022	0.00544251452879253	0.00394635980774778	0.00505858424789924
others	0.0752090831126645	0.0656229436901453	0.0675191279684015	0.109027540157203	0.0504108242684423	0.0543376612349485	0.100247040959984	0.100019857224164	0.153639690627232	0.197514432811115	0.194866525564355	0.146567936754403	0.131142456058725	0.142476384870749	0.162042522420868	0.136237597768353	0.150022879277577	0.141909302528226	0.176026295083902	0.144979665389111	0.189372531000578	0.130755287067767	0.127642620338215	0.169622296616471
peptide	0.180114917993042	0.108144066764757	0.121250302197226	0.232907523319339	0.204189411925085	0.164540061745819	0.157048950175293	0.159213083275719	0.257401879963752	0.385683784948927	0.490340122937201	0.24729208159625	0.391056293799938	0.403630986310382	0.296381439358051	0.212631401879279	0.325873898980539	0.253712702502099	0.459642848037402	0.361539905079665	0.316372557042127	0.32135062542842	0.242755614668288	0.327754550481142
phenicol	0.0454276071472833	0.0483236513651502	0.0504368416919682	0.083511415556192	0.0447206540203783	0.0407532392399761	0.0697176933673313	0.0607055961207277	0.0880407047183521	0.105166288768064	0.118343810157439	0.0946535716565066	0.0916343244341655	0.0987774556260888	0.0842950834271026	0.0731402673354	0.0954997925392372	0.0792614941916669	0.114275849572529	0.0960624256233433	0.0944816431544918	0.0822772888522078	0.0805531848824456	0.0905625889317514
pleuromutilin	0	4.60318067411232e-05	0	0	6.48746058465472e-05	0	0	0	0	0	0	0	0	0	0	0	6.08400812040838e-05	0	0	0	0	0	0	0
rifamycin	0.00543520353985571	0.00733987703650116	0.00894650596925665	0.0120450035279598	0.00706707082098431	0.00398835598291377	0.00610586841348207	0.00502023308289037	0.0112047196564338	0.00921788410044739	0.0115206319398047	0.00766355160245692	0.0126844799728795	0.0112255813721918	0.0132832173174166	0.00609053868306077	0.00695805593964347	0.0115140088473101	0.0149752803178928	0.0121742434765263	0.0135537504182599	0.0202785132031041	0.00984892466538476	0.0179589606806628
streptogramin	0	0	0.000173433216806561	0.000172472236212787	0	0	0	0	0	0.000224288992455178	0.000168389563003388	0.000181473549030689	0	0	0	0	0	0	0	0.000711792077846707	0.000190425062253761	0	0	0.00019131317578127
sulfonamide	0.144316537547556	0.0937916820370532	0.100278118504359	0.278438269657166	0.0636843617699236	0.0854111385309425	0.161942256996881	0.193257686193035	0.307570108425182	0.490463935362242	0.339059581190947	0.263287018433572	0.255420005506796	0.297655145765556	0.304408334492046	0.318565919038882	0.297971896872174	0.306364380524432	0.39827699055211	0.322131333797803	0.391077063675315	0.308680578596671	0.212252926270838	0.27040393325442
tetracycline	0.499620310391751	0.53337976702276	0.326691471585037	0.358680747724674	0.353152334050568	0.389553634945424	0.51035596123263	0.47825951745907	0.386906343544737	0.510083916321942	0.494929894158602	0.498550919238868	0.537680407263807	0.479149569688569	0.326611730532075	0.359446162823535	0.400875675171089	0.411375318234152	0.515995385414813	0.517566160407256	0.465866721472571	0.478242458304644	0.348443512598929	0.443366113213106
triclosan	0	0	0.000457690978792419	0.000183930430970647	0	0.000173841743974998	0.000426819093285134	0.000984390865080879	0.000409410839084472	0.00190573841817158	0.000528815439531306	0.000340090546256801	0.000342792646574532	0.000211192449532545	0.00043366402823912	0.00103815733730677	0.000245379912574147	0.000142043598293886	0.00053412034315874	0.000488473512580331	0.000207284353684089	0.000184770391396201	0.000252814690217127	0.000383647773010818
`;
export default textData;
