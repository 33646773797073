import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AbundanceChart = ({ data, headers, chartSize }) => {
  const svgRef = useRef();

  useEffect(() => {
    const margin = { top: 10, right: 10, bottom: 10, left: 50 }; // Adjust bottom margin for buttons
    const width = (1000 - margin.left - margin.right) * (chartSize / 100); // Adjust size by percentage
    const height = (900 - margin.top - margin.bottom) * (chartSize / 100); // Adjust size by percentage

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    // Clear previous chart content
    svg.selectAll("*").remove();

    // Define color schemes
    const colorSchemes = [
      d3.schemeCategory10,
      d3.schemeSet2,
      d3.schemeAccent,
      d3.schemeDark2,
      d3.schemePaired,
      d3.schemePastel1,
      d3.schemePastel2,
      d3.schemeSet1,
      d3.schemeSet3,
      d3.schemeTableau10,
    ];
    const combinedColors = colorSchemes.flat();
    const color = d3.scaleOrdinal(combinedColors); // Use combined color schemes

    // Define a list of line styles for the legend
    const lineStyles = [
      "none",
      "4,4",
      "2,2",
      "1,1",
      "10,2,1,2",
      "5,5",
      "5,2",
      "8,3",
      "4,1",
      "6,2",
      "3,3",
      "7,3",
      "2,6",
      "1,5",
      "5,10",
      "10,5",
      "15,5,5,5",
      "5,15",
      "10,5,1,5",
      "1,10,5",
      "15,5,10",
      "5,1,10,1",
      "2,10,5,10",
      "5,10,2,10",
      "1,10,1,10",
      "10,1,10,1",
      "5,1,5,1",
      "1,5,1,5",
      "1,1,1,1",
      "10,2,10,2",
      "10,10,2,2",
      "2,2,2,2",
      "5,5,1,1",
      "10,5,5,1",
      "1,5,1,10",
      "5,1,10,5",
      "1,10,5,5",
      "10,1,5,5",
      "5,10,1,1",
      "1,1,10,10",
    ];

    // Add patterns to the SVG
    const defs = svg.append("defs");

    lineStyles.forEach((style, i) => {
      const pattern = defs
        .append("pattern")
        .attr("id", `pattern-${i}`)
        .attr("width", 10)
        .attr("height", 10)
        .attr("patternUnits", "userSpaceOnUse");

      pattern
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", combinedColors[i % combinedColors.length]);

      pattern
        .append("path")
        .attr("d", "M0,0 l10,10")
        .attr("stroke", "#000")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", style);
    });

    // Add legend title
    svg
      .append("text")
      .attr("x", 90)
      .attr("y", 0)
      .attr("dy", "1em")
      .style("text-anchor", "end")
      .text("ARG types");

    const legend = svg.append("g").attr("class", "legend-group");

    let xOffset = 20;
    let yOffset = 30;
    const legendItemWidth = 300; // Adjust as necessary

    // Add legend
    legend
      .selectAll(".legend")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "legend")
      .each(function (d, i) {
        if (xOffset + legendItemWidth > width) {
          xOffset = 20;
          yOffset += 20;
        }

        const fillColor =
          i < 12 ? color(i) : `url(#pattern-${i % lineStyles.length})`;

        d3.select(this)
          .append("rect")
          .attr("x", xOffset)
          .attr("y", yOffset)
          .attr("width", 18)
          .attr("height", 18)
          .attr("fill", fillColor);

        d3.select(this)
          .append("text")
          .attr("x", xOffset + 22)
          .attr("y", yOffset + 9)
          .attr("dy", ".35em")
          .style("text-anchor", "start")
          .text(d.label);

        xOffset += legendItemWidth;
      });

    const legendHeight = yOffset + 40; // Height occupied by the legend

    svg
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left},${margin.top + legendHeight})`
      )
      .each(function () {
        const chartGroup = d3.select(this);

        const x = d3
          .scaleBand()
          .domain(data[0].values.map((_, i) => i))
          .range([0, width])
          .padding(0.1);

        const y = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(
              data[0].values.map((_, i) => d3.sum(data.map((d) => d.values[i])))
            ),
          ])
          .range([height / 2 - margin.bottom * 2, 0]); // Adjust as necessary

        // Stack the data
        const stack = d3
          .stack()
          .keys(data.map((d, i) => i))
          .value((d, key) => d[key].value);

        const stackedData = stack(
          d3.transpose(
            data.map((d) => d.values.map((value, i) => ({ value, key: i })))
          )
        );

        // Add y-axis
        const yAxis = d3
          .axisLeft(y)
          .ticks(10) // Customize number of ticks if needed
          .tickSize(-width) // Extend ticks across the width of the chart
          .tickPadding(10) // Add padding between the ticks and the axis line
          .tickFormat(d3.format(".2s")); // Format tick values (e.g., using SI prefixes)

        // Add axes
        chartGroup
          .append("g")
          .attr("transform", `translate(0,${height / 2 - margin.bottom - 5})`) // Adjust as necessary
          .call(d3.axisBottom(x).tickFormat((i) => data[0].values[i].label));

        // Add y-axis to chart group
        chartGroup.append("g").call(yAxis);

        // Add x-axis headers
        const xAxisHeaders = chartGroup
          .append("g")
          .attr(
            "transform",
            `translate(${margin.left / 2},${height / 2 - margin.bottom + 20})`
          )
          .selectAll("text")
          .data(headers)
          .enter()
          .append("text")
          .attr("x", (d, i) => x(i))
          .attr("y", 0)
          .attr("dy", "0.35em")
          .style("text-anchor", "middle")
          .attr("font-size", "0.5rem") // Add this line to set the font size to 10px
          .text((d) => d);

        // Add y-axis title
        chartGroup
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - height / 4)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text("Abundance (Copy of ARGs per cell)");

        // Append tooltip element (hidden by default)
        const tooltip = chartGroup
          .append("text")
          .attr("id", "tooltip")
          .attr("opacity", 0);

        // Add bars
        chartGroup
          .selectAll(".layer")
          .data(stackedData)
          .enter()
          .append("g")
          .attr("class", "layer")
          .each(function (d, i) {
            const layerGroup = d3.select(this);

            layerGroup
              .selectAll("rect")
              .data((d) => d)
              .enter()
              .append("rect")
              .attr("x", (d, i) => x(i))
              .attr("y", (d) => y(d[1]))
              .attr("height", (d) => y(d[0]) - y(d[1]))
              .attr("width", x.bandwidth())
              .attr(
                "fill",
                i < 12 ? color(i) : `url(#pattern-${i % lineStyles.length})`
              )
              // .attr("stroke", (d, j) =>
              //   j >= 12 ? combinedColors[j % combinedColors.length] : "none"
              // )
              // .attr("stroke-width", 1.5)
              .on("mouseover", function (event, d) {
                const value0 = d[0]; // extract d[0] value
                const value1 = d[1]; // extract d[1] value

                // Create the tooltip text element
                const tooltipText = chartGroup
                  .append("text")
                  .attr("class", "tooltip")
                  .attr(
                    "x",
                    event.clientX >= width * 0.75
                      ? event.clientX - 200 + 5
                      : event.clientX + 5
                  ) // Follow the mouse's x-position
                  .attr("y", y(d[1]) + 10)
                  // .attr("text-anchor", "middle")
                  .attr("fill", "#000")
                  .attr("z-index", 1000) // set the z-index to 1000
                  .text(`Low: ${value0.toFixed(2)} High: ${value1.toFixed(2)}`) // display tooltip with d[0] and d[1] values
                  .transition()
                  .duration(100)
                  .attr("opacity", 1);

                // Create a background rect element
                const tooltipBackground = chartGroup
                  .insert("rect", ".tooltip") // Insert before the text element with class "tooltip"
                  .attr("class", "tooltip-background")
                  .attr(
                    "x",
                    event.clientX >= width * 0.75
                      ? event.clientX - 200
                      : event.clientX
                  ) // adjust x position to center the text
                  .attr("y", y(d[1]) - 10) // adjust y position to center the text
                  .attr("width", 140) // set the width of the background rect
                  .attr("height", 30) // set the height of the background rect
                  .attr("fill", "#f0f0f0") // set the background color
                  .attr("rx", 5) // add a rounded corner to the background rect
                  .attr("margin", 0) // Add this line
                  .attr("padding", 0);
              })
              .on("mouseout", function () {
                // Remove the things when the mouse is no longer hovering
                chartGroup.selectAll(".tooltip").remove();
                chartGroup.selectAll(".tooltip-background").remove();
              });
          });
      });
  }, [data, chartSize]); // Update when chartSize changes

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default AbundanceChart;
