import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Header,
  Icon,
  Segment,
  Form,
  Input
} from "semantic-ui-react";
import { myAxios } from "../../utils/axios";
import { Link } from "react-router-dom";
import { backendURL, authGoogleURL, authEmailURL } from "../../constant/url";

const LoginForm = () => {
  const [email, setEmail] = useState("")
  const handleGoogleLogin = (e) => {
    window.location.href = backendURL + '/' + authGoogleURL;
  }
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const handleEmailLogin = async (e) => {
    const emailValidate = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/gm
    if (!email.match(emailValidate)) {
      alert("Invalid email address")
      setIsEmailInvalid(true)
      return
    }
    setIsEmailInvalid(false)
    setIsSendingEmail(true)
    const link = `${backendURL}/${authEmailURL}?email=${email}`;
    try {
      const response = await myAxios.get(link)
      if (response.status === 200) {
        showEmailSentPopUp()
      } else {
        showEmailSentErrorPopUp()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSendingEmail(false)
    }
  }
  const showEmailSentPopUp = () => {
    // TODO: make it prettier
    alert("Check your email for the one-time login link")
  }
  const showEmailSentErrorPopUp = () => {
    alert("Error sending email. Please try again later.")
  }

  const handleEmailFieldChange = (e) => {
    setEmail(e.target.value)
  }

  const token = localStorage.getItem("token")
  if (token) {
    window.location.href = "/"
    return
  } else {
    return (
      <Grid textAlign="center" style={{ height: "100%", marginTop: "100px" }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            Log in
          </Header>
          <Segment>
            <Form onSubmit={handleEmailLogin} >
              <Form.Input
                fluid
                required
                type="email"
                label="Email Address"
                placeholder="Email Address"
                value={email}
                error={isEmailInvalid}
                onChange={handleEmailFieldChange}
                style={{ marginBottom: "10px" }}
              />
              <Form.Button
                size="large"
                type="submit"
                icon
                fluid
                loading={isSendingEmail}
                labelPosition="left"
              >
                <Icon name="mail" />
                Sign in / sign up with email
              </Form.Button>
            </Form>
          </Segment>
          {/* <Button 
            fluid 
            size="large" 
            icon 
            labelPosition="left" 
            onClick={handleGoogleLogin}
            style={{ marginBottom: "10px" }}
          >
            <Icon name="google" />
            Sign in / sign up with Google
          </Button> */}
          {/* <Link to="/">
            <Button fluid size="large" icon labelPosition="left">
              <Icon name="arrow right" />
              Continue as guest
            </Button>
          </Link> */}
        </Grid.Column>
      </Grid>
    )
  }
}

export default LoginForm;
