import {create} from 'zustand';

const useNodesAndEdgesStore = create((set) => ({
    nodes: [],
    edges: [],
    isDeleted: false,
    setIsDeleted: (isDeleted) => set({ isDeleted }),
    setNodes: (nodes) => set({ nodes }),
    setEdges: (edges) => set({ edges }),
    deleteNode: (nodeId) =>
      set((state) => {
        // Filter out the node to be deleted
        const newNodes = state.nodes.filter((node) => node.id !== nodeId);
        
        // Find edges that are connected to the node to be deleted
        const connectedEdges = state.edges.filter(
          (edge) => edge.source === nodeId || edge.target === nodeId
        );

        // Update the connected nodes
        connectedEdges.forEach((edge) => {
          if (edge.source === nodeId) {
            // If the deleted node is the source, set the target node's source to null
            const targetIndex = newNodes.findIndex((node) => node.id === edge.target);
            if (targetIndex !== -1) {
              newNodes[targetIndex].data.source = null;
            }
          } else if (edge.target === nodeId) {
            // If the deleted node is the target, set the source node's target to null
            const sourceIndex = newNodes.findIndex((node) => node.id === edge.source);
            if (sourceIndex !== -1) {
              newNodes[sourceIndex].data.target = null;
            }
          }
        });
  
        // Filter out edges associated with the node to be deleted
        const remainingEdges = state.edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        );
        return {
          nodes: newNodes,
          edges: remainingEdges,
          isDeleted: true 
        };
      }),
  }));

export default useNodesAndEdgesStore;