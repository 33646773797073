export const utcTimestampToLocalizedString = (timestamp) => {
    const userlocale = Intl.DateTimeFormat().resolvedOptions().locale
    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3
    }
    return new Date(timestamp).toLocaleString(userlocale, options)
}